import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  updateCourse as updateCourseService,
  getCourse,
} from '../../services/courses';
import { uploadFile } from '../../services/files';
import { listExams } from '../../services/exams';
import { ArrowUp, ArrowDown } from 'react-feather';
import checkEmptyString from '../../helpers/check-empty-string';
import { hideModal, showModal } from '../modal';
import SelectContents from './select-contents';
import Swal from 'sweetalert2';
import SelectImage from '../select-image';
import { getSegmentationTypes } from '../../services/segmentations';
import getErrorMessage from '../../helpers/get-error-message';
import { DefaultTextArea } from '../CreateAndEditContent/style';

const TabsetEditarCurso = () => {
  const history = useHistory();
  const { courseId } = useParams();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [selectedContents, setSelectedContents] = useState([]);
  const [thumbnail, setThumbnail] = useState('');
  const [selectedExam, setSelectedExam] = useState([]);
  const [selectedExamID, setSelectedExamID] = useState([]);
  const [exams, setExams] = useState([]);
  const [segTypes, setSegTypes] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedRegistrations, setSelectedRegistrations] = useState([]);

  const selectImage = () => {
    showModal(
      'Cortar Imagem',
      <SelectImage aspect={1.712} onCutImage={onCutImage} />,
    );
  };

  const onCutImage = file => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setThumbnail(`${reader.result}`);

      hideModal();
    }
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const updateCourse = async event => {
    event.preventDefault();

    const proceedWithUpdate = async () => {
      try {
        if (!selectedContents || !selectedContents.length) {
          throw new Error('Selecione ao menos um conteúdo para continuar!');
        }

        if (checkEmptyString(title)) {
          throw new Error('Informe um nome para o curso!');
        }

        if (checkEmptyString(description)) {
          throw new Error('Informe uma descrição para o curso!');
        }

        const localSelectedContents = selectedContents.map(
          (content, index) => ({
            content_id: content.id,
            position: index + 1,
          }),
        );

        const newCourse = {
          title: title,
          description: description,
          contents: localSelectedContents,
          thumbnail: undefined,
          is_active: true,
          exam_id: selectedExam.value,
        };

        if (
          (selectedRoles && selectedRoles.length) ||
          (selectedRegistrations && selectedRegistrations.length)
        ) {
          newCourse.segmentation_items = [
            ...(selectedRoles || []).map(role => ({
              segmentation_item_id: role.value,
            })),
            ...(selectedRegistrations || []).map(reg => ({
              segmentation_item_id: reg.value,
            })),
          ];
        }

        if (thumbnail && thumbnail.length && thumbnail.includes('base64')) {
          try {
            const localThumbnail = dataURLtoFile(thumbnail, `thumbnail.png`);

            const formData = new FormData();
            formData.append('file', localThumbnail);

            const { reference } = await uploadFile(formData);
            newCourse.thumbnail = reference;
          } catch (e) {
            throw new Error('Erro ao mudar a imagem de capa. ' + e.message);
          }
        }

        await updateCourseService(courseId, newCourse);

        toast.success('Curso atualizado com sucesso!');

        history.push(`/cursos-avulsos/listar-cursos-avulsos`);
      } catch (e) {
        const errorMessage = e.response || e.message;
        toast.error('Erro ao atualizar o curso. ' + errorMessage);
      }
    };

    await proceedWithUpdate();
  };

  async function getExamsForSelect() {
    const exams = await listExams();
    const toSelect = (exams || [])
      .filter(e => !!e.enabled)
      .map(({ exam_id, title }) => ({
        value: exam_id,
        label: title,
      }));

    return toSelect;
  }

  const changeContentPosition = async (content, up) => {
    const foundContent = selectedContents.find(c => c.id === content.id);
    if (foundContent) {
      const indexOfFoundContent = selectedContents.indexOf(foundContent);
      if (
        indexOfFoundContent > -1 &&
        indexOfFoundContent < selectedContents.length
      ) {
        if (up) {
          const previousContent = selectedContents[indexOfFoundContent - 1];
          selectedContents.splice(indexOfFoundContent - 1, 1, foundContent);
          selectedContents.splice(indexOfFoundContent, 1, previousContent);
        } else {
          const nextContent = selectedContents[indexOfFoundContent + 1];
          selectedContents.splice(indexOfFoundContent + 1, 1, foundContent);
          selectedContents.splice(indexOfFoundContent, 1, nextContent);
        }
      }
    }

    setSelectedContents([...selectedContents]);
  };

  const checkShouldShowChangePositionButton = (content, up) => {
    const foundContent = selectedContents.find(c => c.id === content.id);
    if (foundContent) {
      const indexOfFoundContent = selectedContents.indexOf(foundContent);
      if (
        indexOfFoundContent > -1 &&
        indexOfFoundContent < selectedContents.length
      ) {
        if (up) {
          return indexOfFoundContent > 0;
        } else {
          return indexOfFoundContent < selectedContents.length - 1;
        }
      }
    }

    return false;
  };

  const selectContents = () => {
    showModal(
      'Selecionar Conteúdos',
      <SelectContents
        initialSelectedContents={[...selectedContents]}
        onSelectContents={selectedContents => {
          setSelectedContents([...selectedContents]);
          hideModal();
        }}
      />,
    );
  };

  useEffect(() => {
    const selected = exams.filter(({ value }) => value === selectedExamID);
    setSelectedExam(selected);
  }, [exams, selectedExamID]);

  useEffect(() => {
    (async () => {
      const localExams = await getExamsForSelect();
      setExams(localExams);

      const localSegTypes = await getSegmentationTypes();
      setSegTypes(localSegTypes);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const localCourse = await getCourse(courseId);

      setTitle(localCourse.title);
      setDescription(localCourse.description);

      const localSelectedContents = localCourse.contents
        .filter((a, b) => (a.position > b.position ? 1 : -1))
        .map(content => ({
          ...content,
          id: content.content_id,
        }));
      setSelectedContents(localSelectedContents);

      setSelectedExamID(localCourse.exam?.exam_id);

      const localSegs = localCourse.segmentations;
      const localRegistrations =
        (localSegs.filter(segType => segType.type === 'Matricula') || [])
          .map(segType => segType.items)
          .flat() || [];
      const localRoles =
        (localSegs.filter(segType => segType.type === 'Cargo') || [])
          .map(segType => segType.items)
          .flat() || [];

      setSelectedRegistrations(
        localRegistrations.map(item => ({
          value: item.segmentation_item_id,
          label: item.item,
        })),
      );
      setSelectedRoles(
        localRoles.map(item => ({
          value: item.segmentation_item_id,
          label: item.item,
        })),
      );
    })();
  }, [courseId]);

  const roles = useMemo(() => {
    if (segTypes && segTypes.length) {
      const roleSegType = segTypes.find(segType => segType.type === 'Cargo');
      if (roleSegType) {
        if (roleSegType.items && roleSegType.items.length) {
          return roleSegType.items.map(item => ({
            label: item.item,
            value: item.segmentation_item_id,
          }));
        }
      }
    }

    return [];
  }, [segTypes]);

  const registrations = useMemo(() => {
    if (segTypes && segTypes.length) {
      const registrationType = segTypes.find(
        segType => segType.type === 'Matricula',
      );
      if (registrationType) {
        if (registrationType.items && registrationType.items.length) {
          return registrationType.items.map(item => ({
            label: item.item,
            value: item.segmentation_item_id,
          }));
        }
      }
    }

    return [];
  }, [segTypes]);

  return (
    <>
      <div className="tab-pane fade active show">
        <form
          onSubmit={e => updateCourse(e)}
          className="needs-validation"
          noValidate=""
        >
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group row">
                <label htmlFor="input-titulo" className="col-sm-2">
                  <span>*</span> Título
                </label>

                <input
                  className="form-control col-md-10"
                  id="input-titulo"
                  type="text"
                  required="required"
                  maxLength={50}
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                />
              </div>

              <div className="form-group row">
                <label htmlFor="input-descricao" className="col-sm-2">
                  <span>*</span> Descrição
                </label>

                <DefaultTextArea
                  className="form-control col-md-10"
                  id="input-descricao"
                  maxLength={1000}
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  required
                />
              </div>

              <div className="form-group row">
                <label htmlFor="input-conteudos" className="col-sm-2">
                  <span>*</span> Conteúdos
                </label>
                <div className="border rounded col-xl-10 col-md-8 p-3 text-center">
                  <button
                    className="btn btn-primary mb-3"
                    type="button"
                    onClick={selectContents}
                  >
                    Selecionar Conteúdos
                  </button>
                  <div className="row">
                    {selectedContents && selectedContents.length ? (
                      selectedContents.map(content => (
                        <div key={content.id} className="col-sm-12">
                          <div
                            className="card border"
                            style={{ marginBottom: '10px' }}
                          >
                            <div
                              className="card-body"
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '20px',
                              }}
                            >
                              <h4 style={{ margin: 0 }}>{content.title}</h4>
                              <div>
                                <div className="m-2">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      checkShouldShowChangePositionButton(
                                        content,
                                        true,
                                      ) && changeContentPosition(content, true)
                                    }
                                    className="btn btn-xs btn-light btn-small ml-2"
                                    disabled={
                                      !checkShouldShowChangePositionButton(
                                        content,
                                        true,
                                      )
                                    }
                                  >
                                    <ArrowUp size={22} />
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      checkShouldShowChangePositionButton(
                                        content,
                                        false,
                                      ) && changeContentPosition(content, false)
                                    }
                                    className="btn btn-xs btn-light btn-small ml-2"
                                    disabled={
                                      !checkShouldShowChangePositionButton(
                                        content,
                                        false,
                                      )
                                    }
                                  >
                                    <ArrowDown size={22} />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-2 col-md-4">Prova</label>
                <Select
                  isMulti={false}
                  options={[
                    {
                      value: '',
                      label:
                        'Selecione a prova a ser aplicada ao final do curso',
                    },
                    ...exams,
                  ]}
                  value={selectedExam}
                  onChange={selected => setSelectedExam(selected)}
                  placeholder="Selecione a prova a ser aplicada ao final do curso"
                  className="col-md-10 p-0"
                />
              </div>

              <div className="form-group row mb-1">
                <label className="col-xl-2 col-md-4">Imagem</label>
                <div className="text-center col-md-10 border rounded p-1">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={selectImage}
                  >
                    Selecionar Imagem
                  </button>

                  <div>
                    {thumbnail ? (
                      <img
                        className="mt-1"
                        style={{ width: '400px' }}
                        src={thumbnail}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-group row mb-2">
                <label className="col-xl-2 col-md-4" htmlFor="roles">
                  Cargo
                </label>
                <div className="text-center col-md-10 border rounded p-1">
                  <Select
                    id="roles"
                    isMulti={true}
                    options={roles}
                    value={selectedRoles}
                    onChange={options => setSelectedRoles(options)}
                  />
                </div>
              </div>

              <div className="form-group row mb-2">
                <label className="col-xl-2 col-md-4" htmlFor="registrations">
                  Matricula
                </label>
                <div className="text-center col-md-10 border rounded p-1">
                  <Select
                    id="registrations"
                    isMulti={true}
                    options={registrations}
                    value={selectedRegistrations}
                    onChange={options => setSelectedRegistrations(options)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pull-right">
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={() =>
                history.push(
                  `${process.env.PUBLIC_URL}/cursos-avulsos/listar-cursos-avulsos`,
                )
              }
            >
              Cancelar
            </button>

            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default TabsetEditarCurso;
import React from 'react';
import { Check, X } from 'react-feather';

const convertAuthorFromDB = (author) => (
  {
    'id': author.author_id,
    'nome': author.name,
    'descrição': author.description,
    'idade': author.info && author.info.day_of_birth
      ? (
          new Date(
            Math.abs(
              new Date(author.info.day_of_birth) - Date.now()
            )
          )
        ).getUTCFullYear() - 1970
      : '',
    'gênero': author.info && author.info.gender,
    'ativo': author.is_active ? <Check /> : <X />,
    'is_active': author.is_active
  }
);

export {
  convertAuthorFromDB
}
import React, { useRef } from "react";
import { ScormCoursesContainer } from "./style";
import Swal from "sweetalert2";
import getSignedUrl from "../../../services/scorm";

const ScormCourses = ({ setReference, setIsScormVersion12 }) => {
  const uploadScormInput = useRef(null);

  const handleScormFile = async (event) => {
    const loading = document.getElementById('loading');
    loading.style.display = 'flex';
    const acceptedFiles = Array.from(event.currentTarget.files);
    try {
      let pathIndexInFolder = "";

      const searchIndexInFolder = (
        fileImsmanifest => {
          return new Promise((resolve, reject) => {
            const readerManifest = new FileReader();
            readerManifest.readAsText(fileImsmanifest);
            readerManifest.onload = (data) => {
              try {
                const readerData = data.target.result;
                if (typeof readerData == "string") {
                  const parser = new DOMParser();
                  const xml = parser.parseFromString(readerData, "text/xml");
                  const schemaVersion = xml.querySelector("schemaversion");
                  if (!schemaVersion) {
                    throw new Error("Não foi possível encontrar a versão do scorm.");
                  }
                  setIsScormVersion12(schemaVersion.textContent?.trim() === '1.2');
                  const teste = xml.querySelector("resources");
                  const resourceWithHref = teste?.children[0];
                  const hrefIndex =
                    resourceWithHref?.getAttribute("href") || "";
                  pathIndexInFolder = hrefIndex.split("?")[0];
                  if (!pathIndexInFolder) {
                    resolve(pathIndexInFolder);
                  } else {
                    resolve(
                      `${fileImsmanifest.webkitRelativePath.split("/")[0]
                      }/${pathIndexInFolder}`
                    );
                  }
                }
              } catch (error) {
                reject(error.message);
              }
            };
          });
        });

      const uploadFileScorm = (
        file,
        contentType,
        uploadURL,
        index,
      ) => {
        return new Promise((resolve, reject) => {
          try {
            const reader = new FileReader();
            if (file.size > 100000000) {
              reader.onload = async (data) => {
                const buffer = data.target.result;

                const fileBlob = new Blob([new Uint8Array(buffer)], {
                  type: file.type,
                });

                await fetch(uploadURL, {
                  method: "PUT",
                  body: fileBlob,
                  headers: {
                    "Access-Control-Allow-Origin": "*",
                  },
                });
                if (index === acceptedFiles.length) {
                  const pathIndex = `${process.env.REACT_APP_ASSETS_SCORM
                    }scorm/vallourec/${encodeURI(pathIndexInFolder)}`;

                  setReference(pathIndex);
                  Swal.fire({
                    title: "Sucesso!",
                    icon: "success",
                    text: "Upload do scorm realizado com sucesso!",
                  });
                }
                resolve();
              };
              reader.readAsArrayBuffer(file);
            } else {
              reader.onload = async (data) => {
                const dataFileUrl = data.target.result;
                let array = [];
                if (!!dataFileUrl.split(",")[1]) {
                  let binary = atob(dataFileUrl.split(",")[1]);
                  for (let i = 0; i < binary.length; i++) {
                    array.push(binary.charCodeAt(i));
                  }
                }
                let blobData = new Blob([new Uint8Array(array)], {
                  type: contentType,
                });

                await fetch(uploadURL, {
                  method: "PUT",
                  body: blobData,
                  headers: {
                    "Access-Control-Allow-Origin": "*",
                  },
                });
                if (index === acceptedFiles.length) {
                  const pathIndex = `${process.env.REACT_APP_ASSETS_SCORM
                    }scorm/vallourec/${encodeURI(pathIndexInFolder)}`;

                  setReference(pathIndex);
                  Swal.fire({
                    title: "Sucesso!",
                    icon: "success",
                    text: "Upload do scorm realizado com sucesso!",
                  });
                }
                resolve();
              };
              reader.readAsDataURL(file);
            }
          } catch (error) {
            reject();
          }
        });
      };
      let hasImsmanifest = false;

      for (const file of acceptedFiles) {
        if (file.webkitRelativePath.includes("imsmanifest")) {
          try {
            hasImsmanifest = true;
            pathIndexInFolder = await searchIndexInFolder(file);
          } catch (error) {
            throw new Error(typeof error == 'string' ? error : 'Erro ao acessar o arquivo imsmanifest.xml');
          }
        }
      }
      if (!pathIndexInFolder && hasImsmanifest) {
        throw new Error(
          "Não foi possível encontrar o index base do scorm, no arquivo imsmanifest.xml"
        );
      }
      if (!hasImsmanifest) {
        throw new Error(
          "Não foi possível encontrar o arquivo imsmanifest.xml. Por favor insira o scorm na versão 1.2 ou 2004."
        );
      }
      let countFilesUpload = 0;
      for (const file of acceptedFiles) {
        let contentType = file.type;
        if (!contentType) {
          contentType =
            file.webkitRelativePath.split(".")[1] === "xsd"
              ? "application/xml"
              : "application/octet-stream";
        }

        const { uploadURL } = await getSignedUrl(contentType, file.path);
        await uploadFileScorm(
          file,
          contentType,
          uploadURL,
          ++countFilesUpload
        );
      }
      loading.style.display = 'none';
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: error.message,
      });
    }
  };


  return (
    <ScormCoursesContainer>
      <button
        className="btn btn-primary mr-2"
        type="button"
        onClick={() => {
          if (uploadScormInput.current) {
            uploadScormInput.current.click();
          }
        }}
      >
        Selecionar pasta do SCORM
      </button>
      <input
        ref={uploadScormInput}
        type="file"
        style={{ display: "none" }}
        multiple={true}
        {...{ directory: "", webkitdirectory: "" }}
        onChange={handleScormFile}
      />

    </ScormCoursesContainer>
  );
};

export default ScormCourses;

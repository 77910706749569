import React from 'react';
import Breadcrumb from '../common/breadcrumb';
import { generateRegisteredTrailsReport as generateReportService } from '../../services/reports';

const DisciplinasCadastradas = () => {
  const generateReport = async format => {
    await generateReportService(format);
  };

  return (
    <>
      <Breadcrumb
        title="Relatórios - Disciplinas Cadastradas"
        parent="Disciplinas Cadastradas"
      />
      <div className="container-fluid">
        <div className="card">
          <div className="card-header">
            <h5>Disciplinas Cadastradas</h5>
          </div>
          <div className="card-body">
            <form>
              <div className="d-flex justify-content-center align-items-center my-3">
                <button
                  type="button"
                  className="btn btn-primary mr-2"
                  onClick={() => generateReport('pdf')}
                >
                  Gerar Relatório (PDF)
                </button>

                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => generateReport('csv')}
                >
                  Gerar Relatório (CSV)
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default DisciplinasCadastradas;

import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumb from '../common/breadcrumb';
import Datatable from '../common/datatable';
import {
  getFaqs as getFaqsService,
  deleteFaq as deleteFaqService,
} from '../../services/faq';
import { Edit2, Trash2 } from 'react-feather';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { convertFaqFromDB } from '../../helpers/converters/faqConverter';

const ListFaqs = () => {
  const history = useHistory();
  const [faqs, setFaqs] = useState([]);

  let refreshContent = () => {};

  const getFAQs = useCallback(async () => {
    let localFaqs = await getFaqsService();
    localFaqs = localFaqs.map(convertFaqFromDB);
    localFaqs = localFaqs.map(faq => {
      const faqId = faq.id;

      faq['ações'] = (
        <>
          <button
            type="button"
            className="btn btn-xs btn-primary mr-1"
            onClick={() => {
              history.push(`${process.env.PUBLIC_URL}/faq/editar-faq/${faqId}`);
            }}
          >
            <Edit2 size={16} />
          </button>

          <button
            type="button"
            className="btn btn-xs btn-danger mr-1"
            onClick={async () => {
              Swal.fire({
                title: '<strong>Confirmação</strong>',
                html: 'Tem certeza que deseja remover este faq?',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                focusConfirm: false,
                icon: 'question',
              })
                .then(async result => {
                  if (result.isConfirmed) {
                    try {
                      await deleteFaqService(faqId);

                      toast.success('FAQ removido com sucesso!');

                      await refreshContent();
                    } catch (e) {
                      const response = e.response;

                      if (
                        response &&
                        response.status &&
                        response.status === 423
                      ) {
                        toast.error('A FAQ não pode ser removida');
                      } else {
                        toast.error('Erro ao remover faq.');
                      }
                    }
                  }
                })
                .catch(() => {});
            }}
          >
            <Trash2 size={16} />
          </button>
        </>
      );

      delete faq.id;

      return faq;
    });
    setFaqs(localFaqs);
  }, [history]);

  refreshContent = useCallback(async () => {
    setFaqs([]);
    await getFAQs();
  }, [getFAQs]);

  useEffect(() => {
    getFAQs();
  }, [getFAQs]);

  return (
    <Fragment>
      <Breadcrumb title="Listar Faqs" parent="FAQ" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Lista de Faqs</h5>
              </div>
              <div className="card-body">
                <div className="btn-popup pull-right">
                  <Link
                    to={`${process.env.PUBLIC_URL}/faq/criar-faq`}
                    className="btn btn-primary"
                  >
                    Criar FAQ
                  </Link>
                </div>
                <div className="clearfix"></div>
                <div>
                  {faqs && faqs.length ? (
                    <Datatable
                      myData={faqs}
                      pageSize={10}
                      pagination={true}
                      class="-striped -highlight"
                    />
                  ) : (
                    <div
                      className="
                          d-flex
                          justify-content-center
                          align-items-center
                        "
                    >
                      <span>Não há Faqs</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ListFaqs;

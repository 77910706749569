import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import 'react-toastify/dist/ReactToastify.css';

export default function Datatable({
  pageSize,
  myClass,
  pagination,
  customColumns,
  myData,
}) {
  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  let columns = [];

  if (!customColumns) {
    for (var key in myData[0]) {
      let editable = null;

      columns.push({
        Header: <b>{capitalize(key.toString())}</b>,
        accessor: key,
        Cell: editable,
        style: {
          textAlign: 'center',
        },
      });
    }
  } else {
    columns = customColumns.map(col => {
      return {
        ...col,
        Header: <b>{col.Header}</b>,
        style: {
          textAlign: 'center',
        },
      };
    });
  }

  return (
    <>
      <ReactTable
        data={myData}
        columns={columns}
        pageSize={pageSize}
        className={myClass}
        showPagination={pagination}
        showPageJump={false}
        pageText="Página"
        previousText="Anterior"
        nextText="Próxima"
        rowsText="Linhas"
        ofText="de"
      />
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumb from '../common/breadcrumb';

import {
  listQuestions,
  deleteQuestion,
  toggleQuestionStatus,
} from '../../services/question';

import Datatable from '../common/datatable';
import { Edit2, Trash2, Check, X } from 'react-feather';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const ListarQuestoes = () => {
  const [questions, setQuestions] = useState([]);
  const [rows, setRows] = useState([]);

  const history = useHistory();

  async function getQuestions() {
    const questions = await listQuestions();
    setQuestions(questions);
  }

  useEffect(() => {
    getQuestions();
  }, []);

  useEffect(() => {
    if (questions && questions.length) {
      const rowList = questions.map(({ enunciated, question_id, enabled }) => ({
        Enunciado: enunciated,
        ações: (
          <>
            <EditButton id={question_id} />
            <RemoveButton id={question_id} />
            <DisableButton id={question_id} isActive={enabled} />
          </>
        ),
      }));

      setRows(rowList);
    }
  }, [questions]);

  const removeQuestion = async id => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja remover esta questão?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
      icon: 'question'
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await deleteQuestion(id);

          toast.success('Questão removida com sucesso!');

          await getQuestions();
        } catch (e) {
          if (e.response.status === 403) {
            toast.error(
              `Erro ao remover questão. Essa questão está vinculada a uma prova.`,
            );
          } else {
            toast.error(`Erro ao remover questão. ${e.response.data.message}`);
          }
        }
      }
    });
  };

  const disableQuestion = async (id, isActive) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: `Tem certeza que deseja ${
        isActive ? 'inativar' : 'ativar'
      } esta questão?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
      icon: 'question'
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await toggleQuestionStatus(id, !isActive);

          toast.success(
            `Questão ${isActive ? 'inativada' : 'ativada'} com sucesso!`,
          );

          await getQuestions();
        } catch (e) {
          toast.error(
            `Erro ao ${isActive ? 'inativar' : 'ativar'} questão. ${
              e.response
            }`,
          );
        }
      }
    });
  };

  const EditButton = ({ id }) => {
    return (
      <button
        type="button"
        className="btn btn-xs btn-primary mr-1 pt-1"
        title="Editar Questão"
        onClick={() => {
          history.push(`/questoes/editar/${id}`);
        }}
      >
        <Edit2 size={18} />
      </button>
    );
  };

  const RemoveButton = ({ id }) => {
    return (
      <button
        type="button"
        className="btn btn-xs btn-danger mr-1 pt-1"
        title="Excluir Questão"
        onClick={async () => removeQuestion(id)}
      >
        <Trash2 size={18} />
      </button>
    );
  };

  const DisableButton = ({ id, isActive }) => {
    return (
      <button
        type="button"
        className="btn btn-xs btn-warning pt-1"
        title={`${isActive ? 'Inativar' : 'Ativar'} questão`}
        onClick={async () => disableQuestion(id, isActive)}
      >
        {isActive ? <X size={18} /> : <Check size={18} />}
      </button>
    );
  };

  return (
    <>
      <Breadcrumb title="Listar Questões" parent="Questões" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Lista de Questões</h5>
              </div>
              <div className="card-body">
                <div className="btn-popup pull-right">
                  <Link
                    to={`${process.env.PUBLIC_URL}/questoes/criar`}
                    className="btn btn-primary"
                  >
                    Criar Questão
                  </Link>
                </div>
                <div className="clearfix"></div>
                <div
                  id="batchDelete"
                  className="question-table order-table coupon-list-delete"
                >
                  {questions && questions.length ? (
                    <Datatable
                      myData={rows}
                      pageSize={20}
                      pagination={true}
                      class="-striped -highlight"
                    />
                  ) : (
                    <div className="d-flex justify-content-center align-items-center">
                      <span>Nenhuma questão encontrada</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListarQuestoes;

import React from 'react';
import { Check, X } from 'react-feather';

const convertCourseFromDB = (course) => {
  const contentsTitle = course.contents 
    && (course.contents.map(content => content.title) || [])
      .join(', ') ;
  
  return {
    'id': course.course_id,
    'capa': (<img src={course.thumbnail} style={{ width: 80, height: 80 }} alt="capa"/>),
    'titulo': course.title,
    'descrição': course.description,
    'aulas ': <span title={contentsTitle}>{ 
      contentsTitle
    }</span>,
    'ativo': course.is_active
      ? <Check size={18} />
      : <X size={18} />,
    'is_active': course.is_active
  }
};

export {
  convertCourseFromDB
}

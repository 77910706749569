import React, { useEffect, useMemo, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { useHistory, useParams } from 'react-router';
import Swal from 'sweetalert2';
import getErrorMessage from '../../helpers/get-error-message';
import {
  getCategory as getCategoryService,
  createCategory as createCategoryService,
  updateCategory as updateCategoryService,
} from '../../services/forum';
import checkEmptyString from '../../helpers/check-empty-string';

const CreateForumCategory = () => {
  const history = useHistory();
  const { categoryId } = useParams();
  const [description, setDescription] = useState('');

  const handleSubmit = event => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    if (isEditting) {
      updateCategory();
    } else {
      createCategory();
    }
  };

  const validate = () => {
    if (checkEmptyString(description)) {
      throw new Error('Informe uma descrição válida para a categoria!');
    }
  };

  const createCategory = async () => {
    try {
      validate();
      await createCategoryService({ description });
      gotoCategories();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        icon: 'error',
        text: 'Erro ao criar categoria de fórum. ' + errorMessage,
      });
    }
  };

  const updateCategory = async () => {
    try {
      validate();
      await updateCategoryService(categoryId, { description });
      gotoCategories();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        icon: 'error',
        text: 'Erro ao criar categoria de fórum. ' + errorMessage,
      });
    }
  };

  const gotoCategories = () => {
    history.push(`${process.env.PUBLIC_URL}/forum/categorias`);
  };

  const isEditting = useMemo(() => {
    return !!categoryId;
  }, [categoryId]);

  useEffect(() => {
    if (isEditting) {
      (async () => {
        const localCategory = await getCategoryService(categoryId);
        setDescription((localCategory || {}).description);
      })();
    }
  }, [isEditting]);

  return (
    <>
      <Breadcrumb
        title={`${isEditting ? 'Editar' : 'Criar'} Categoria`}
        parent="Categorias"
      />
      <div className="container-fluid">
        <div className="row">
          <form onSubmit={handleSubmit} className="col-sm-12">
            <div className="form-group row">
              <div className="col-md-2">
                <label htmlFor="description" className="control-label">
                  Descrição
                </label>
              </div>
              <div className="col-md-10">
                <input
                  className="form-control"
                  type="text"
                  id="description"
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                />
              </div>
            </div>
            <div className="pull-right">
              <button
                onClick={gotoCategories}
                type="button"
                className="btn btn-danger mr-1"
              >
                Cancelar
              </button>
              <button type="submit" className="btn btn-primary">
                Salvar
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateForumCategory;

import React from 'react';
import { Check, X } from 'react-feather';
import segToMin from '../convertTime';

const convertContentFromDB = (content) => (
  {
    'id': content.content_id,
    'titulo': content.title,
    'descrição': content.description,
    'duração': `${segToMin(content.duration)} min.`,
    'url': <a target="_blanc" href={content.reference}>{content.reference}</a>,
    'ativo': content.is_active
      ? <Check size={16} />
      : <X size={16} />,
    'is_active': content.is_active
  }
);

export {
  convertContentFromDB
} 

import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumb from '../common/breadcrumb';
import Datatable from '../common/datatable';
import {
  getVideos as getVideosService,
  deleteVideo as deleteVideoService,
  activateOrInactivateVideo as activateOrInactivateVideoService,
} from '../../services/videos';
import { Edit2, Trash2, X, Check } from 'react-feather';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const ListVideos = () => {
  const history = useHistory();

  const [contents, setVideos] = useState([]);

  let refreshContent = () => {};

  const getVideos = useCallback(async () => {
    let localVideos = await getVideosService();

    localVideos = localVideos.map(content => {
      const contentId = content.id;
      const isActive = content.is_active;

      content['ações'] = (
        <>
          <button
            type="button"
            className="btn btn-xs btn-primary mr-1"
            onClick={() => {
              history.push(
                `${process.env.PUBLIC_URL}/biblioteca/videos/editar-video/${contentId}`,
              );
            }}
          >
            <Edit2 size={16} />
          </button>

          <button
            type="button"
            className="btn btn-xs btn-danger mr-1"
            onClick={async () => {
              Swal.fire({
                title: '<strong>Confirmação</strong>',
                html: 'Tem certeza que deseja excluir este video?',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                focusConfirm: false,
                icon: 'question'
              }).then(async result => {
                if (result.isConfirmed) {
                  try {
                    await deleteVideoService(contentId);

                    toast.success('Vídeo removido com sucesso!');

                    await refreshContent();
                  } catch (e) {
                    const response = e.response;

                    if (
                      response &&
                      response.status &&
                      response.status === 423
                    ) {
                      toast.error(
                        'O vídeo não pode ser removida, pois está associada a pelo menos um módulo.',
                      );
                    } else {
                      toast.error('Erro ao remover vídeo.');
                    }
                  }
                }
              });
            }}
          >
            <Trash2 size={16} />
          </button>

          <button
            type="button"
            className="btn btn-xs btn-warning"
            title={`${content.is_active ? 'Inativar' : 'Ativar'} vídeo`}
            onClick={async () => {
              Swal.fire({
                title: '<strong>Confirmação</strong>',
                html: `Tem certeza que deseja ${
                  isActive ? 'inativar' : 'ativar'
                } este video?`,
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                focusConfirm: false,
                icon: 'question'
              }).then(async result => {
                if (result.isConfirmed) {
                  try {
                    await activateOrInactivateVideoService(
                      contentId,
                      !isActive,
                    );

                    toast.success(
                      `Vídeo ${isActive ? 'inativado' : 'ativado'} com sucesso!`,
                    );

                    await refreshContent();
                  } catch (e) {
                    const response = e.response;

                    if (
                      response &&
                      response.status &&
                      response.status === 423
                    ) {
                      toast.error(
                        `O vídeo não pode ser ${
                          isActive ? 'inativada' : 'ativada'
                        }, pois está associada a pelo menos um módulo.`,
                      );
                    } else {
                      toast.error(
                        `Erro ao ${isActive ? 'inativar' : 'ativar'} video.`,
                      );
                    }
                  }
                }
              });
            }}
          >
            {content.is_active ? <X size={18} /> : <Check size={18} />}
          </button>
        </>
      );

      delete content.id;
      delete content.is_active;

      return content;
    });

    setVideos(localVideos);
  }, [history]);

  refreshContent = useCallback(async () => {
    setVideos([]);
    await getVideos();
  }, [getVideos]);

  useEffect(() => {
    getVideos();
  }, [getVideos]);

  const contentListColumns = [
    {
      Header: 'Título',
      accessor: 'titulo',
      style: {
        padding: '0 16px',
      },
    },
    {
      Header: 'Descrição',
      accessor: 'descrição',
      width: 280,
      style: {
        padding: '0 16px',
      },
    },
    {
      Header: 'Duração',
      accessor: 'duração',
      width: 160,
    },
    {
      Header: 'Ativo',
      accessor: 'ativo',
      width: 90,
    },
    {
      Header: 'Ações',
      accessor: 'ações',
      width: 180,
    },
  ];

  return (
    <Fragment>
      <Breadcrumb title="Listar Videos" parent="Videos" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Lista de Videos</h5>
              </div>
              <div className="card-body">
                <div className="btn-popup pull-right">
                  <Link
                    to={`${process.env.PUBLIC_URL}/biblioteca/videos/criar-video`}
                    className="btn btn-primary"
                  >
                    Criar Video
                  </Link>
                </div>
                <div className="clearfix"></div>
                <div
                  id="batchDelete"
                  className="category-table order-table coupon-list-delete"
                >
                  {contents && contents.length ? (
                    <Datatable
                      customColumns={contentListColumns}
                      myData={contents}
                      pageSize={20}
                      pagination={true}
                      class="-striped -highlight"
                    />
                  ) : (
                    <div
                      className="
                          d-flex
                          justify-content-center
                          align-items-center
                        "
                    >
                      Nenhum vídeo encontrada
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ListVideos;

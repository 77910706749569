import httpClient from '../http-client';
import { convertTrailFromDB } from '../helpers/converters/trailConverter';

const getTrails = async () => {
  let trails = (await httpClient.get(`/trails`)).data;

  if (trails && trails.length) {
    trails = trails.map(convertTrailFromDB);
  }

  return trails;
};

const getTrail = async trailId => {
  if (!trailId) {
    throw new Error('O id do curso é obrigatório para obter seus dados!');
  }

  let trail = (await httpClient.get(`/trails/${trailId}`)).data;

  if (trail && trail.trail) {
    trail = trail.trail;
  }

  return trail;
};

const deleteTrail = async trailId => {
  if (!trailId) {
    throw new Error('O id do curso é obrigatório para a exclusão');
  }

  const deleteResponse = (await httpClient.delete(`/trails/${trailId}`)).data;

  return deleteResponse;
};

const createTrail = async newTrail => {
  if (!newTrail || !Object.keys(newTrail).length) {
    throw new Error('Os dados para criação do curso são obrigatórios!');
  }

  const createResponse = (await httpClient.post(`/trails`, newTrail)).data;

  return createResponse;
};

const updateTrail = async (trailId, newTrail) => {
  if (!trailId) {
    throw new Error('O id do curso é obrigatório para a edição');
  }

  if (!newTrail || !Object.keys(newTrail).length) {
    throw new Error('Os dados para atualização são obrigatórios');
  }

  const updateResponse = (await httpClient.put(`/trails/${trailId}`, newTrail))
    .data;

  return updateResponse;
};

const changeThumbnail = async (trailId, formData) => {
  const changeThumbnailResponse = (
    await httpClient.patch(`trails/${trailId}/thumbnail`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  ).data;

  return changeThumbnailResponse;
};

const activateOrInactivateTrail = async (trailId, active) => {
  const response = (
    await httpClient.put(`/trails/${trailId}/active`, { is_active: active })
  ).data;

  return response;
};

const getTrailsForSelect = async () => {
  let trails = (await httpClient.get(`/trails/`)).data;

  trails =
    trails &&
    trails.length &&
    trails.map(trail => ({
      value: trail.trail_id,
      label: trail.name,
    }));

  return trails;
};

const changeVideoReference = async (trailId, reference) => {
  const changeVideoReferenceResponse = await httpClient.patch(
    `/trails/${trailId}/video-reference`,
    {
      video_reference: reference,
    },
  );

  return changeVideoReferenceResponse;
};

export {
  getTrails,
  getTrail,
  createTrail,
  updateTrail,
  deleteTrail,
  activateOrInactivateTrail,
  changeThumbnail,
  changeVideoReference,
};

export { getTrailsForSelect };

import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumb from '../common/breadcrumb';
import Datatable from '../common/datatable';
import Swal from 'sweetalert2';
import {
  getContents as getContentsService,
  deleteContent as deleteContentService,
  activateOrInactivateContent as activateOrInactivateContentService,
} from '../../services/contents';
import { Edit2, Trash2, X, Check } from 'react-feather';
import { toast } from 'react-toastify';

const ListContents = () => {
  const history = useHistory();

  const [contents, setContents] = useState([]);

  const activateOrInactivateContent = async (contentId, active) => {
    await activateOrInactivateContentService(contentId, active);
    toast.success(`Aula ${!active ? 'inativada' : 'ativada'} com sucesso!`);
    await getContents();
  };

  const getContents = useCallback(async () => {
    setContents([]);
    let localContents = await getContentsService({ is_library: false });

    localContents = localContents.map(content => {
      const contentId = content.id;
      const isActive = content.is_active;

      content['ações'] = (
        <>
          <button
            type="button"
            className="btn btn-xs btn-primary mr-1"
            onClick={() => {
              history.push(
                `${process.env.PUBLIC_URL}/aulas/editar-aula/${contentId}`,
              );
            }}
          >
            <Edit2 size={16} />
          </button>

          <button
            type="button"
            className="btn btn-xs btn-danger mr-1"
            onClick={async () => {
              Swal.fire({
                title: '<strong>Confirmação</strong>',
                html: 'Tem certeza que deseja remover esta aula?',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                focusConfirm: false,
                icon: 'question',
              })
                .then(async result => {
                  if (result.isConfirmed) {
                    try {
                      await deleteContentService(contentId);
                      toast.success('Aula removida com sucesso!');
                      await getContents();
                    } catch (e) {
                      const response = e.response;

                      if (
                        response &&
                        response.status &&
                        response.status === 423
                      ) {
                        toast.error(
                          'A aula não pode ser removida, pois, a mesma está associada a um curso!',
                        );
                      } else {
                        toast.error('Erro ao remover aula.');
                      }
                    }
                  }
                })
                .catch(() => {});
            }}
          >
            <Trash2 size={16} />
          </button>

          <button
            type="button"
            className="btn btn-xs btn-warning"
            title={`${content.is_active ? 'Inativar' : 'Ativar'} aula`}
            onClick={async () => {
              Swal.fire({
                title: '<strong>Confirmação</strong>',
                html: `Tem certeza que deseja ${
                  isActive ? 'inativar' : 'ativar'
                } esta aula?`,
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                focusConfirm: false,
                icon: 'question',
              })
                .then(async result => {
                  if (result.isConfirmed) {
                    try {
                      await activateOrInactivateContent(contentId, !isActive);
                    } catch (e) {
                      const response = e.response;

                      if (
                        response &&
                        response.status &&
                        response.status === 423
                      ) {
                        toast.error(
                          `A aula não pode ser ${
                            isActive ? 'inativada' : 'ativada'
                          }, pois, a mesma está associada a um curso!`,
                        );
                      } else {
                        toast.error(
                          `Erro ao ${isActive ? 'inativar' : 'ativar'} aula. ${
                            e.message
                          }`,
                        );
                      }
                    }
                  }
                })
                .catch(() => {});
            }}
          >
            {content.is_active ? <X size={18} /> : <Check size={18} />}
          </button>
        </>
      );

      delete content.id;
      delete content.is_active;

      return content;
    });

    setContents(localContents);
  }, [history]);

  useEffect(() => {
    getContents();
  }, [getContents]);

  return (
    <Fragment>
      <Breadcrumb title="Listar Aulas" parent="Aula" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Lista de Aulas</h5>
              </div>
              <div className="card-body">
                <div className="btn-popup pull-right">
                  <Link
                    to={`${process.env.PUBLIC_URL}/aulas/criar-aula`}
                    className="btn btn-primary"
                  >
                    Criar Aula
                  </Link>
                </div>
                <div className="clearfix"></div>
                <div
                  id="batchDelete"
                  className="category-table order-table coupon-list-delete"
                >
                  {contents && contents.length ? (
                    <Datatable
                      myData={contents}
                      pageSize={10}
                      pagination={true}
                      class="-striped -highlight"
                    />
                  ) : (
                    <div
                      className="
                          d-flex
                          justify-content-center
                          align-items-center
                        "
                    >
                      <span>Não há Aulas</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ListContents;

import styled from "styled-components";

const ModalContentContainer = styled.div`
  @keyframes fadeIn {
    0% {
      background: rgba(255, 255, 255, 0);
    }
    100% {
      background: rgba(255, 255, 255, 1);
    }
  }

  width: 80%;
  height: 80%;
  background: white;
  border-radius: 15px;
  animation: fadeIn 0.3s;
`;

const ModalHeader = styled.div`
  padding: 25px;
  border-bottom: solid 1px #ddd;;
  height: 10%;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalCloseButton = styled.button`
  border-radius: 50%;
  width: 35px;
  height: 35px;
  border: solid 1px #ddd;;
  background: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }

  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalTitle = styled.h2`
  font-size: var(--font-medium);
  color: var(--default-title-color);
`;

const ModalContent = styled.div`
  padding: 15px;
  height: 90%;
`;

export {
  ModalContentContainer,
  ModalHeader,
  ModalCloseButton,
  ModalTitle,
  ModalContent,
};

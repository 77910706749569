import React from 'react';
import Breadcrumb from '../common/breadcrumb';
import Tabset from './tabset-criar-autor';

const CriarAutor = () => {
  return (
    <>
      <Breadcrumb title="Adicionar Autor" parent="Autores" />
      <div className="container-fluid">
        <div className="card">
          <div className="card-header">
            <h5>Criar Autor</h5>
          </div>
          <div className="card-body">
            <Tabset />
          </div>
        </div>
      </div>
    </>
  );
}

export default CriarAutor;

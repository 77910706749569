import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumb from '../common/breadcrumb';
import { listExams, deleteExam, toggleExamStatus } from '../../services/exams';
import Datatable from '../common/datatable';
import { Edit2, Trash2, Check, X } from 'react-feather';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const ListarProvas = () => {
  const [exams, setExams] = useState([]);
  const [rows, setRows] = useState([]);

  const history = useHistory();

  async function getExams() {
    const exams = await listExams();
    setExams(exams);
  }

  useEffect(() => {
    getExams();
  }, []);

  useEffect(() => {
    if (exams && exams.length) {
      const rowList = exams.map(({ title, exam_id, enabled }) => ({
        Provas: title,
        ações: (
          <>
            <EditButton id={exam_id} />
            <RemoveButton id={exam_id} />
            <DisableButton id={exam_id} isActive={enabled} />
          </>
        ),
      }));

      setRows(rowList);
    }
  }, [exams]);

  const removeExam = async id => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja remover esta prova?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
      icon: 'question'
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await deleteExam(id);

          toast.success('Prova removida com sucesso!');

          await getExams();
        } catch (e) {
          toast.error(`Erro ao remover prova. ${e.response}`);
        }
      }
    });
  };

  const disableExam = async (id, isActive) => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: `Tem certeza que deseja ${
        isActive ? 'inativar' : 'ativar'
      } esta prova?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
      icon: 'question'
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await toggleExamStatus(id, !isActive);

          toast.success(
            `Prova ${isActive ? 'inativada' : 'ativada'} com sucesso!`,
          );

          await getExams();
        } catch (e) {
          toast.error(
            `Erro ao ${isActive ? 'inativar' : 'ativar'} prova. ${e.response}`,
          );
        }
      }
    });
  };

  const EditButton = ({ id }) => {
    return (
      <button
        type="button"
        className="btn btn-xs btn-primary mr-1 pt-1"
        title="Editar Prova"
        onClick={() => {
          history.push(`/provas/editar/${id}`);
        }}
      >
        <Edit2 size={18} />
      </button>
    );
  };

  const RemoveButton = ({ id }) => {
    return (
      <button
        type="button"
        className="btn btn-xs btn-danger mr-1 pt-1"
        title="Excluir Prova"
        onClick={async () => removeExam(id)}
      >
        <Trash2 size={18} />
      </button>
    );
  };

  const DisableButton = ({ id, isActive }) => {
    return (
      <button
        type="button"
        className="btn btn-xs btn-warning pt-1"
        title={`${isActive ? 'Inativar' : 'Ativar'} prova`}
        onClick={async () => disableExam(id, isActive)}
      >
        {isActive ? <X size={18} /> : <Check size={18} />}
      </button>
    );
  };

  return (
    <>
      <Breadcrumb title="Listar Provas" parent="Provas" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Lista de Provas</h5>
              </div>
              <div className="card-body">
                <div className="btn-popup pull-right">
                  <Link
                    to={`${process.env.PUBLIC_URL}/provas/criar`}
                    className="btn btn-primary"
                  >
                    Criar Prova
                  </Link>
                </div>
                <div className="clearfix"></div>
                <div
                  id="batchDelete"
                  className="question-table order-table coupon-list-delete"
                >
                  {exams && exams.length ? (
                    <Datatable
                      myData={rows}
                      pageSize={20}
                      pagination={true}
                      class="-striped -highlight"
                    />
                  ) : (
                    <div className="d-flex justify-content-center align-items-center">
                      <span>Nenhuma prova encontrada</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListarProvas;

import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { createContent as createContentService } from '../../services/videos';
import { toast } from 'react-toastify';
import SelectVideoModal from '../select-video-modal';
import { hideModal, showModal } from '../modal';
import { uploadFile } from '../../services/files';
import SelectImage from '../select-image';

const Tabset = () => {
  const history = useHistory();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('');
  const [duration, setDuration] = useState(0);
  const [contentReference, setContentReference] = useState('');
  const [shouldShowSelectVideoModal, setShouldShowSelectVideoModal] =
    useState(false);
  const [imageToUpload, setImageToUpload] = useState('');
  const [image, setImage] = useState('');

  const selectImage = () => {
    showModal(
      'Cortar Imagem',
      <SelectImage aspect={16 / 9} onCutImage={onCutImage} />,
    );
  };

  const onCutImage = file => {
    if (file) {
      setImageToUpload(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setImage(`${reader.result}`);

      hideModal();
    }
  };

  const gotoLives = () => {
    history.push(`${process.env.PUBLIC_URL}/lives/listar-lives`);
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const createContent = async event => {
    event.preventDefault();

    try {
      if (!contentReference) {
        throw new Error('Selecione um vídeo antes de cria-lo.');
      }

      if (!title || !title.replace(/[!@#$%¨&()_+=~^<>*|"]/g, '').trim()) {
        throw new Error('Informe um titulo para a video!');
      }

      if (
        !description ||
        !description.replace(/[!@#$%¨&()_+=~^<>*|"]/g, '').trim()
      ) {
        throw new Error('Informe uma descrição para a video!');
      }

      const newContent = {
        title: title,
        description: description,
        type: type,
        duration: duration,
        reference: contentReference,
        is_library: true,
      };

      if (image && image.length && image.includes('base64')) {
        try {
          const thumbnail = dataURLtoFile(image, `thumbnail-${title}.png`);

          const formData = new FormData();
          formData.append('file', thumbnail);

          const { reference } = await uploadFile(formData);
          newContent.thumbnail = reference;
        } catch (e) {
          throw new Error('Erro ao incluir a imagem de capa. ' + e.message);
        }
      }

      await createContentService(newContent, true);

      toast.success('Vídeo criado com sucesso!');

      history.push('/biblioteca/videos/listar-videos');
    } catch (e) {
      toast.error('Erro ao cadastrar vídeo. ' + e.message);
    }
  };

  const selectContent = async (mediaId, localType, localDuration) => {
    try {
      const playerKey = process.env.REACT_APP_PLAYER_KEY;
      const localContentReference = `${process.env.REACT_APP_PLAYER_INITIAL_URL}/${playerKey}/${mediaId}`;

      setContentReference(localContentReference);
      setType(localType);
      setDuration(parseInt(localDuration / 1000));

      setShouldShowSelectVideoModal(false);
    } catch (e) {
      toast.error('Houve um erro ao selecionar o vídeo. ' + e.message);
    }
  };

  return (
    <>
      <div className="tab-pane fade active show">
        <form onSubmit={e => createContent(e)}>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group row">
                <label htmlFor="titulo" className="col-sm-2">
                  <span className="required">*</span> Título
                </label>

                <input
                  className="form-control col-md-10"
                  id="titulo"
                  type="text"
                  maxLength={100}
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                />
              </div>

              <div className="form-group row">
                <label htmlFor="descricao" className="col-sm-2">
                  <span className="required">*</span> Descrição
                </label>

                <textarea
                  className="form-control col-md-10"
                  id="descricao"
                  rows={6}
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                ></textarea>
              </div>

              <div className="form-group row">
                <label htmlFor="select-content" className="col-sm-2">
                  <span className="required">*</span> Vídeo
                </label>

                <div className="col-md-10 p-0 border py-1 d-flex flex-column justify-content-center">
                  <div className="d-flex justify-content-center align-items-center w-100">
                    <div className="ml-3 mr-2">
                      <button
                        id="select-content"
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                          setShouldShowSelectVideoModal(true);
                        }}
                      >
                        Selecionar Vídeo
                      </button>
                    </div>
                  </div>

                  <div className="w-100 my-3 d-flex justify-content-center">
                    {contentReference && (
                      <iframe
                        title="content"
                        style={{
                          width: '450px',
                          height: '253px',
                          border: 'none',
                          outline: 'none',
                        }}
                        src={contentReference}
                      ></iframe>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-group row" style={{ padding: '5px' }}>
                <label htmlFor="descricao" className="col-sm-2">
                  <span>*</span> Capa
                </label>
                <div className="text-center col-md-10 border rounded p-1">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={selectImage}
                  >
                    Selecionar Imagem
                  </button>

                  <div>
                    {image ? (
                      <img
                        className="mt-1"
                        style={{ width: '400px' }}
                        src={image}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pull-right">
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={() => {
                history.push(
                  `${process.env.PUBLIC_URL}/biblioteca/videos/listar-videos`,
                );
              }}
            >
              Cancelar
            </button>

            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </form>
      </div>

      {shouldShowSelectVideoModal && (
        <SelectVideoModal
          setShouldShowSelectVideoModal={setShouldShowSelectVideoModal}
          selectContent={selectContent}
        />
      )}
    </>
  );
};

export default Tabset;

const secondsToTimeText = seconds => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(((seconds % 3600) % 60));

  return `${hours > 0 ? `${hours}h, ` : ''} ${
    minutes ? `${minutes}min, ` : ''
  } ${remainingSeconds}seg`;
};

export default secondsToTimeText;

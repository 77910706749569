import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import Tabset from './tabset-criar-categoria';

export class CriarCategoria extends Component {
  render() {
    return (
      <Fragment>
        <Breadcrumb title="Criar categoria" parent="Categorias" />
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <h5>Criar Categoria</h5>
            </div>
            <div className="card-body">
              <Tabset />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default CriarCategoria;

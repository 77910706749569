import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  createAuthor as createAuthorService,
} from '../../services/authors';

const Tabset = () => {
  const history = useHistory();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [gender, setGender] = useState('');
  const [birthDay, setBirthDay] = useState('');

  const createAuthor = async event => {
    event.preventDefault();

    try {
      if (!name || !name.replace(/[!@#$%¨&()_+=~^<>|"]/g, '').trim()) {
        throw new Error('Informe um nome para o autor!');
      }

      if (!description || !description.replace(/[!@#$%¨&()_+=~^<>|"]/g, '').trim()) {
        throw new Error('Informe uma descrição para o autor!');
      }

      const newAuthor = {
        name: name,
        description: description,
        info: {
          gender: gender,
          day_of_birth: birthDay,
        },
      };

      await createAuthorService(newAuthor);

      toast.success('Autor cadastrado com sucesso!');

      history.push(`${process.env.PUBLIC_URL}/autores/listar-autores`);
    } catch (e) {
      toast.error('Erro ao cadastrar autor. ' + e.message);
    }
  };

  return (
    <>
      <div className="tab-pane fade active show">
        <form onSubmit={e => createAuthor(e)} className="needs-validation">
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group row">
                <label htmlFor="input-nome" className="col-sm-2">
                  <span>*</span> Nome
                </label>

                <input
                  className="form-control col-md-10"
                  id="input-nome"
                  type="text"
                  required="required"
                  maxLength={45}
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </div>

              <div className="form-group row">
                <label htmlFor="input-descricao" className="col-sm-2">
                  <span>*</span> Descricao
                </label>

                <input
                  className="form-control col-md-10"
                  id="input-descricao"
                  type="text"
                  required="required"
                  maxLength={45}
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                />
              </div>

              <div className="form-group row">
                <div className="col-sm-12">
                  <div className="row">
                    <label htmlFor="input-genero" className="col-sm-2">
                      Gênero
                    </label>

                    <select
                      className="form-control col-sm-4"
                      id="input-genero"
                      maxLength={45}
                      value={gender}
                      onChange={e => setGender(e.target.value)}
                    >
                      <option value="" defaultChecked>
                        Selecione o gênero
                      </option>
                      <option value="Masculino">Masculino</option>
                      <option value="Feminino">Feminino</option>
                    </select>

                    <label htmlFor="input-data" className="col-sm-2">
                      Data de Nascimento
                    </label>

                    <input
                      id="input-data"
                      type="date"
                      className="form-control col-sm-4"
                      value={birthDay}
                      onChange={e => setBirthDay(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pull-right">
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={() => {
                history.push(
                  `${process.env.PUBLIC_URL}/autores/listar-autores`,
                );
              }}
            >
              Cancelar
            </button>

            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Tabset;

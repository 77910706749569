import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Breadcrumb from '../common/breadcrumb';
import Datatable from '../common/datatable';
import {
  deleteCourse,
  getCourses as getCoursesService,
  activateOrInactivateCourse as activateOrInactivateCourseService,
} from '../../services/courses';
import { Edit2, Trash2, X, Check } from 'react-feather';
import Swal from 'sweetalert2';

const ListarCursos = () => {
  const [courses, setCourses] = useState([]);
  const history = useHistory();

  const getCourses = useCallback(async () => {
    setCourses([]);
    let localCourses = await getCoursesService({ is_single: false });

    localCourses = localCourses.map(course => {
      const courseId = course.id;
      const isActive = course.is_active;

      course['ações'] = (
        <>
          <button
            type="button"
            className="btn btn-xs btn-primary mr-1"
            title="Editar curso"
            onClick={() => {
              history.push(`/cursos/editar-curso/${courseId}`);
            }}
          >
            <Edit2 size={18} />
          </button>

          <button
            type="button"
            className="btn btn-xs btn-danger mr-1"
            title="Excluir curso"
            onClick={async () => {
              Swal.fire({
                title: '<strong>Confirmação</strong>',
                html: 'Tem certeza que deseja remover este conteúdo?',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                focusConfirm: false,
                icon: 'question',
              }).then(async result => {
                if (result.isConfirmed) {
                  try {
                    await deleteCourse(courseId);
                    toast.success('Curso removido com sucesso!');
                    await getCourses();
                  } catch (e) {
                    const response = e.response && e.response;

                    if (
                      response &&
                      response.status &&
                      response.status === 423
                    ) {
                      toast.error('O curso não pode ser removido, pois, está associado a uma disciplina!');
                    } else {
                      toast.error('Erro ao remover curso.');
                    }
                  }
                }
              });
            }}
          >
            <Trash2 size={18} />
          </button>

          <button
            type="button"
            className="btn btn-xs btn-warning"
            title={`${course.is_active ? 'Inativar' : 'Ativar'} curso`}
            onClick={async () => {
              Swal.fire({
                title: '<strong>Confirmação</strong>',
                html: `Tem certeza que deseja ${
                  isActive ? 'inativar' : 'ativar'
                } este curso?`,
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                focusConfirm: false,
                icon: 'question',
              }).then(async result => {
                if (result.isConfirmed) {
                  try {
                    await activateOrInactivateCourse(courseId, !isActive);
                  } catch (e) {
                    const response = e.response && e.response;

                    if (
                      response &&
                      response.status &&
                      response.status === 423
                    ) {
                      toast.error(
                        `O curso não pode ser ${
                          isActive ? 'inativado' : 'ativado'
                        }, pois, está associado a uma disciplina!`,
                      );
                    } else {
                      toast.error(
                        `Erro ao ${isActive ? 'inativar' : 'ativar'} curso. ${
                          e.message
                        }`,
                      );
                    }
                  }
                }
              });
            }}
          >
            {course.is_active ? <X size={18} /> : <Check size={18} />}
          </button>
        </>
      );

      delete course.id;
      delete course.is_active;

      return course;
    });

    setCourses(localCourses);
  }, [history]);

  const activateOrInactivateCourse = async (courseId, active) => {
    await activateOrInactivateCourseService(courseId, active);
    toast.success(`Curso ${!active ? 'inativado' : 'ativado'} com sucesso!`);
    await getCourses();
  };

  useEffect(() => {
    getCourses();
  }, [getCourses]);

  return (
    <>
      <Breadcrumb title="Listar Cursos" parent="Cursos" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Lista de Cursos</h5>
              </div>
              <div className="card-body">
                <div className="btn-popup pull-right">
                  <Link
                    to={`${process.env.PUBLIC_URL}/cursos/criar-curso`}
                    className="btn btn-primary"
                  >
                    Criar Curso
                  </Link>
                </div>
                <div className="clearfix"></div>
                <div
                  id="batchDelete"
                  className="category-table order-table coupon-list-delete"
                >
                  {courses && courses.length ? (
                    <Datatable
                      myData={courses}
                      pageSize={10}
                      pagination={true}
                      class="-striped -highlight"
                    />
                  ) : (
                    <div
                      className="
                          d-flex
                          justify-content-center
                          align-items-center
                        "
                    >
                      <span>Nenhum curso</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListarCursos;

import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Breadcrumb from '../common/breadcrumb';
import { generateFinishedTrailsReport as generateReportService } from '../../services/reports';
import { getTrailsForSelect } from '../../services/trails';
import { getUsersForSelect } from '../../services/users';

const dataAtual = `${`${new Date().getFullYear()}-${(new Date().getMonth() + 1)
  .toString()
  .padStart(2, '0')}-${new Date().getDate()}`}`;

const DisciplinasFinalizadas = () => {
  const [trails, setTrails] = useState([]);
  const [selectedTrails, setSelectedTrails] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [courseInitMin, setCourseInitMin] = useState('');
  const [courseInitMax, setCourseInitMax] = useState('');
  const [courseFinishMin, setCourseFinishMin] = useState('');
  const [courseFinishMax, setCourseFinishMax] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let localTrails = await getTrailsForSelect();
    setTrails(localTrails);

    let localUsers = await getUsersForSelect();
    setUsers(localUsers);
  };

  const generateReport = async format => {
    const queryStrings = {
      trail_id_ft: selectedTrails.map(trail => trail.value),
      user_id_ft: selectedUsers.map(user => user.value),
      start_at_gte: courseInitMin,
      start_at_lte: courseInitMax,
      finish_at_gte: courseFinishMin,
      finish_at_lte: courseFinishMax,
    };

    for (let prop of Object.keys(queryStrings)) {
      if (!queryStrings[prop]) {
        delete queryStrings[prop];
      }
    }

    await generateReportService(format, queryStrings);
  };

  return (
    <>
      <Breadcrumb
        title="Relatórios - Disciplinas Finalizadas"
        parent="Relatórios"
      />
      <div className="container-fluid">
        <div className="card">
          <div className="card-header">
            <h5>Disciplinas Finalizadas</h5>
          </div>
          <div className="card-body">
            <form>
              <div
                style={{
                  width: '100%',
                }}
                className="mb-2"
              >
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <div
                    style={{
                      width: '100%',
                    }}
                  >
                    <label htmlFor="usuarios">Usuários</label>

                    <Select
                      id="usuarios"
                      styles={{
                        width: '100%',
                      }}
                      isMulti={true}
                      options={users}
                      value={selectedUsers}
                      onChange={options => setSelectedUsers(options || [])}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-center mb-2">
                  <div
                    style={{
                      width: '100%',
                    }}
                  >
                    <label htmlFor="trilhas">Disciplinas</label>

                    <Select
                      id="trilhas"
                      styles={{
                        width: '100%',
                      }}
                      isMulti={true}
                      options={trails}
                      value={selectedTrails}
                      onChange={options => setSelectedTrails(options || [])}
                    />
                  </div>
                </div>

                <label htmlFor="inicio">
                  Data de Inicio da Disciplina (De - Até)
                </label>

                <div className="d-flex justify-content-between align-items-center">
                  <input
                    id="inicio"
                    type="date"
                    className="form-control"
                    style={{
                      width: '48%',
                    }}
                    value={courseInitMin}
                    onKeyDown={e => e.preventDefault()}
                    onChange={e => setCourseInitMin(e.target.value)}
                    max={courseInitMax || dataAtual}
                  />

                  <input
                    id="fim"
                    type="date"
                    className="form-control"
                    style={{
                      width: '48%',
                    }}
                    value={courseInitMax}
                    onKeyDown={e => e.preventDefault()}
                    onChange={e => setCourseInitMax(e.target.value)}
                    min={courseInitMin}
                    max={dataAtual}
                  />
                </div>
              </div>

              <div
                style={{
                  width: '100%',
                }}
              >
                <label htmlFor="inicio">
                  Data de Finalização da Disciplina (De - Até)
                </label>

                <div className="d-flex justify-content-between align-items-center mb-2">
                  <input
                    id="inicio"
                    type="date"
                    className="form-control"
                    style={{
                      width: '48%',
                    }}
                    value={courseFinishMin}
                    onKeyDown={e => e.preventDefault()}
                    onChange={e => setCourseFinishMin(e.target.value)}
                    max={courseFinishMax || dataAtual}
                  />

                  <input
                    id="fim"
                    type="date"
                    className="form-control"
                    style={{
                      width: '48%',
                    }}
                    value={courseFinishMax}
                    onKeyDown={e => e.preventDefault()}
                    onChange={e => setCourseFinishMax(e.target.value)}
                    min={courseFinishMin}
                    max={dataAtual}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-center align-items-center my-3">
                <button
                  type="button"
                  className="btn btn-primary mr-2"
                  onClick={() => generateReport('pdf')}
                >
                  Gerar Relatório (PDF)
                </button>

                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => generateReport('csv')}
                >
                  Gerar Relatório (CSV)
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default DisciplinasFinalizadas;

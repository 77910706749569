import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import Tabset from './tabset-editar-video';

export class EditVideo extends Component {
  render() {
    return (
      <Fragment>
        <Breadcrumb title="Editar Video" parent="Videos" />
        <div className="container-fluid">
          <div 
            className="card"
            style={{ 
              minHeight: '75vh'
            }}
          >
            <div className="card-header">
              <h5>Editar Video</h5>
            </div>
            <div className="card-body">
              <Tabset />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default EditVideo;

import httpClient from '../http-client';
import { convertVideoFromDB } from '../helpers/converters/videoConverter';

const getVideos = async () => {
  let videos = (
    await httpClient.get(`/contents`, { params: { is_library: true, type: 'video' } })
  ).data;

  videos = videos.filter(
    item => item.type === 'VIDEO' || item.type === 'video',
  );

  if (videos && videos.length) {
    videos = videos.map(convertVideoFromDB);
  }

  return videos;
};

const getVideosForSelect = async () => {
  let videos = (await httpClient.get(`/contents`)).data;

  if (videos && videos.length) {
    videos = videos
      .filter(video => video.is_active)
      .map(video => ({
        label: video.title,
        value: video.content_id,
      }));
  }

  return videos;
};

const getVideo = async contentId => {
  if (!contentId) {
    throw new Error('O id do conteúdo é obrigatório para obter seus dados!');
  }

  let content = (await httpClient.get(`/contents/${contentId}`)).data;

  return content;
};

const deleteVideo = async contentId => {
  if (!contentId) {
    throw new Error('O id do conteúdo é obrigatório para a exclusão!');
  }

  const contentDeleteResponse = (
    await httpClient.delete(`/contents/${contentId}`)
  ).data;

  return contentDeleteResponse;
};

const updateContent = async (contentId, newContent) => {
  if (!contentId) {
    throw new Error('O id do conteúdo é obrigatório para a edição!');
  }

  if (!newContent || !Object.keys(newContent).length) {
    throw new Error('Os dados para atualização do conteúdo são obrigatórios!');
  }

  const contentUpdateResponse = (
    await httpClient.put(`/contents/${contentId}`, {
      ...newContent,
      type: 'video',
      is_library: true,
    })
  ).data;

  return contentUpdateResponse;
};

const createContent = async newContent => {
  if (!newContent || !Object.keys(newContent).length) {
    throw new Error('Os dados para criação do conteúdo são obrigatórios!');
  }

  const createContentResponse = (
    await httpClient.post(`/contents`, {
      ...newContent,
      type: 'video',
    })
  ).data;

  return createContentResponse;
};

const activateOrInactivateVideo = async (contentId, active) => {
  if (!contentId) {
    throw new Error('O id do conteúdo é obrigatório.');
  }

  const activateOrInactivateVideoResponse = (
    await httpClient.put(`/contents/${contentId}/active`, { is_active: active })
  ).data;

  return activateOrInactivateVideoResponse;
};

export {
  getVideos,
  getVideo,
  deleteVideo,
  updateContent,
  createContent,
  getVideosForSelect,
  activateOrInactivateVideo,
};

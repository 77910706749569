import React, { useEffect, useMemo, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import Datatable from '../common/datatable';
import { getSegmentationTypes as getSegmentationTypesService } from '../../services/segmentations';
import { Edit } from 'react-feather';
import { useHistory } from 'react-router';

const TiposSegmentacao = () => {
  const history = useHistory();
  const [segTypes, setSegTypes] = useState([]);

  const getSegmentationTypes = async () => {
    const getSegTypes = await getSegmentationTypesService();
    const localSegTypes = getSegTypes.filter(item => item.type !== 'Generic');

    setSegTypes(localSegTypes);
  };

  const editSegType = segTypeId => {
    history.push(`${process.env.PUBLIC_URL}/segmentacao/editar/${segTypeId}`);
  };

  const segTypesToBeShown = useMemo(() => {
    if (segTypes && segTypes.length) {
      return segTypes.map(segType => ({
        nome: segType.type,
        ações: (
          <>
            <button
              className="btn btn-xs btn-primary"
              onClick={() => editSegType(segType.segmentation_type_id)}
            >
              <Edit />
            </button>
          </>
        ),
      }));
    }

    return [];
  }, [segTypes]);

  useEffect(() => {
    getSegmentationTypes();
  }, []);

  return (
    <>
      <Breadcrumb title="Listar Tipos de Segmentação" parent="Segmentação" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Lista de Tipos de Segmentação</h5>
              </div>
              <div className="card-body">
                <div className="clearfix"></div>
                <div
                  id="batchDelete"
                  className="category-table order-table coupon-list-delete"
                >
                  {segTypesToBeShown && segTypesToBeShown.length ? (
                    <Datatable
                      multiSelectOption={false}
                      myData={segTypesToBeShown}
                      pageSize={10}
                      pagination={true}
                      class="-striped -highlight"
                    />
                  ) : (
                    <div
                      className="
                        d-flex 
                        justify-content-center 
                        align-items-center
                      "
                    >
                      <span>Nenhum tipo de segmentação</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TiposSegmentacao;

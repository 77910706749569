import React from 'react';
import Breadcrumb from '../common/breadcrumb';
import Tabset from './tabset-editar-trilha';

const EditarTrilha = () => {
  return (
    <>
      <Breadcrumb title="Editar Disciplina" parent="Disciplinas" />
      <div className="container-fluid">
        <div className="card">
          <div className="card-header">
            <h5>Editar Disciplina</h5>
          </div>
          <div className="card-body">
            <Tabset />
          </div>
        </div>
      </div>
    </>
  );
}

export default EditarTrilha;

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createCategory as createCategoryService } from '../../services/categories';

const TabSetCriarCategoria = () => {
  const history = useHistory();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const createCategory = async event => {
    event.preventDefault();

    try {
      if (!title || !title.replace(/[!@#$%¨&()_+=~^<>|"]/g, '').trim()) {
        throw new Error('Informe um nome para a categoria!');
      }

      if (!description || !description.replace(/[!@#$%¨&()_+=~^<>|"]/g, '').trim()) {
        throw new Error('Informe uma descrição para a categoria!');
      }

      const newCategory = {
        title: title,
        description: description,
      };

      await createCategoryService(newCategory);

      toast.success('Categoria criada com sucesso!');

      history.push(`/categorias/listar-categorias`);
    } catch (e) {
      const errorMessage = e.response || e.message;
      toast.error('Erro ao criar categoria. ' + errorMessage);
    }
  };

  return (
    <>
      <div className="tab-pane fade active show">
        <form
          onSubmit={e => createCategory(e)}
          className="needs-validation"
          noValidate=""
        >
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group row">
                <label
                  htmlFor="title"
                  className="col-xl-2 col-md-4 d-flex justify-content-start"
                >
                  <span>*</span> Título
                </label>
                <input
                  id="title"
                  className="form-control col-md-7"
                  type="text"
                  required="required"
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                />
              </div>
              <div className="form-group row">
                <label
                  htmlFor="description"
                  className="col-xl-2 col-md-4 d-flex justify-content-start"
                >
                  <span>*</span> Descrição
                </label>
                <input
                  id="description"
                  className="form-control col-md-7"
                  type="text"
                  required="required"
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="pull-right">
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={() => {
                history.push(
                  `${process.env.PUBLIC_URL}/categorias/listar-categorias`,
                );
              }}
            >
              Cancelar
            </button>
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default TabSetCriarCategoria;

import React, { useEffect, useState, useMemo } from 'react';
import Breadcrumb from '../common/breadcrumb';
import {
  getTags as getTagsService,
  deleteTag as deleteTagService,
} from '../../services/forum';
import Datatable from '../common/datatable';
import { useHistory } from 'react-router';
import { Edit2, Trash } from 'react-feather';
import Swal from 'sweetalert2';
import getErrorMessage from '../../helpers/get-error-message';

const ForumTags = () => {
  const history = useHistory();
  const [tags, setTags] = useState([]);

  const editTag = tagId => {
    history.push(`${process.env.PUBLIC_URL}/forum/tags/criar-editar-tag/${tagId}`);
  };

  const deleteTag = async tagId => {
    Swal.fire({
      icon: 'question',
      text: 'Tem certeza que deseja excluir esta tag?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await deleteTagService(tagId);
          getTags();
        } catch (error) {
          const errorMessage = getErrorMessage(error);
          Swal.fire({
            icon: 'error',
            text: 'Erro ao excluir tag. ' + errorMessage,
          });
        }
      }
    });
  };

  const getTags = async () => {
    setTags([]);
    const localTags = await getTagsService();
    setTags(localTags);
  };

  const createTag = () => {
    history.push(`${process.env.PUBLIC_URL}/forum/tags/criar-editar-tag`);
  };

  const tagsToBeShown = useMemo(() => {
    return (tags || []).map(tag => ({
      descrição: tag.description,
      data: tag.created_at
        ? new Intl.DateTimeFormat('pt-BR').format(new Date(tag.created_at))
        : '',
      ações: (
        <>
          <button
            onClick={() => editTag(tag.tag_id)}
            type="button"
            className="btn btn-xs btn-primary mr-1"
          >
            <Edit2 size={16} />
          </button>
          <button
            onClick={() => deleteTag(tag.tag_id)}
            type="button"
            className="btn btn-xs btn-danger"
          >
            <Trash size={16} />
          </button>
        </>
      ),
    }));
  }, [tags]);

  useEffect(() => {
    getTags();
  }, []);

  return (
    <>
      <Breadcrumb title="Tags de Fórum" parent="Fórum" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="pull-right mb-2">
              <button className="btn btn-primary" onClick={createTag}>
                Criar Tag
              </button>
            </div>
          </div>
          <div className="col-sm-12">
            {tags && tags.length ? (
              <Datatable
                myData={tagsToBeShown}
                pageSize={10}
                pagination={true}
                class="-striped -highlight"
              />
            ) : (
              <div
                className="
                  d-flex
                  justify-content-center
                  align-items-center
                "
              >
                <span>Não há Tags</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ForumTags;

import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import Tabset from './tabset-criar-faq';

export class CreateContent extends Component {
  render() {
    return (
      <Fragment>
        <Breadcrumb title="Adicionar FAQ" parent="FAQ" />
        <div className="container-fluid">
          <div 
            className="card"
            style={{ 
              minHeight: '75vh'
            }}
          >
            <div className="card-header">
              <h5>Criar FAQ</h5>
            </div>
            <div className="card-body">
              <Tabset />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default CreateContent;

import React, { Component } from 'react';

export class UserPanel extends Component {
  render() {
    return (
      <div>
        <div className="sidebar-user text-center">
          <h6 className="mt-3 f-14">Admin</h6>
        </div>
      </div>
    );
  }
}

export default UserPanel;

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createQuestion as createQuestionService } from '../../services/question';
import { Trash2 } from 'react-feather';
import checkEmptyString from '../../helpers/check-empty-string';

const TabSetCriarQuestao = () => {
  const history = useHistory();

  const [enunciated, setEnunciated] = useState('');
  const [alternative, setAlternatives] = useState([]);
  const [answer, setCorrectAnswer] = useState();

  const createQuestion = async event => {
    event.preventDefault();

    try {
      if (checkEmptyString(enunciated)) {
        throw new Error('A pergunta não pode ser vazia!');
      }

      if (!alternative || alternative.length < 2) {
        throw new Error('Informe ao menos duas alternativas!');
      } else {
        const alts = alternative.map(({ value }) => value);
        const correct = alternative.map(({ is_correct }) => is_correct);

        if (alts.some(alt => checkEmptyString(alt))) {
          throw new Error('Todas as alternativas devem ser preenchidas!');
        }

        if (!correct.includes(true)) {
          throw new Error('Selecione uma resposta correta!');
        }
      }

      const orderedAlternative = alternative.map((alt, index) => ({
        ...alt,
        order: index + 1,
      }));

      const body = {
        enunciated,
        randomize_alternatives: false,
        alternative: orderedAlternative,
        points: 1,
        answer
      };

      await createQuestionService(body);

      toast.success('Questão criada com sucesso!');

      history.push(`${process.env.PUBLIC_URL}/questoes/listar`);
    } catch (e) {
      toast.error('Erro ao criar questão. ' + e.message);
    }
  };

  const createEmptyAlternative = () => {
    const alternatives = [...alternative, { value: '', is_correct: false }];
    setAlternatives(alternatives);
  };

  const editAlternativeText = (key, text) => {
    const alternatives = [...alternative];
    alternatives[key].value = text;
    setAlternatives(alternatives);
  };

  const editAlternativeIsCorrect = (key, status) => {
    const correct = status === 'on';

    const list = alternative.map((item, index) => ({
      ...item,
      is_correct: index === key && correct,
    }));

    setAlternatives(list);
    setCorrectAnswer({ ...list[key], order: key + 1 });
  };

  const removeAlternative = key => {
    const alternatives = [...alternative];
    alternatives.splice(key, 1);
    setAlternatives(alternatives);
  };

  return (
    <>
      <div className="tab-pane fade active show">
        <form onSubmit={e => createQuestion(e)}>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group row">
                <label htmlFor="title" className="d-flex justify-content-start">
                  <span className="text-danger p-1">*</span> Pergunta
                </label>
                <textarea
                  rows="6"
                  id="description"
                  className="form-control col-md-12"
                  type="text"
                  value={enunciated}
                  onChange={e => setEnunciated(e.target.value)}
                />
              </div>
              {alternative && alternative.length > 0 && (
                <div className="row">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Alternativa</th>
                        <th>Correta</th>
                        <th>Excluir</th>
                      </tr>
                    </thead>
                    <tbody>
                      {alternative.map(({ value, is_correct }, key) => (
                        <tr key={key}>
                          <td className="col-10">
                            <textarea
                              rows="2"
                              className="form-control col-md-12"
                              type="text"
                              value={value}
                              onChange={e =>
                                editAlternativeText(key, e.target.value)
                              }
                            />
                          </td>
                          <td className="col-2">
                            <input
                              type="radio"
                              name="alt"
                              style={{ marginLeft: '20px' }}
                              checked={is_correct}
                              onChange={e =>
                                editAlternativeIsCorrect(key, e.target.value)
                              }
                            />
                          </td>
                          <td>
                            <Trash2
                              style={{ marginLeft: '16px' }}
                              size={18}
                              onClick={() => removeAlternative(key)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              <div className="row form-group">
                <button
                  type="button"
                  className="btn btn-primary mr-2"
                  onClick={() => {
                    createEmptyAlternative();
                  }}
                >
                  Criar Alternativa
                </button>
                <hr />
              </div>
            </div>
          </div>

          <div className="pull-right">
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={() => {
                history.push(`${process.env.PUBLIC_URL}/questoes/listar`);
              }}
            >
              Cancelar
            </button>
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default TabSetCriarQuestao;

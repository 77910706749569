import httpClient from '../http-client';

const getFaqs = async () => {
  const faqs = (await httpClient.get('/faqs')).data;

  return faqs;
}

const getFaq = async (faqId) => {
  const faq = (await httpClient.get(`/faqs/${faqId}`)).data;

  return faq;
}

const createFaq = async (newFaq) => {

  if(!newFaq){
    throw new Error("Dados do faq necessários para criação");
  }

  const response = (await httpClient.post('/faqs', newFaq)).data

  return response;
}

const deleteFaq = async (faqId) => {
  const response = (await httpClient.delete(`/faqs/${faqId}`)).data;

  return response;
}

const updateFaq = async (faqId, question, answer) => {
  const response = (await httpClient.put(`/faqs/${faqId}`, { question, answer })).data;

  return response;
}

export {
  getFaqs,
  getFaq,
  createFaq,
  deleteFaq,
  updateFaq
}

import React from 'react';
import './style.css';

function Loading(){
  return (
    <div>
      <div id="loading">
        <div className="loader"></div>
        <h4 style={{ margin: '10px' }}>Carregando...</h4>
      </div>
    </div>
  ) 
}

export default Loading;

import httpClient from '../http-client';

const getUsersForSelect = async () => {
  let users = (await httpClient.get(`/users/`)).data;

  users =
    users &&
    users.length &&
    users.map(user => ({
      value: user.user_id,
      label: user.name,
    }));

  return users;
};

const getActualUser = async () => {
  const user = (await httpClient.get('/users/me')).data;
  return user;
};

const getUsers = async () => {
  const users = (await httpClient.get('/users')).data;
  return users;
};

const toogleAdminPrivileges = async userId => {
  await httpClient.patch(`/users/${userId}/admin`);
};

export { getUsersForSelect, getActualUser, getUsers, toogleAdminPrivileges };

import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getQuestion, updateQuestion } from '../../services/question';
import { Trash2 } from 'react-feather';
import checkEmptyString from '../../helpers/check-empty-string';

const TabSetCriarQuestao = () => {
  const history = useHistory();

  const [question, setQuestion] = useState({});

  const { id } = useParams();

  const fetchQuestion = useCallback(async () => {
    const question = await getQuestion(id);
    const { alternative, answer } = question;


    const alt = alternative.map((item, index) => ({
      ...item,
      is_correct: index + 1 === answer.order,
    }));

    setQuestion({ ...question, alternative: alt });
  }, [id]);

  useEffect(() => {
    fetchQuestion();
  }, [fetchQuestion, id]);

  const editQuestion = async event => {
    event.preventDefault();

    const { enunciated, alternative, answer } = question;

    try {
      if (checkEmptyString(enunciated)) {
        throw new Error('A pergunta não pode ser vazia!');
      }

      if (!alternative || alternative.length < 2) {
        throw new Error('Informe ao menos duas alternativas!');
      } else {
        const alts = alternative.map(({ value }) => value);

        if (alts.some(alt => checkEmptyString(alt))) {
          throw new Error('Todas as alternativas devem ser preenchidas!');
        }

        if (!answer?.order > 0) {
          throw new Error('Selecione uma resposta correta!');
        }
      }

    delete answer["alternative_id"];
    delete answer["created_at"];
    delete answer["updated_at"];

    alternative.forEach(index => {
      delete index["alternative_id"];
      delete index["created_at"];
      delete index["updated_at"];
    }
    )

    const body = {
      enunciated,
      alternative,
      points: 1,
      randomize_alternatives: false,
      answer
    };

      await updateQuestion(id, body);

      toast.success('Questão editada com sucesso!');

      history.push(`${process.env.PUBLIC_URL}/questoes/listar`);
    } catch (e) {
      toast.error('Erro ao editar questão. ' + e.message);
    }
  };

  const createEmptyAlternative = () => {
    const { alternative } = question;
    alternative.push({
      value: '',
      order: alternative.length + 1,
      is_correct: false,
    });

    setQuestion({ ...question, alternative: alternative });
  };

  const editAlternativeText = (key, text) => {
    const { alternative } = question;

    alternative[key].value = text;
    setQuestion({ ...question, alternative });
  };

  const editAlternativeIsCorrect = (key, status) => {
    const alternatives = question.alternative.map((item, index) => {
      return {
        ...item,
        is_correct: status === 'on' && key === index,
      };
    });

    setQuestion({
      ...question,
      alternative: alternatives,
      answer: alternatives[key],
    });
  };

  const removeAlternative = key => {
    const alternatives = [...question.alternative];
    alternatives.splice(key, 1);
    setQuestion({ ...question, alternative: alternatives });
  };

  const editEnunciated = enunciated => {
    setQuestion({ ...question, enunciated });
  };

  return (
    <>
      <div className="tab-pane fade active show">
        <form onSubmit={e => editQuestion(e)}>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group row">
                <label htmlFor="title" className="d-flex justify-content-start">
                  <span className="text-danger p-1">*</span> Pergunta
                </label>
                <textarea
                  rows="6"
                  id="description"
                  className="form-control col-md-12"
                  type="text"
                  value={question.enunciated}
                  onChange={e => editEnunciated(e.target.value)}
                />
              </div>
              {question.alternative?.length > 0 && (
                <div className="row">
                  <table className="table table-bordered col-md-12">
                    <thead>
                      <tr>
                        <th>Alternativa</th>
                        <th>Correta</th>
                        <th>Excluir</th>
                      </tr>
                    </thead>
                    <tbody>
                      {question.alternative.map(
                        ({ value, answer = question.answer.order }, key) => (
                          <tr key={key}>
                            <td className="col-10">
                              <textarea
                                rows="2"
                                className="form-control col-md-12"
                                type="text"
                                value={value}
                                onChange={e =>
                                  editAlternativeText(key, e.target.value)
                                }
                              />
                            </td>
                            <td className="col-2">
                              <input
                                style={{ marginLeft: '20px' }}
                                type="radio"
                                name="alt"
                                checked={key + 1 === answer}
                                onChange={e =>
                                  editAlternativeIsCorrect(key, e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <Trash2
                                style={{ marginLeft: '16px' }}
                                size={18}
                                onClick={() => removeAlternative(key)}
                              />
                            </td>
                          </tr>
                        ),
                      )}
                    </tbody>
                  </table>
                </div>
              )}
              <div className="row form-group">
                <div className="col-md-2" />
                <div className="col-md-10">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      createEmptyAlternative();
                    }}
                  >
                    Criar Alternativa
                  </button>
                  <hr />
                </div>
              </div>
            </div>
          </div>

          <div className="pull-right">
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={() => {
                history.push(`${process.env.PUBLIC_URL}/questoes/listar`);
              }}
            >
              Cancelar
            </button>
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default TabSetCriarQuestao;

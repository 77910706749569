import httpClient from '../http-client';

const createExam = async data => {
  const newExamResponse = (await httpClient.post(`/exams`, data)).data;

  return newExamResponse;
};

const listExams = async filters => {
  let exams = (await httpClient.get(`/exams`, { params: filters })).data;

  return exams;
};

const deleteExam = async id => {
  if (!id) {
    throw new Error('O id da prova é obrigatório para a exclusão!');
  }

  const examDeleteResponse = (await httpClient.delete(`/exams/${id}`)).data;

  return examDeleteResponse;
};

const toggleExamStatus = async (id, status) => {
  if (!id) {
    throw new Error(
      'O id da prova é obrigatório para a ativa-la ou inativa-la!',
    );
  }

  const toggleStatusResponse = (
    await httpClient.put(`/exams/${id}/active`, {
      is_active: status,
    })
  ).data;

  return toggleStatusResponse;
};

const updateExam = async (id, data) => {
  const updateExamResponse = (await httpClient.put(`/exams/${id}`, data)).data;

  return updateExamResponse;
};

const getExam = async id => {
  if (!id) {
    throw new Error('O id da prova é obrigatório para obter seus dados!');
  }

  let exam = (await httpClient.get(`/exams/${id}`)).data;

  return exam;
};

export {
  createExam,
  listExams,
  deleteExam,
  toggleExamStatus,
  getExam,
  updateExam,
};

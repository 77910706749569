import httpClient from '../http-client/index';
import { convertDocumentFromDB } from '../helpers/converters/documentConverter';

const getFiles = async () => {
  let files = (await httpClient.get(`/files`)).data;

  return files;
};

const getDocuments = async () => {
  let documents = (
    await httpClient.get(`/contents`, { params: { is_library: true, type: 'file' } })
  ).data;

  documents = documents.filter(
    item => item.type === 'FILE' || item.type === 'file',
  );
  if (documents && documents.length) {
    documents = documents.map(convertDocumentFromDB);
  }

  return documents;
};

const getDocument = async document_id => {
  let document = (await httpClient.get(`/contents/${document_id}`)).data;

  return document;
};

const updateFile = async (contentId, newContent) => {
  if (!contentId) {
    throw new Error('O id do conteúdo é obrigatório para a edição!');
  }

  if (!newContent || !Object.keys(newContent).length) {
    throw new Error('Os dados para atualização do conteúdo são obrigatórios!');
  }

  const contentUpdateResponse = (
    await httpClient.put(`/contents/${contentId}`, newContent)
  ).data;

  return contentUpdateResponse;
};

const createFile = async file => {
  if (!file) {
    throw new Error('Os dados para criação do arquivo são obrigatórios!');
  }

  const fileResponse = (await httpClient.post(`/files`, file)).data;

  return fileResponse;
};

export { getDocuments, getDocument, getFiles, updateFile, createFile };

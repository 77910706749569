import axios from 'axios';


const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }
});

httpClient.interceptors.request.use(function (config) {
  const token = window.localStorage.getItem('vallourec-api-token');
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

httpClient.interceptors.request.use(function (config) {
  const loading = document.getElementById('loading');
  if (loading && !config.url?.includes("/files/url-signed")) {
    loading.style.display = 'flex';
}

  const token = window.localStorage.getItem('vallourec-api-token');
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

httpClient.interceptors.response.use(
    function (response) {
      const loading = document.getElementById("loading");
      if (loading && !response.config.url?.includes("/files/url-signed")) {
        if (response.config.method !== "GET") {
          loading.style.display = "none";
        } else {
          setTimeout(() => {
            loading.style.display = "none";
          }, 300);
        }
      }

      return response;
    },
  function (error) {
    if (!!error.response && error.response.status === 401) {
      window.location.href = `${process.env.REACT_APP_FARMARCAS_LOGIN_PAGE}/auth?req_token=${window.location.origin}`;
    } else {
      setTimeout(() => {
        const loading = document.getElementById('loading');
        if (loading) {
          loading.style.display = 'none';
        }
      }, 1000);
      
      return Promise.reject(error);
    }
  },
);

export default httpClient;

import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useHistory } from 'react-router-dom';
import { createDocument as createDocumentService } from '../../services/contents';
import { createFile as createFileService } from '../../services/documents';
import { toast } from 'react-toastify';
import checkEmptyString from '../../helpers/check-empty-string';
import { hideModal, showModal } from '../modal';
import { uploadFile } from '../../services/files';
import SelectImage from '../select-image';

const Tabset = () => {
  const history = useHistory();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('');
  const [contentReference, setContentReference] = useState('');
  const [file, setFile] = useState([]);

  const [imageToUpload, setImageToUpload] = useState('');
  const [image, setImage] = useState('');

  const selectImage = () => {
    showModal(
      'Cortar Imagem',
      <SelectImage aspect={16 / 9} onCutImage={onCutImage} />,
    );
  };

  const onCutImage = file => {
    if (file) {
      setImageToUpload(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setImage(`${reader.result}`);

      hideModal();
    }
  };

  const gotoLives = () => {
    history.push(`${process.env.PUBLIC_URL}/lives/listar-lives`);
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const createDocument = async event => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error('Informe um titulo para o documento!');
      }

      if (checkEmptyString(description)) {
        throw new Error('Informe uma descrição para o documento!');
      }

      if (checkEmptyString(description)) {
        throw new Error('Informe um arquivo para documento');
      }

      const newContent = {
        title: title,
        description: description,
        type: type,
        reference: contentReference,
      };

      if (image && image.length && image.includes('base64')) {
        try {
          const thumbnail = dataURLtoFile(image, `thumbnail-${title}.png`);

          const formData = new FormData();
          formData.append('file', thumbnail);

          const { reference } = await uploadFile(formData);
          newContent.thumbnail = reference;
        } catch (e) {
          throw new Error('Erro ao incluir a imagem de capa. ' + e.message);
        }
      }

      await createDocumentService(newContent);

      toast.success('Documento criado com sucesso!');

      history.push('/biblioteca/documentos/listar-documentos');
    } catch (e) {
      const errorMesssage = e.response || e.message;
      toast.error(errorMesssage);
    }
  };

  const addFile = async event => {
    event.preventDefault();

    let fileBlob = event.target.files[0];

    setType('file');
    setFile(fileBlob);

    const formData = new FormData();
    formData.append('file', fileBlob);
    formData.append('name', fileBlob.name);
    if (fileBlob === '') {
      toast.error('Informe um arquivo válido');
    } else {
      let Extension = fileBlob.name
        .substring(fileBlob.name.lastIndexOf('.') + 1)
        .toLowerCase();
      if (
        Extension === 'gif' ||
        Extension === 'png' ||
        Extension === 'bmp' ||
        Extension === 'jpeg' ||
        Extension === 'jpg' ||
        Extension === 'svg'
      ) {
        formData.append('type', 'image');
        const fileResponse = await createFileService(formData);
        setContentReference(fileResponse.reference);
      } else if (
        Extension === 'doc' ||
        Extension === 'docx' ||
        Extension === 'pdf' ||
        Extension === 'rtf' ||
        Extension === 'tex' ||
        Extension === 'txt' ||
        Extension === 'wpd'
      ) {
        formData.append('type', 'file');
        const fileResponse = await createFileService(formData);
        setContentReference(fileResponse.reference);
      } else {
        toast.error('Informe um arquivo de imagem ou documento válido');
      }
    }
  };

  return (
    <>
      <div className="tab-pane fade active show">
        <form
          onSubmit={e => createDocument(e)}
          className="needs-validation"
          noValidate=""
        >
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group row">
                <label htmlFor="titulo" className="col-sm-2">
                  <span>*</span> Título
                </label>

                <input
                  className="form-control col-md-10"
                  id="titulo"
                  type="text"
                  required="required"
                  maxLength={50}
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                />
              </div>

              <div className="form-group row">
                <label htmlFor="descricao" className="col-sm-2">
                  <span>*</span> Descrição
                </label>

                <textarea
                  className="form-control col-md-10"
                  id="descricao"
                  required="required"
                  rows={5}
                  maxLength={250}
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                ></textarea>
              </div>

              <div className="form-group row" style={{ padding: '5px' }}>
                <label htmlFor="descricao" className="col-sm-2">
                  <span>*</span> Documento
                </label>
                <div className="col-md-10" style={{ padding: 0 }}>
                  <input
                    style={{ width: 420, padding: 5 }}
                    type="file"
                    className="btn btn-warning"
                    id="support-materials"
                    onChange={e => addFile(e)}
                    required
                  />
                </div>
              </div>

              <div className="form-group row" style={{ padding: '5px' }}>
                <label htmlFor="descricao" className="col-sm-2">
                  <span>*</span> Capa
                </label>
                <div className="text-center col-md-10 border rounded p-1">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={selectImage}
                  >
                    Selecionar Imagem
                  </button>

                  <div>
                    {image ? (
                      <img
                        className="mt-1"
                        style={{ width: '400px' }}
                        src={image}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pull-right">
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={() => {
                history.push(
                  `${process.env.PUBLIC_URL}/biblioteca/documentos/listar-documentos`,
                );
              }}
            >
              Cancelar
            </button>

            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Tabset;

import React from "react";
import {
	CircleSwitch,
	DefaultSwitchContainer,
	LabelSwitch,
	Switch,
} from "./style";

const DefaultSwitchScorm = ({
	switchState,
	onChangeSwitchState,
}) => {
	return (
		<DefaultSwitchContainer onClick={onChangeSwitchState}>
			<Switch>
				<LabelSwitch switchState={switchState}>
					{switchState === "video" ? "Vídeo" : "Scorm"}
				</LabelSwitch>
				<CircleSwitch switchState={switchState} />
			</Switch>
		</DefaultSwitchContainer>
	);
};

export default DefaultSwitchScorm;

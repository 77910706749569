import { convertCourseFromDB } from '../helpers/converters/courseConverter';
import httpClient from '../http-client';

const getCourses = async filters => {
  let courses = (await httpClient.get(`/courses`, { params: filters })).data;

  if (courses && courses.length) {
    courses = courses.map(convertCourseFromDB);
  }

  return courses;
};

const getCoursesForSelect = async () => {
  let courses = (await httpClient.get(`/courses`)).data;

  if (courses && courses.length) {
    courses = courses.map(course => ({
      label: course.title,
      value: course.course_id,
    }));
  }

  return courses;
};

const getCourse = async courseId => {
  if (!courseId) {
    throw new Error('O id do curso é obrigatório para obter seus dados!');
  }

  let course = (await httpClient.get(`/courses/${courseId}`)).data;

  if (course.course) {
    course = course.course;
  }

  return course;
};

const deleteCourse = async courseId => {
  if (!courseId) {
    throw new Error('O id do curso é obrigatório para a exclusão!');
  }

  const courseDeleteResponse = (await httpClient.delete(`/courses/${courseId}`))
    .data;

  return courseDeleteResponse;
};

const updateCourse = async (courseId, newCourse) => {
  if (!courseId) {
    throw new Error('O id do curso é obrigatório para a edição!');
  }

  if (!newCourse || !Object.keys(newCourse).length) {
    throw new Error('Os dados para atualização do curso são obrigatórios!');
  }

  const courseUpdateResponse = (
    await httpClient.put(`/courses/${courseId}`, newCourse)
  ).data;

  return courseUpdateResponse;
};

const createCourse = async newCourse => {
  if (!newCourse || !Object.keys(newCourse).length) {
    throw new Error('Os dados para criação do curso são obrigatórios!');
  }

  for (let key of Object.keys(newCourse)) {
    if (!newCourse[key]) {
      delete newCourse[key];
    }
  }

  const createCourseResponse = (await httpClient.post(`/courses`, newCourse))
    .data;

  return createCourseResponse;
};

const activateOrInactivateCourse = async (courseId, active) => {
  if (!courseId) {
    throw new Error('Id do curso é obrigatório!');
  }

  const activateOrInactivateCourseResponse = (
    await httpClient.put(`/courses/${courseId}/active`, {
      is_active: active,
    })
  ).data;

  return activateOrInactivateCourseResponse;
};

export {
  getCourses,
  getCourse,
  deleteCourse,
  updateCourse,
  createCourse,
  getCoursesForSelect,
  activateOrInactivateCourse,
};

import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useHistory } from 'react-router-dom';
import { createFaq as createFaqService } from '../../services/faq';
import { toast } from 'react-toastify';
import checkEmptyString from '../../helpers/check-empty-string';

const Tabset = () => {
  const history = useHistory();

  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');

  const createFaq = async event => {
    event.preventDefault();

    try {
      if (checkEmptyString(question)) {
        throw new Error('Informe uma questão!');
      }

      if (checkEmptyString(answer)) {
        throw new Error('Informe uma resposta!');
      }

      const newContent = {
        question: question,
        answer: answer
      };

      await createFaqService(newContent);

      toast.success('Faq criada com sucesso!');

      history.push('/faq/listar-faqs');
    } catch (e) {
      const errorMesssage = e.response || e.message;
      toast.error(errorMesssage);
    }
  };

  return (
    <>
      <div className="tab-pane fade active show">
        <form
          onSubmit={e => createFaq(e)}
          className="needs-validation"
          noValidate=""
        >
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group row">
                <label htmlFor="titulo" className="col-sm-2">
                  <span>*</span> Pergunta
                </label>

                <input
                  className="form-control col-md-10"
                  id="titulo"
                  type="text"
                  required="required"
                  value={question}
                  onChange={e => setQuestion(e.target.value)}
                />
              </div>

              <div className="form-group row">
                <label htmlFor="descricao" className="col-sm-2">
                  <span>*</span> Resposta
                </label>

                <textarea
                  className="form-control col-md-10"
                  id="descricao"
                  required="required"
                  rows={5}
                  value={answer}
                  onChange={e => setAnswer(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>

          <div className="pull-right">
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={() => {
                history.push(`${process.env.PUBLIC_URL}/faq/listar-faqs`);
              }}
            >
              Cancelar
            </button>

            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Tabset;

import httpClient from '../http-client';

const createQuestion = async data => {
  const newQuestionResponse = (await httpClient.post(`/questions`, data)).data;

  return newQuestionResponse;
};

const listQuestions = async () => {
  let questions = (await httpClient.get(`/questions`)).data;

  return questions;
};

const deleteQuestion = async id => {
  if (!id) {
    throw new Error('O id da categoria é obrigatório para a exclusão!');
  }

  const qestionDeleteResponse = (await httpClient.delete(`/questions/${id}`))
    .data;

  return qestionDeleteResponse;
};

const toggleQuestionStatus = async (id, status) => {
  if (!id) {
    throw new Error(
      'O id da categoria é obrigatório para a ativa-la ou inativa-la!',
    );
  }

  const toggleStatusResponse = (
    await httpClient.put(`/questions/${id}/active`, {
      is_active: status,
    })
  ).data;

  return toggleStatusResponse;
};

const updateQuestion = async (id, data) => {
  const updateQuestionResponse = (
    await httpClient.put(`/questions/${id}`, data)
  ).data;

  return updateQuestionResponse;
};

const getQuestion = async id => {
  if (!id) {
    throw new Error('O id da questão é obrigatório para obter seus dados!');
  }

  let question = (await httpClient.get(`/questions/${id}`)).data;

  return question;
};

export {
  createQuestion,
  listQuestions,
  deleteQuestion,
  toggleQuestionStatus,
  getQuestion,
  updateQuestion,
};

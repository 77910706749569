import React, { useEffect, useMemo, useState } from 'react';
import { X } from 'react-feather';
import { useHistory, useParams } from 'react-router';
import {
  getSegmentationTypes as getTypes,
  getSegmentationType as getSegmentationTypeService,
  deleteSegmentationItem as removeSegItemService,
  createSegmentationItem as createSegmentationItemService,
  updateSegmentationItem as updateSegmentationItemService,
} from '../../services/segmentations';
import Breadcrumb from '../common/breadcrumb';
import Datatable from '../common/datatable';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import getErrorMessage from '../../helpers/get-error-message';
import checkEmptyString from '../../helpers/check-empty-string';

const EditarTipoSegmentacao = () => {
  const history = useHistory();
  const { segmentationTypeId } = useParams();
  const [segItems, setSegItems] = useState([]);
  const [segItemsToBeUpdated, setSegItemsToBeUpdated] = useState([]);
  const [segItemsToBeAdded, setSegItemsToBeAdded] = useState([]);
  const [segType, setSegType] = useState({});
  const [segGenericType, setSegGenericType] = useState({});

  const updateDataTable = () => {
    setSegItems([]);
    const localSegItems = segItems;
    setTimeout(() => {
      setSegItems(localSegItems);
    }, 50);
  };

  const getSegItems = async () => {
    setSegItems([]);
    setSegItemsToBeUpdated([]);
    setSegItemsToBeAdded([]);

    const allTypes = await getTypes();
    const genericType = allTypes.find(type => type.type === 'Generic');
    const type = allTypes.find(type => type.segmentation_type_id === segmentationTypeId);

    const localSegItems = await getSegmentationTypeService(segmentationTypeId);
    
    setSegType(type);
    setSegGenericType(genericType);
    setSegItems(localSegItems);
  };

  const handleChange = (event, itemId, forAdd) => {
    const inputValue = (event.target || { value: '' }).value;
    if (forAdd) {
      if (!segItemsToBeAdded.some(item => item.id === itemId)) {
        setSegItemsToBeAdded([
          ...segItemsToBeAdded,
          { id: itemId, value: inputValue },
        ]);
      } else {
        const foundSegItemToBeAdded = segItemsToBeAdded.find(
          item => item.id === itemId,
        );
        if (foundSegItemToBeAdded) {
          foundSegItemToBeAdded.value = inputValue;
          setSegItemsToBeAdded([...segItemsToBeAdded]);
        }
      }
    } else {
      if (!segItemsToBeUpdated.some(item => item.id === itemId)) {
        setSegItemsToBeUpdated([
          ...segItemsToBeUpdated,
          { id: itemId, value: inputValue },
        ]);
      } else {
        const foundSegItemToBeUpdated = segItemsToBeUpdated.find(
          item => item.id === itemId,
        );
        if (foundSegItemToBeUpdated) {
          foundSegItemToBeUpdated.value = inputValue;
          setSegItemsToBeUpdated([...segItemsToBeUpdated]);
        }
      }
    }
  };

  const removeSegItem = async segItemId => {
    Swal.fire({
      icon: 'question',
      text: 'Tem certeza que deseja remover este item de segmentação?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
         await removeSegItemService(segItemId);
          const localItemToBeUpdated = segItems.find(iten => iten.segmentation_item_id === segItemId);
          const foundGenericItem = segGenericType.items.find(items => items.item === localItemToBeUpdated.item);
          await removeSegItemService(foundGenericItem.segmentation_item_id);
          await getSegItems();
        } catch (error) {
          const errorMessage = getErrorMessage(error);
          Swal.fire({
            icon: 'error',
            text: `Erro ao remover item de segmentação. ${errorMessage}`,
          });
        }
      }
    });
  };

  const removeSegItemToBeAdded = segItemId => {
    const foundSegItemToBeAdded = segItemsToBeAdded.find(
      item => item.id === segItemId,
    );
    if (foundSegItemToBeAdded) {
      const foundSegItemIndex = segItemsToBeAdded.indexOf(
        foundSegItemToBeAdded,
      );
      if (
        foundSegItemIndex > -1 &&
        foundSegItemIndex < segItemsToBeAdded.length
      ) {
        segItemsToBeAdded.splice(foundSegItemIndex, 1);
      }
    }
    updateDataTable();
  };

  const addEmptyItem = () => {
    setSegItemsToBeAdded([...segItemsToBeAdded, { id: uuidv4(), value: '' }]);
    updateDataTable();
  };

  const validate = () => {
    if (
      (segItemsToBeAdded || []).some(
        item =>
          checkEmptyString(item.value) ||
          (segItemsToBeUpdated || []).some(item =>
            checkEmptyString(item.value),
          ),
      )
    ) {
      throw new Error(
        'Necessário informar uma descrição valida para os itens de segmentação!',
      );
    }
  };

  const saveChanges = async () => {
    try {
      validate();

      if (segItemsToBeAdded && segItemsToBeAdded.length) {
        await createSegmentationItemService(segmentationTypeId, {
          segmentation_items: segItemsToBeAdded.map(item => ({
            item: item.value.trim(),
          })),
        });

        await createSegmentationItemService(segGenericType.segmentation_type_id, {
          segmentation_items: segItemsToBeAdded.map(item => ({
            item: item.value.trim(),
          })),
        });
      }

if (segItemsToBeUpdated && segItemsToBeUpdated.length) {
        for (let item of segItemsToBeUpdated) {
          await updateSegmentationItemService(item.id, { item: item.value.trim()});

          const localItemToBeUpdated = segItems.find(iten => iten.segmentation_item_id === item.id);
          const foundGenericItem = segGenericType.items.find(items => items.item === localItemToBeUpdated.item);

          await updateSegmentationItemService(foundGenericItem.segmentation_item_id, { item: item.value.trim() });
        }
      }

      await getSegItems();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        icon: 'error',
        text:
          'Erro ao salvar alterações no tipo de segmentação. ' + errorMessage,
      });
    }
  };

  const goToSegTypes = () => {
    history.push(`${process.env.PUBLIC_URL}/segmentacao/listar`);
  };

  const segItemsToBeShown = useMemo(() => {
    let localSegItems = [];

    if (segItems && segItems.length) {
      localSegItems.push(
        ...segItems.map(item => ({
          nome: (
            <textarea
              className="form-control"
              style={{ minWidth: 400, width: '100%' }}
              defaultValue={item.item}
              onChange={event =>
                handleChange(event, item.segmentation_item_id, false)
              }
            ></textarea>
          ),
          ações: (
            <>
              <button
                onClick={() => removeSegItem(item.segmentation_item_id)}
                className="btn btn-xs btn-danger"
              >
                <X />
              </button>
            </>
          ),
        })),
      );
    }

    localSegItems.push(
      ...segItemsToBeAdded.map(item => ({
        nome: (
          <textarea
            className="form-control"
            style={{ minWidth: 400, width: '100%' }}
            defaultValue={item.value}
            onChange={event => handleChange(event, item.id, true)}
          ></textarea>
        ),
        ações: (
          <>
            <button
              onClick={() => removeSegItemToBeAdded(item.id)}
              className="btn btn-xs btn-danger"
            >
              <X />
            </button>
          </>
        ),
      })),
    );

    return localSegItems;
  }, [segItems, segItemsToBeAdded, segItemsToBeUpdated]);

  useEffect(() => {
    getSegItems();
  }, []);

  return (
    <>
      <Breadcrumb title="Listar Itens de Segmentação" parent="Segmentação" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Lista de Itens de Segmentação</h5>
              </div>
              <div className="card-body">
                <div className="clearfix"></div>
                <div
                  id="batchDelete"
                  className="category-table order-table coupon-list-delete"
                >
                  {segItemsToBeShown && segItemsToBeShown.length ? (
                    <Datatable
                      myData={segItemsToBeShown}
                      pageSize={segItemsToBeShown.length}
                      class="-striped -highlight"
                    />
                  ) : (
                    <div
                      className="
                    d-flex 
                    justify-content-center 
                    align-items-center
                  "
                    >
                      <span>Nenhum item de segmentação</span>
                    </div>
                  )}
                </div>

                <div className="d-flex justify-content-end my-3">
                  <button className="btn btn-info" onClick={addEmptyItem}>
                    Adicionar Item
                  </button>
                </div>

                <div className="pull-right">
                  <button
                    className="btn btn-danger mr-1"
                    onClick={goToSegTypes}
                  >
                    Cancelar
                  </button>
                  <button className="btn btn-primary" onClick={saveChanges}>
                    Salvar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditarTipoSegmentacao;
import React, { useState, useMemo, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { useHistory, useParams } from 'react-router';
import getErrorMessage from '../../helpers/get-error-message';
import {
  getTag as getTagService,
  createTag as createTagService,
  updateTag as updateTagService,
} from '../../services/forum';
import Swal from 'sweetalert2';
import checkEmptyString from '../../helpers/check-empty-string';

const CreateForumTag = () => {
  const history = useHistory();
  const [description, setDescription] = useState('');
  const { tagId } = useParams();

  const handleSubmit = event => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    if (isEditting) {
      updateTag();
    } else {
      createTag();
    }
  };

  const validate = () => {
    if (checkEmptyString(description)) {
      throw new Error('Informe uma descrição válida para a tag!');
    }
  };

  const createTag = async () => {
    try {
      validate();
      await createTagService({ description });
      gotoTags();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        icon: 'error',
        text: 'Erro ao criar tag de fórum. ' + errorMessage,
      });
    }
  };

  const updateTag = async () => {
    try {
      validate();
      await updateTagService(tagId, { description });
      gotoTags();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        icon: 'error',
        text: 'Erro ao criar tag de fórum. ' + errorMessage,
      });
    }
  };

  const gotoTags = () => {
    history.push(`${process.env.PUBLIC_URL}/forum/tags`);
  };

  const isEditting = useMemo(() => {
    return !!tagId;
  }, [tagId]);

  useEffect(() => {
    if (isEditting) {
      (async () => {
        const localTag = await getTagService(tagId);
        setDescription((localTag || {}).description);
      })();
    }
  }, [isEditting]);

  return (
    <>
      <Breadcrumb
        title={`${isEditting ? 'Editar' : 'Criar'} Tag`}
        parent="Tags"
      />
      <div className="container-fluid">
        <div className="row">
          <form onSubmit={handleSubmit} className="col-sm-12">
            <div className="form-group row">
              <div className="col-md-2">
                <label htmlFor="description" className="control-label">
                  Descrição
                </label>
              </div>
              <div className="col-md-10">
                <input
                  className="form-control"
                  type="text"
                  id="description"
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                />
              </div>
            </div>
            <div className="pull-right">
              <button
                onClick={gotoTags}
                type="button"
                className="btn btn-danger mr-1"
              >
                Cancelar
              </button>
              <button type="submit" className="btn btn-primary">
                Salvar
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateForumTag;

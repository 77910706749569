import React, { useEffect, useState } from 'react';
import { getActualUser } from '../../services/users';

const Profile = () => {
  const [userName, setUserName] = useState('');

  useEffect(() => {
    (async () => {
      const localUser = await getActualUser();
      setUserName(localUser.name);
    })();
  });

  return (
    <div
      className="
        d-flex
        justify-content-center
        align-items-center
      "
      style={{
        height: '60vh',
      }}
    >
      <h3>Bem vindo {userName}!</h3>
    </div>
  );
};

export default Profile;

import React, {
  Fragment,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import Breadcrumb from '../common/breadcrumb';
import Swal from 'sweetalert2';
import { Edit, Trash, ArrowUp, ArrowDown } from 'react-feather';
import { toast } from 'react-toastify';
import {
  getBanners as getBannersService,
  deleteBanner as deleteBannerService,
  updateBanner,
} from '../../services/banner';
import { showModal } from '../modal';
import CreateAndEditBanner from './criar-editar-banner';

const ListBanners = () => {
  const history = useHistory();
  const [banners, setBanners] = useState([]);

  const createBanner = () => {
    showModal(
      'Criar Banner',
      <CreateAndEditBanner
        lastPosition={lastPosition}
        onCreateOrUpdate={getBanners}
      />,
    );
  };

  const editBanner = bannerId => {
    showModal(
      'Editar Banner',
      <CreateAndEditBanner
        bannerId={bannerId}
        lastPosition={lastPosition}
        onCreateOrUpdate={getBanners}
      />,
    );
  };

  const getBanners = useCallback(async () => {
    setBanners([]);
    let localBanners = await getBannersService();
    setBanners(localBanners);
  }, [history]);

  const removeBanner = async bannerId => {
    Swal.fire({
      title: '<strong>Confirmação</strong>',
      html: 'Tem certeza que deseja remover este banner?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
      icon: 'question',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await deleteBannerService(bannerId);

          toast.success('Banner removido com sucesso!');

          await getBanners();
        } catch (e) {
          const response = e.response;

          if (response && response.status && response.status === 423) {
            toast.error('O banner não pode ser removido');
          } else {
            toast.error('Erro ao remover banner.');
          }
        }
      }
    });
  };

  const moveBanner = async (bannerToBeMoved, up) => {
    try {
      if (up) {
        bannerToBeMoved.position -= 1;
      } else {
        bannerToBeMoved.position += 1;
      }

      await updateBanner(
        bannerToBeMoved.home_info_id,
        'banner',
        bannerToBeMoved.position,
        bannerToBeMoved.description,
      );

      await getBanners();
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Erro ao mudar a posição do banner. ' + error.message,
        icon: 'error',
      });
    }
  };

  const lastPosition = useMemo(() => {
    if (banners && banners.length) {
      return Math.max(...banners.map(banner => banner.position));
    }

    return 0;
  }, [banners]);

  useEffect(() => {
    getBanners();
  }, [getBanners]);

  return (
    <Fragment>
      <Breadcrumb title="Listar Banners" parent="Home" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Banners</h5>
              </div>
              <div className="card-body">
                <div className="btn-popup pull-right">
                  <button
                    onClick={createBanner}
                    to={`/home/criar-banner`}
                    className="btn btn-primary"
                  >
                    Criar Banner
                  </button>
                </div>
                <div className="clearfix"></div>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    gridRowGap: '15px',
                  }}
                >
                  {banners && banners.length ? (
                    banners.map(banner => (
                      <div
                        key={banner.home_info_id}
                        className="card"
                        style={{ width: '100%' }}
                      >
                        <div
                          className="card-img-top"
                          style={{ overflow: 'hidden', maxHeight: '300px' }}
                        >
                          <img
                            src={banner.image}
                            style={{ width: '100%' }}
                            alt="banner"
                          />
                        </div>
                        <div className="card-body">
                          <h5 className="card-title">{banner.description}</h5>
                          <p className="card-text">
                            Posição: {banner.position}
                          </p>
                          <p className="card-text">
                            {new Intl.DateTimeFormat('pt-BR').format(
                              new Date(banner.created_at),
                            )}
                          </p>
                          <div className="mb-2 d-flex justify-content-center align-items-center">
                            <button
                              onClick={() => editBanner(banner.home_info_id)}
                              title="Editar"
                              className="btn btn-xs btn-primary mr-2"
                            >
                              <Edit />
                            </button>
                            <button
                              onClick={() => removeBanner(banner.home_info_id)}
                              title="Excluir"
                              className="btn btn-xs btn-danger"
                            >
                              <Trash />
                            </button>
                          </div>
                          <div
                            title="Subir banner"
                            className="d-flex justify-content-center align-items-center"
                          >
                            {banners[0].home_info_id !==
                              banner.home_info_id && (
                              <button
                                onClick={() => moveBanner(banner, true)}
                                className="btn btn-xs btn-info mr-2"
                              >
                                <ArrowUp />
                              </button>
                            )}
                            {banners[banners.length - 1].home_info_id !==
                              banner.home_info_id && (
                              <button
                                onClick={() => moveBanner(banner, false)}
                                title="Descer banner"
                                className="btn btn-xs btn-info"
                              >
                                <ArrowDown />
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div
                      className="
                          d-flex 
                          justify-content-center 
                          align-items-center
                        "
                    >
                      <span>Nenhum banner encontrado</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ListBanners;

import React, { useCallback, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumb from '../common/breadcrumb';
import {
  getTrails as getTrailsService,
  deleteTrail,
  activateOrInactivateTrail,
} from '../../services/trails';
import Datatable from '../common/datatable';
import { Edit2, Trash2, Check, X } from 'react-feather';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const ListarTrilhas = () => {
  const [trails, setTrails] = useState([]);
  const history = useHistory();
  let refreshTrails = useCallback(() => {}, []);

  const getTrails = useCallback(async () => {
    let localTrails = await getTrailsService();

    if (localTrails && localTrails.length) {
      localTrails = localTrails.map(trail => {
        const trailId = trail.id;
        const isActive = trail.is_active;

        trail['ações'] = (
          <>
            <button
              type="button"
              className="btn btn-xs btn-primary mr-1"
              title="Editar Curso"
              onClick={() => {
                history.push(`/disciplinas/editar-disciplina/${trailId}`);
              }}
            >
              <Edit2 size={18} />
            </button>

            <button
              type="button"
              className="btn btn-xs btn-danger mr-1"
              title="Excluir Curso"
              onClick={async () => {
                Swal.fire({
                  title: '<strong>Confirmação</strong>',
                  html: 'Tem certeza que deseja remover esta disciplina?',
                  showCancelButton: true,
                  cancelButtonText: 'Cancelar',
                  focusConfirm: false,
                  icon: 'question'
                })
                  .then(async result => {
                    if (result.isConfirmed) {
                      try {
                        await deleteTrail(trailId);

                        toast.success('Disciplina removida com sucesso!');

                        await refreshTrails();
                      } catch (e) {
                        const response = e.response;
                        if (
                          response &&
                          response.status &&
                          response.status === 423
                        ) {
                          toast.error('A disciplina não pode ser removida');
                        } else {
                          toast.error('Erro ao remover disciplna.');
                        }
                      }
                    }
                  })
                  .catch(() => {});
              }}
            >
              <Trash2 size={18} />
            </button>

            <button
              type="button"
              className="btn btn-xs btn-warning"
              title={`${trail.is_active ? 'Inativar' : 'Ativar'} curso`}
              onClick={async () => {
                Swal.fire({
                  title: '<strong>Confirmação</strong>',
                  html: `Tem certeza que deseja ${
                    isActive ? 'inativar' : 'ativar'
                  } esta disciplina?`,
                  showCancelButton: true,
                  cancelButtonText: 'Cancelar',
                  focusConfirm: false,
                  icon: 'question'
                })
                  .then(async result => {
                    if (result.isConfirmed) {
                      try {
                        await activateOrInactivateTrail(
                          trailId,
                          !isActive,
                        ).then(async () => {
                          toast.success(
                            `Disciplina ${
                              isActive ? 'inativada' : 'ativada'
                            } com sucesso!`,
                          );

                          await refreshTrails();
                        });
                      } catch (e) {
                        const response = e.response;

                        if (
                          response &&
                          response.status &&
                          response.status === 423
                        ) {
                          toast.error(
                            `A disciplina não pode ser ${
                              isActive ? 'inativada' : 'ativada'
                            }`,
                          );
                        } else {
                          toast.error(
                            `Erro ao ${
                              isActive ? 'inativar' : 'ativar'
                            } disciplina. ${e.message}`,
                          );
                        }
                      }
                    }
                  })
                  .catch(() => {});
              }}
            >
              {trail.is_active ? <X size={18} /> : <Check size={18} />}
            </button>
          </>
        );

        delete trail.is_active;
        delete trail.id;

        return trail;
      });

      setTrails(localTrails);
    }
  }, [history, refreshTrails]);

  refreshTrails = useCallback(async () => {
    setTrails([]);
    await getTrails();
  }, [getTrails]);

  useEffect(() => {
    getTrails();
  }, [getTrails]);
  return (
    <>
      <Breadcrumb title="Listar Disciplinas" parent="Disciplinas" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Lista de Disciplinas</h5>
              </div>
              <div className="card-body">
                <div className="btn-popup pull-right">
                  <Link
                    to={`${process.env.PUBLIC_URL}/disciplinas/criar-disciplina`}
                    className="btn btn-primary"
                  >
                    Criar Disciplina
                  </Link>
                </div>
                <div className="clearfix"></div>
                <div
                  id="batchDelete"
                  className="category-table order-table coupon-list-delete"
                >
                  {trails && trails.length ? (
                    <Datatable
                      multiSelectOption={false}
                      myData={trails}
                      pageSize={10}
                      pagination={true}
                      class="-striped -highlight"
                    />
                  ) : (
                    <div
                      className="
                        d-flex 
                        justify-content-center 
                        align-items-center
                      "
                    >
                      <span>Nenhuma disciplina</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListarTrilhas;

import httpClient from '../http-client';

const getReviews = async trailId => {
  const reviews = (
    await httpClient.get(`/trail-user-reviews/all/with-contents`)
  ).data;

  return reviews;
};

const deleteReviewService = async trailId => {
  await httpClient.delete(`/trail-user-reviews/${trailId}`);
};

const visibleReviewService = async (trailId, userId) => {
  await httpClient.patch(
    `/trail-user-reviews/${trailId}/${userId}/show-to-all-users`,
  );
};

export { getReviews, deleteReviewService, visibleReviewService };

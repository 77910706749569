import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumb from '../common/breadcrumb';
import Datatable from '../common/datatable';
import {
  deleteContent as deleteContentService,
  activateOrInactivateContent as activateOrInactivateContentService
} from '../../services/contents';
import { getDocuments as getDocumentsService } from '../../services/documents'
import { Edit2, Trash2, X, Check } from 'react-feather';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const ListContents = () => {
  const history = useHistory();
  const [contents, setContents] = useState([]);
  
  let refreshContent = () => {};

  const activateOrInactivateContent = async (contentId, active) => {
    try {
      await activateOrInactivateContentService(contentId, active);
    }catch (e) {
      const errorMessage = e.message;
      toast.error('Erro ao inativar conteúdo. ' + errorMessage)
    }
  }

  const getContents = useCallback(async () => {
    let localContents = await getDocumentsService();
    localContents = localContents.map(content => {
      const contentId = content.id;
      const isActive = content.is_active;

      content['ações'] = (
        <>
          <button
            type="button"
            className="btn btn-xs btn-primary mr-1"
            onClick={() => {
              history.push(`${process.env.PUBLIC_URL}/biblioteca/documentos/editar-documento/${contentId}`);
            }}
          >
            <Edit2 size={16} />
          </button>

          <button
            type="button"
            className="btn btn-xs btn-danger mr-1"
            onClick={async () => {
              Swal.fire({
                title: '<strong>Confirmação</strong>',
                html: 'Tem certeza que deseja remover este documento?',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                focusConfirm: false,
                icon: 'question'
              })
                .then(async result => {
                  if (result.isConfirmed) {
                    try {
                      await deleteContentService(contentId);
  
                      toast.success('Documento removido com sucesso!');
  
                      await refreshContent();
                    } catch (e) {
                      const response = e.response;
  
                      if ( response && response.status && response.status === 423) {
                        toast.error('O documento não pode ser removido',);
                      } else {
                        toast.error('Erro ao remover documento.');
                      }
                    }
                  }
                })
                .catch(() => {});
            }}
          >
            <Trash2 size={16} />
          </button>

          <button
              type="button"
              className="btn btn-xs btn-warning"
              title={`${content.is_active ? 'Inativar' : 'Ativar'} conteúdo`}
              onClick={async () => {
                Swal.fire({
                  title: '<strong>Confirmação</strong>',
                  html: `Tem certeza que deseja ${
                    isActive ? 'inativar' : 'ativar'
                  } este documento?`,
                  showCancelButton: true,
                  cancelButtonText: 'Cancelar',
                  focusConfirm: false,
                  icon: 'question'
                })
                .then(async result => {
                  if (result.isConfirmed) {
                    try {
                      await activateOrInactivateContent(contentId, !isActive);
  
                      toast.success(`Documento ${isActive ? 'inativado' : 'ativado'} com sucesso!`,);
  
                      await refreshContent();
                    } catch (e) {
                      const response = e.response;
  
                      if ( response && response.status && response.status === 423) {
                        toast.error(`O documento não pode ser ${isActive ? 'inativar' : 'ativar'}`,);
                      } else {
                        toast.error(`Erro ao ${isActive ? 'inativar' : 'ativar'} documento. ${e.message}`);
                      }
                    }
                  }
                }).catch(() => {});
              }}
            >
              {content.is_active ? <X size={18} /> : <Check size={18} />}
            </button>
        </>
      );

      delete content.id;
      delete content.is_active;

      return content;
    });
    setContents(localContents);
  }, [history]);

  refreshContent = useCallback(async () => {
    setContents([]);
    await getContents();
  }, [getContents]);

  useEffect(() => {
    getContents();
  }, [getContents]);

  return (
    <Fragment>
      <Breadcrumb title="Listar Documentos" parent="Documentos" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Lista de Documentos</h5>
              </div>
              <div className="card-body">
                <div className="btn-popup pull-right">
                  <Link
                    to={`${process.env.PUBLIC_URL}/biblioteca/documentos/criar-documento`}
                    className="btn btn-primary"
                  >
                    Criar Documento
                  </Link>
                </div>
                <div className="clearfix"></div>
                <div
                  id="batchDelete"
                  className="category-table order-table coupon-list-delete"
                >
                  {
                    contents && contents.length
                    ? (
                      <Datatable
                        myData={contents}
                        pageSize={10}
                        pagination={true}
                        class="-striped -highlight"
                      />
                    ) : (
                      <div
                        className="
                          d-flex
                          justify-content-center
                          align-items-center
                        "
                      >
                        <span>Não há documentos</span>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ListContents;

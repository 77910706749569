import React, { useState, useEffect, useRef } from 'react';
import { Volume2, X, File } from 'react-feather';
import { getMedias } from '../../services/medias';
import './select-video-modal.css';
import { Upload } from 'react-feather';
import SambaUploader from 'samba-uploader';
import { toast } from 'react-toastify';

const SelectVideoModal = ({ setShouldShowSelectVideoModal, selectContent }) => {
  const [medias, setMedias] = useState([]);
  const [actualUploadProgress, setActualUploadProgress] = useState(0);
  const [sambaUploader, setSambaUploader] = useState(undefined);
  const sambaUploadButton = useRef(null);
  const [processingMedias, setProcessingMedias] = useState([]);
  const [shouldShowLoadMoreMedias, setShouldShowLoadMoreMedias] =
    useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [loadingMoreMedias, setLoadingMoreMedias] = useState(false);
  const numberOfMediasPerPage = 12;

  const getSambaVideosContents = async () => {
    try {
      let localMedias = await getMedias({
        status: 'ACTIVE',
        limit: numberOfMediasPerPage,
      });
      setMedias(localMedias);
    } catch (e) {
      const errorMessage = e.response && e.message;
      window.alert(
        'Houve um erro ao recuperar os vídeos a serem selecionados. ' +
          errorMessage,
      );
    }
  };

  const loadMoreMedias = async () => {
    setLoadingMoreMedias(true);
    const localContents = await getMedias({
      start: pageNumber,
      limit: numberOfMediasPerPage,
      types: 'VIDEO',
    });

    if (localContents && localContents.length) {
      const localJoinedContents = [...medias, ...localContents];

      if (localJoinedContents.length % numberOfMediasPerPage > 0) {
        setShouldShowLoadMoreMedias(false);
      }

      setMedias(localJoinedContents);
    } else {
      setShouldShowLoadMoreMedias(false);
    }

    setPageNumber(pageNumber + 1);
    setLoadingMoreMedias(false);
  };

  useEffect(() => {
    getSambaVideosContents();
  }, []);

  useEffect(() => {
    if (sambaUploadButton && sambaUploadButton.current && !sambaUploader) {
      setSambaUploader(
        new SambaUploader({
          button: sambaUploadButton.current,
          sambaUploadKey: process.env.REACT_APP_SAMBA_UPLOAD_KEY,
          allowedExtensions: [
            'avi',
            'wmv',
            'wma',
            'mov',
            'flv',
            'rm',
            'rmvb',
            'mp4',
            'mp3',
            'mkv',
            '3gp',
          ],
        }),
      );
    } else if (sambaUploader) {
      process.nextTick(() => {
        sambaUploader.on('start', event => {
          const nomeDoArquivo = event.name;
          toast.success(`O upload do seu vídeo "${nomeDoArquivo}" iniciou!`);

          const loading = document.getElementById('loading') || {};
          if (loading) {
            loading.style.display = 'flex';
          }
        });

        sambaUploader.on('complete', event => {
          const loading = document.getElementById('loading') || {};
          if (loading) {
            loading.style.display = 'none';
          }

          const nomeDoArquivo = event.name;
          toast.success(
            `O upload do seu conteúdo "${nomeDoArquivo}" foi concluído com sucesso!
            Em breve o conteúdo estará disponível para ser selecionado abaixo!`,
          );

          setActualUploadProgress(0);
        });

        sambaUploader.on('progress', event => {
          const progress = (event.uploadedBytes / event.totalBytes) * 100;
          if (progress) {
            setActualUploadProgress(progress);
          }
        });

        sambaUploader.on('error', event => {
          const loading = document.getElementById('loading') || {};
          if (loading) {
            loading.style.display = 'none';
          }

          const errorMessage = event && event.error && event.error.message;
          toast.error(
            `Houve um erro ao realizar o upload do seu arquivo. ${
              errorMessage.includes('INVALID_FILE_EXTENSION')
                ? 'O arquivo informado possui uma extensão inválida!'
                : errorMessage
            }`,
          );
        });
      });
    }
  }, [sambaUploadButton, sambaUploader]);

  useEffect(
    () => {
      const checkIfIsShowingSelectSambaVideosContent = () =>
        !!document.getElementById('select-video-modal');

      const checkForNewProcessingMedias = async () => {
        const localProcessingMedias = await getMedias({
          status: 'PROCESSING',
        });

        if (!!localProcessingMedias.length) {
          setProcessingMedias(
            localProcessingMedias.map(md => ({
              id: md.id,
              name: md.title,
              actualProcess: 'Processando',
            })),
          );
        } else {
          setProcessingMedias([]);
          if (window.previousProcessingMediaNumber > 0) {
            await getSambaVideosContents();
          }
        }

        window.previousProcessingMediaNumber = localProcessingMedias.length;

        if (checkIfIsShowingSelectSambaVideosContent()) {
          window.idOfRunningCheckForProcessingMediaJob = window.setTimeout(
            async () => await checkForNewProcessingMedias(),
            5000,
          );
        } else {
          window.clearTimeout(window.idOfRunningCheckForProcessingMediaJob);
          window.idOfRunningCheckForProcessingMediaJob = 0;
        }
      };

      if (checkIfIsShowingSelectSambaVideosContent()) {
        if (!window.idOfRunningCheckForProcessingMediaJob) {
          window.setTimeout(
            async () => await checkForNewProcessingMedias(),
            150,
          );
        } else {
          window.clearTimeout(window.idOfRunningCheckForProcessingMediaJob);
          window.idOfRunningCheckForProcessingMediaJob = window.setTimeout(
            async () => await checkForNewProcessingMedias(),
            150,
          );
        }
      }
    },
    // eslint-disable-next-line
    [],
  );

  return (
    <div className="select-video-modal-container">
      <div className="select-video-modal" id="select-video-modal">
        <div className="header">
          <h2 className="modal-title">Selecionar Conteúdo</h2>

          <div
            className="d-flex justify-content-start align-items-center"
            style={{ flexGrow: 1 }}
          >
            <button
              type="button"
              className="btn btn-lg btn-primary d-flex align-items-center mr-1"
              ref={sambaUploadButton}
            >
              <div className="mr-1">
                <Upload size={22} />
              </div>
              <div>Fazer Upload</div>
            </button>

            {actualUploadProgress > 0 && (
              <div
                className="d-flex justify-content-start align-items-start p-0 mt-1 rounded border"
                style={{ width: '200px', height: '20px' }}
              >
                <div
                  style={{ width: `${actualUploadProgress}%`, height: '100%' }}
                  className="bg-info"
                ></div>
              </div>
            )}
          </div>

          <button
            onClick={() => setShouldShowSelectVideoModal(false)}
            className="btn btn-danger"
            style={{
              width: '35px',
              height: '35px',
              borderRadius: '50%',
              padding: '5px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              bottom: '12px',
              right: '2px',
            }}
          >
            <X size={18} />
          </button>
        </div>

        <div className="medias">
          {medias && medias.length ? (
            <>
              {processingMedias && processingMedias.length ? (
                processingMedias.map(media => (
                  <div
                    key={media.id}
                    className="border rounded pt-3"
                    style={{
                      borderRadius: '15px',
                      cursor: 'not-allowed',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '300px',
                      height: '250px',
                      margin: '5px',
                      flexDirection: 'column',
                      overflow: 'hidden',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '20px 0',
                      }}
                    >
                      <div className="spinner medium">
                        <div></div>
                      </div>
                      <p className="text-center">{media.actualProcess}...</p>
                    </div>
                    <div className="text-center" style={{ flexGrow: 1 }}>
                      <b>{media.name}</b>
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
              {medias.map(media => (
                <div
                  key={media.id}
                  className="media"
                  onClick={() =>
                    selectContent(
                      media.id,
                      media.qualifier,
                      media.files &&
                        ((media.files.find(file => file) || { fileInfo: {} })
                          .fileInfo.duration ||
                          0),
                      media.thumbs &&
                        media.thumbs.length &&
                        (media.thumbs.find(thumb => thumb) || {}).url,
                    )
                  }
                >
                  <div className="media-thumbnail-container">
                    {media.qualifier === 'VIDEO' ? (
                      <img
                        alt="conteúdo"
                        src={
                          media &&
                          media.thumbs &&
                          media.thumbs.length &&
                          (media.thumbs.find(thumb => thumb) || {}).url
                        }
                        className="media-thumbnail"
                      />
                    ) : media.qualifier === 'AUDIO' ? (
                      <Volume2 size={64} />
                    ) : (
                      <File size={64} />
                    )}
                  </div>

                  <div className="media-metadata-container">
                    <h4 className="media-title" title={media.title}>
                      {media.title}
                    </h4>
                    <p className="media-postdate">
                      {media.postdate &&
                        new Intl.DateTimeFormat('pt-BR').format(
                          new Date(media.postdate),
                        )}
                    </p>
                  </div>
                </div>
              ))}
              {shouldShowLoadMoreMedias ? (
                <div className="col-sm-12">
                  <div className="d-flex justify-content-center">
                    {loadingMoreMedias ? (
                      <div className="spinner small"></div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={loadMoreMedias}
                      >
                        Carregar Mais
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <div className="d-flex flex-column justify-content-center align-items-center w-100">
              <div className="spinner medium">
                <div></div>
              </div>
              <p className="text-center" style={{ width: '100%' }}>
                Carregando...
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectVideoModal;

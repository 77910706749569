import React from 'react';
import ReactDOM from 'react-dom';
import './style.css';

const showModal = (title, modalContent) => {
  const modalContainer = document.createElement('div');
  modalContainer.id = 'global-modal-container';

  const modal = document.createElement('div');
  modal.id = 'global-modal';

  modalContainer.appendChild(modal);

  document.body.appendChild(modalContainer);

  ReactDOM.render(
    <div className="modal-content-container">
      <div
        className="modal-content-header"
      >
        <h3 className="modal-content-header-title">{title}</h3>
        <button
          className="
          modal-content-close-button 
          d-flex 
          justify-items-cnter 
          align-items-center"
          onClick={hideModal}
        >
          x
        </button>
      </div>
      <div className="modal-content-container">{modalContent}</div>
    </div>,
    modal,
  );
};

const hideModal = () => {
  const modalContainer = document.getElementById('global-modal-container');
  if (modalContainer) {
    modalContainer.remove();
  }
};

export { showModal, hideModal };

import React, { useEffect, useMemo, useState } from 'react';
import Swal from 'sweetalert2';
import Breadcrumb from '../common/breadcrumb';
import Datatable from '../common/datatable';
import getErrorMessage from '../../helpers/get-error-message';
import { useHistory } from 'react-router';
import {
  getLive as getLiveService,
  getLives as getLivesService,
  deleteLive as deleteLiveService,
  stopLive as stopLiveService,
  startLive as startLiveService,
} from '../../services/lives';
import { Link } from 'react-router-dom';
import { Copy, Edit, Play, Square, Trash } from 'react-feather';

const ListarLives = () => {
  const history = useHistory();
  const [lives, setLives] = useState([]);

  const editLive = liveId => {
    history.push(`${process.env.PUBLIC_URL}/lives/editar-live/${liveId}`);
  };

  const deleteLive = async liveId => {
    Swal.fire({
      icon: 'question',
      text: 'Tem certeza que deseja remover esta live?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await deleteLiveService(liveId);
          Swal.fire({
            icon: 'success',
            text: 'Live removida com sucesso!',
          });
          await getLives();
        } catch (error) {
          const errorMessage = getErrorMessage(error);
          Swal.fire({
            icon: 'error',
            text: 'Houve um erro ao remover a live. ' + errorMessage,
          });
        }
      }
    });
  };

  const stopLive = async liveId => {
    Swal.fire({
      icon: 'question',
      text: 'Tem certeza que deseja parar esta live?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await stopLiveService(liveId);
          Swal.fire({
            icon: 'success',
            text: 'Live parada com sucesso!',
          });
          await getLives();
        } catch (error) {
          const errorMessage = getErrorMessage(error);
          Swal.fire({
            icon: 'error',
            text: 'Houve um erro ao parar a live. ' + errorMessage,
          });
        }
      }
    });
  };

  const copyRmtp = async liveId => {
    const actualLive = await getLiveService(liveId);
    const rtmp = actualLive.rtmp;

    const actualDocument = document.getElementById('lives-container');

    const rtmpInput = document.createElement('input');
    rtmpInput.value = rtmp;
    actualDocument.appendChild(rtmpInput);
    rtmpInput.select();
    rtmpInput.setSelectionRange(0, 99999);
    document.execCommand('copy');
    rtmpInput.remove();
    Swal.fire({ text: `RTMP "${rtmp}" copiado!`, icon: 'info' });
  };

  const startLive = async liveId => {
    try {
      await startLiveService(liveId);
      Swal.fire({ icon: 'success', text: 'Live iniciada!' });
      await getLives();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        icon: 'error',
        text: 'Erro ao iniciar live. ' + errorMessage,
      });
    }
  };

  const getLives = async () => {
    const localLives = await getLivesService();
    setLives(localLives);
  };

  const livesToBeShown = useMemo(() => {
    if (lives && lives.length) {
      return lives.map(live => ({
        nome: live.name,
        descrição: live.description,
        inicio: live.start
          ? new Intl.DateTimeFormat('pt-BR', {
              dateStyle: 'short',
              timeStyle: 'short',
            }).format(new Date(live.start))
          : '',
        fim: live.stop
          ? new Intl.DateTimeFormat('pt-BR', {
              dateStyle: 'short',
              timeStyle: 'short',
            }).format(new Date(live.stop))
          : '',
        ações: (
          <>
            <button
              title="Visualizar Live"
              className="btn btn-xs btn-primary mr-1"
              onClick={() => editLive(live.live_id)}
            >
              <Edit size={16} />
            </button>
            {live.status !== 'ACTIVE' && live.status !== 'STOPPED' && (
              <button
                title="Iniciar Live"
                className="btn btn-xs btn-success mr-1"
                onClick={() => startLive(live.live_id)}
              >
                <Play size={16} />
              </button>
            )}
            {live.status === 'ACTIVE' && (
              <button
                title="Parar Live"
                className="btn btn-xs btn-warning mr-1"
                onClick={() => stopLive(live.live_id)}
              >
                <Square size={16} />
              </button>
            )}
            <button
              title="Remover Live"
              className="btn btn-xs btn-danger mr-1"
              onClick={() => deleteLive(live.live_id)}
            >
              <Trash size={16} />
            </button>
            {live.status === 'ACTIVE' && (
              <button
                title="Copiar RMTP"
                className="btn btn-xs btn-info"
                onClick={() => copyRmtp(live.live_id)}
              >
                <Copy size={16} />
              </button>
            )}
          </>
        ),
      }));
    }

    return [];
  }, [lives]);

  useEffect(() => {
    getLives();
  }, []);

  return (
    <>
      <Breadcrumb title="Listar Lives" parent="Lives" />
      <div className="container-fluid" id="lives-container">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Lista de Lives</h5>
              </div>
              <div className="card-body">
                <div className="btn-popup pull-right">
                  <Link
                    to={`${process.env.PUBLIC_URL}/lives/criar-live`}
                    className="btn btn-primary"
                  >
                    Criar Live
                  </Link>
                </div>
                <div className="clearfix"></div>
                <div
                  id="batchDelete"
                  className="category-table order-table coupon-list-delete"
                >
                  {lives && lives.length ? (
                    <Datatable
                      myData={livesToBeShown}
                      pageSize={10}
                      pagination={true}
                      class="-striped -highlight"
                    />
                  ) : (
                    <h4>Não foram encontradas lives!</h4>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListarLives;

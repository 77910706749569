import React from 'react';
import { Check, X } from 'react-feather';
import secondsToTimeText from '../secondsToTimeText';

const convertVideoFromDB = video => ({
  id: video.content_id,
  titulo: video.title,
  descrição: video.description,
  duração: `${secondsToTimeText(video.duration)}`,
  url: (
    <a target="_blanc" href={video.reference}>
      {video.reference}
    </a>
  ),
  ativo: video.is_active ? <Check size={16} /> : <X size={16} />,
  is_active: video.is_active,
});

export { convertVideoFromDB };

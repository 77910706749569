import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { listQuestions } from '../../services/question';
import { createExam as createExamService } from '../../services/exams';
import Select from 'react-select';
import checkEmptyString from '../../helpers/check-empty-string';

const TabSetCriarQuestao = () => {
  const history = useHistory();

  const [exam, setExam] = useState({
    title: '',
    instructions: '',
    amount: '',
    average: '',
    attempts: '',
    questions: [],
    selected: [],
  });

  const fetchExams = useCallback(async () => {
    const questions = await listQuestions();

    const questionOptions = questions
      .filter(({ enabled }) => enabled)
      .map(({ enunciated, question_id }) => ({
        label: enunciated,
        value: question_id,
      }));

    setExam(exam => ({ ...exam, questions: questionOptions }));
  }, []);

  useEffect(() => {
    fetchExams();
  }, [fetchExams]);

  const createExam = async event => {
    event.preventDefault();

    try {
      const { title, instructions, amount, average, attempts, selected } = exam;

      if (checkEmptyString(title)) {
        throw new Error('Informe o título da prova!');
      }

      if (checkEmptyString(instructions)) {
        throw new Error('Informe as instruções para realização da prova!');
      }

      if (!amount) {
        throw new Error('Informe o número de questões da prova!');
      }

      if (!average) {
        throw new Error('Informe a média da prova!');
      }
      
      if (!attempts) {
        throw new Error('Informe o número de tentativas!');
      }

      if (selected.length === 0) {
        throw new Error('Selecione ao menos uma questão!');
      }

      if (selected.length < Number(amount)) {
        throw new Error(`Selecione ao menos ${amount} questões!`);
      }

      const body = {
        title,
        instructions,
        amount_questions: [{ points: 1, amount }],
        average,
        questions: selected.map(({ value }) => ({ question_id: value })),
        attempts,
      };

      await createExamService(body);
      toast.success('Prova criada com sucesso!');
      history.push(`${process.env.PUBLIC_URL}/provas/listar`);
    } catch (e) {
      toast.error('Erro ao criar prova. ' + e.message);
    }
  };

  function handleAvgChange(e) {
    const { value } = e.target;
    let avg = value > 100 ? 100 : value;
    avg = avg < 0 ? avg * -1 : avg;
    setExam({ ...exam, average: avg });
  }

  return (
    <>
      <div className="tab-pane fade active show">
        <form onSubmit={e => createExam(e)}>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group row">
                <label
                  htmlFor="title"
                  className="col-xl-2 col-md-4 d-flex justify-content-start"
                >
                  <span className="text-danger p-1">*</span> Título
                </label>
                <input
                  maxLength={80}
                  id="title"
                  className="form-control col-md-10"
                  type="text"
                  value={exam.title}
                  onChange={e => setExam({ ...exam, title: e.target.value })}
                />
              </div>
              <div className="form-group row">
                <label
                  htmlFor="instructions"
                  className="col-xl-2 col-md-4 d-flex justify-content-start"
                >
                  <span className="text-danger p-1">*</span> Instruções
                </label>
                <textarea
                  id="instructions"
                  rows="2"
                  className="form-control col-md-10"
                  type="text"
                  value={exam.instructions}
                  onChange={e =>
                    setExam({ ...exam, instructions: e.target.value })
                  }
                />
              </div>
              <div className="form-group row">
                <label
                  htmlFor="question_number"
                  className="col-xl-2 col-md-4 d-flex justify-content-start"
                >
                  <span className="text-danger p-1">*</span> Número de questões
                </label>
                <input
                  id="question_number"
                  className="form-control col-md-10"
                  type="number"
                  min="1"
                  max="100"
                  value={exam.amount}
                  onChange={e => setExam({ ...exam, amount: e.target.value })}
                />
              </div>
              <div className="form-group row">
                <label
                  htmlFor="average"
                  className="col-xl-2 col-md-4 d-flex justify-content-start"
                >
                  <span className="text-danger p-1">*</span> Média (%)
                </label>
                <input
                  id="average"
                  className="form-control col-md-10"
                  type="number"
                  min={0}
                  max={100}
                  value={exam.average}
                  onChange={e => handleAvgChange(e)}
                />
              </div>
              <div className="form-group row">
                <label
                  htmlFor="attempts"
                  className="col-xl-2 col-md-4 d-flex justify-content-start"
                >
                  <span className="text-danger p-1">*</span> Tentativas
                </label>
                <input
                  id="attempts"
                  className="form-control col-md-10"
                  type="number"
                  min="1"
                  max="100"
                  value={exam.attempts}
                  onChange={e => setExam({ ...exam, attempts: e.target.value })}
                />
              </div>
              <div className="form-group row">
                <label
                  htmlFor="questions"
                  className="col-xl-2 col-md-4 d-flex justify-content-start"
                >
                  <span className="text-danger p-1">*</span> Questões
                </label>
                <Select
                  id="questions"
                  value={exam.selected}
                  onChange={s => {
                    setExam({ ...exam, selected: s });
                  }}
                  options={exam.questions}
                  isMulti={true}
                  placeholder="Selecione as questões que farão parte da prova"
                  className="col-md-10 p-0"
                />
              </div>
            </div>
          </div>

          <div className="pull-right">
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={() => {
                history.push(`${process.env.PUBLIC_URL}/provas/listar`);
              }}
            >
              Cancelar
            </button>
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default TabSetCriarQuestao;

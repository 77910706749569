import React, { useCallback, useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import { hideModal } from '../modal';
import {
  createBanner as createBannerService,
  updateBanner as updateBannerService,
  getBanner,
  uploadImage as uploadImageService,
} from '../../services/banner';
import checkEmptyString from '../../util/checkEmptyString';

const CreateAndEditBanner = ({ bannerId, lastPosition, onCreateOrUpdate }) => {
  const [description, setDescription] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [thumbnailToUpload, setThumbnailToUpload] = useState();
  const [position, setPosition] = useState(lastPosition + 1);
  const [hyperlink, setHyperlink] = useState('');

  const fileInput = useRef(null);

  const hasEmptyFields = () => {
    if (checkEmptyString(description)) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha todos os campos!',
        icon: 'error',
      });

      return true;
    }

    return false;
  };

  const createBanner = async event => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    if (hasEmptyFields()) {
      return;
    }
    try {
      const createdBanner = await createBannerService(
          description,
          'banner',
          position,
          hyperlink ? hyperlink : null,
      );

      if (thumbnailToUpload) {
        const formData = new FormData();
        formData.append('file', thumbnailToUpload);
        formData.append('type', thumbnailToUpload.type);

        await uploadImageService(createdBanner.home_info_id, formData);
      }

      Swal.fire({
        title: 'Sucesso!',
        text: 'Banner criado com sucesso!',
        icon: 'success',
      });

      if (onCreateOrUpdate) {
        setTimeout(() => {
          onCreateOrUpdate();
        }, 500);
      }

      hideModal();
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Erro ao criar banner. ' + error.message,
        icon: 'error',
      });
    }
  };

  const updateBanner = async event => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    if (hasEmptyFields()) {
      return;
    }

    try {
      const updatedBanner = await updateBannerService(
          bannerId,
          'banner',
          position,
          description,
          hyperlink ? hyperlink : null,
      );

      if (thumbnailToUpload) {
        const formData = new FormData();
        formData.append('file', thumbnailToUpload);
        formData.append('type', thumbnailToUpload.type);

        await uploadImageService(updatedBanner.home_info_id, formData);
      }

      Swal.fire({
        title: 'Sucesso!',
        text: 'Banner editado com sucesso!',
        icon: 'success',
      });

      if (onCreateOrUpdate) {
        setTimeout(() => {
          onCreateOrUpdate();
        }, 500);
      }

      hideModal();
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Erro ao editar banner. ' + error.message,
        icon: 'error',
      });
    }
  };

  const uploadImage = useCallback(() => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  });

  const handleFile = event => {
    if (event.currentTarget.files && event.currentTarget.files.length) {
      const file = event.currentTarget.files[0];

      try {
        if (file) {
          if (file.type && !file.type.includes('image')) {
            throw new Error('Arquivo inválido selecionado!');
          }

          setThumbnailToUpload(file);

          let fr = new FileReader();

          fr.onloadend = () => {
            setThumbnail(`${fr.result}`);
          };

          fr.readAsDataURL(file);
        }
      } catch (e) {
        Swal.fire({
          title: 'Erro',
          text:
            'Ocorreu um erro ao carregar a imagem selecionada. ' + e.message,
          icon: 'error',
        });
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (bannerId) {
        const banner = await getBanner(bannerId);
        if (banner && Object.keys(banner).length) {
          setDescription(banner.description);
          setThumbnail(banner.image);
          setPosition(banner.position);
          setHyperlink(banner.hyperlink);
        }
      }
    })();
  }, [bannerId]);

  return (
    <div className="p-3 w-100 h-100">
      <form onSubmit={e => (bannerId ? updateBanner(e) : createBanner(e))}>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Descrição
          </label>
          <textarea
            value={description}
            onChange={e => setDescription(e.target.value)}
            id="description"
            className="form-control"
          ></textarea>
        </div>
        <div className="mb-3">
          <label htmlFor="hyperlink" className="form-label">
            Link
          </label>
          <input
            value={hyperlink}
            onChange={e => setHyperlink(e.target.value)}
            id="hyperlink"
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Imagem
          </label>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <button
              onClick={uploadImage}
              id="description"
              type="button"
              className="btn btn-primary mb-2"
            >
              Selecionar Imagem
            </button>

            {thumbnail && (
              <img
                style={{ maxWidth: '400px', maxHeight: '400px' }}
                src={thumbnail}
                alt="thumbnail"
              />
            )}
          </div>
          <input
            onChange={handleFile}
            ref={fileInput}
            accept="image/*"
            type="file"
            style={{ display: 'none' }}
          />
        </div>
        <div className="mb-3 w-100 d-flex justify-content-end align-items-center">
          <button
            type="button"
            className="btn btn-primary mr-2"
            onClick={hideModal}
          >
            Cancelar
          </button>
          <button type="submit" className="btn btn-secondary">
            Salvar
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateAndEditBanner;

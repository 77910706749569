import httpClient from '../http-client';

const getTags = async () => {
  const tags = (await httpClient.get('/tags')).data;
  return tags;
};

const getTag = async tagId => {
  const localTag = (await httpClient.get(`/tags/${tagId}`)).data;
  return localTag;
};

const deleteTag = async tagId => {
  await httpClient.delete(`/tags/${tagId}`);
};

const createTag = async newTag => {
  await httpClient.post('/tags', newTag);
};

const updateTag = async (tagId, newTag) => {
  await httpClient.put(`/tags/${tagId}`, newTag);
};

const getCategories = async () => {
  const categories = (await httpClient.get('/forum/categories')).data;
  return categories;
};

const getCategory = async categoryId => {
  const localCategory = (await httpClient.get(`/forum/categories/${categoryId}`)).data;
  return localCategory;
};

const deleteCategory = async categoryId => {
  await httpClient.delete(`/forum/categories/${categoryId}`);
};

const createCategory = async newCategory => {
  await httpClient.post('/forum/categories', newCategory);
};

const updateCategory = async (categoryId, newCategory) => {
  await httpClient.put(`/forum/categories/${categoryId}`, newCategory);
};

export {
  getTags,
  getTag,
  deleteTag,
  getCategories,
  createTag,
  updateTag,
  getCategory,
  deleteCategory,
  createCategory,
  updateCategory,
};

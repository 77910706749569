import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import Tabset from './tabset-criar-curso';

export class Criar_curso extends Component {
  render() {
    return (
      <Fragment>
        <Breadcrumb title="Adicionar Curso (Avulso)" parent="Cursos (Avulsos)" />
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <h5>Criar Curso (Avulso)</h5>
            </div>
            <div className="card-body">
              <Tabset />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Criar_curso;

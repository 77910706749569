import httpClient from '../http-client';

export const uploadFile = async formData => {
  if (!formData) {
    throw new Error('Nenhum arquivo foi enviado!');
  }

  const { data } = await httpClient.post(`/files/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return data;
};

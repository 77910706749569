import React from 'react';
import Breadcrumb from '../common/breadcrumb';
import Tabset from './tabset-editar-autor';

const EditarAutor = () => {
  return (
    <>
      <Breadcrumb title="Editar Autor" parent="Autores" />
      <div className="container-fluid">
        <div className="card">
          <div className="card-header">
            <h5>Editar Autor</h5>
          </div>
          <div className="card-body">
            <Tabset />
          </div>
        </div>
      </div>
    </>
  );
}

export default EditarAutor;

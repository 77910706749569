import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  getTrail as getTrailService,
  updateTrail as updateTrailService,
} from '../../services/trails';
import { uploadFile } from '../../services/files';
import checkEmptyString from '../../helpers/check-empty-string';
import { ArrowUp, ArrowDown } from 'react-feather';
import { hideModal, showModal } from '../modal';
import SelectCourses from './select-courses';
import Swal from 'sweetalert2';
import SelectImage from '../select-image';
import { getSegmentationTypes } from '../../services/segmentations';
import getErrorMessage from '../../helpers/get-error-message';

const Tabset = () => {
  const history = useHistory();
  const { trailId } = useParams();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [segTypes, setSegTypes] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedRegistrations, setSelectedRegistrations] = useState([]);

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const selectImage = () => {
    showModal(
      'Cortar Imagem',
      <SelectImage aspect={1.712} onCutImage={onCutImage} />,
    );
  };

  const onCutImage = file => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setThumbnail(`${reader.result}`);

      hideModal();
    }
  };

  const updateTrail = async event => {
    event.preventDefault();

    const proceedWithUpdate = async () => {
      try {
        if (!selectedCourses || !selectedCourses.length) {
          throw new Error('Selecione ao menos um curso para continuar!');
        }

        if (checkEmptyString(name)) {
          throw new Error('Informe um nome para a disciplina!');
        }

        if (checkEmptyString(description)) {
          throw new Error('Informe uma descrição para a disciplina!');
        }

        const localSelectedCourses = selectedCourses.map((course, index) => ({
          position: index + 1,
          course_id: course.id,
        }));

        const newTrail = {
          name: name,
          description: description,
          courses: localSelectedCourses,
          thumbnail: undefined,
        };

        const genericType = segTypes.find(item => item.type === 'Generic');
        const roleFiltered = selectedRoles == null ? [] : genericType.items.filter(seg => selectedRoles.some(role => seg.item === role.label));
        const registrationsFiltered = selectedRegistrations == null ? [] : genericType.items.filter(seg => selectedRegistrations.some(reg => seg.item === reg.label));

        if (
          (selectedRoles && selectedRoles.length) ||
          (selectedRegistrations && selectedRegistrations.length)
        ) {
          newTrail.segmentation_items = [
            ...(roleFiltered || []).map(role => ({
              segmentation_item_id: role.segmentation_item_id,
            })),
            ...(registrationsFiltered || []).map(reg => ({
              segmentation_item_id: reg.segmentation_item_id,
            })),
          ];
        }

        if (thumbnail && thumbnail.length && thumbnail.includes('base64')) {
          try {
            const localThumbnail = dataURLtoFile(
              thumbnail,
              `thumbnail.${thumbnail}`,
            );

            const formData = new FormData();
            formData.append('file', localThumbnail);

            const { reference } = await uploadFile(formData);
            newTrail.thumbnail = reference;
          } catch (e) {
            throw new Error('Erro ao mudar a imagem de capa. ' + e.message);
          }
        }

        await updateTrailService(trailId, newTrail);

        toast.success('Disciplina editada com sucesso!');

        history.push(
          `${process.env.PUBLIC_URL}/disciplinas/listar-disciplinas`,
        );
      } catch (e) {
        const errorMessage = e.response || e.message;
        toast.error('Erro ao editar curso.' + errorMessage);
      }
    };

    await proceedWithUpdate();
  };

  const changeCoursePosition = async (course, up) => {
    const foundCourse = selectedCourses.find(c => c.id === course.id);
    if (foundCourse) {
      const indexOfFoundCourse = selectedCourses.indexOf(foundCourse);
      if (
        indexOfFoundCourse > -1 &&
        indexOfFoundCourse < selectedCourses.length
      ) {
        if (up) {
          const previousCourse = selectedCourses[indexOfFoundCourse - 1];
          selectedCourses.splice(indexOfFoundCourse - 1, 1, foundCourse);
          selectedCourses.splice(indexOfFoundCourse, 1, previousCourse);
        } else {
          const nextCourse = selectedCourses[indexOfFoundCourse + 1];
          selectedCourses.splice(indexOfFoundCourse + 1, 1, foundCourse);
          selectedCourses.splice(indexOfFoundCourse, 1, nextCourse);
        }
      }
    }

    setSelectedCourses([...selectedCourses]);
  };

  const checkShouldShowChangePositionButton = (course, up) => {
    const foundCourse = selectedCourses.find(c => c.id === course.id);
    if (foundCourse) {
      const indexOfFoundCourse = selectedCourses.indexOf(foundCourse);
      if (
        indexOfFoundCourse > -1 &&
        indexOfFoundCourse < selectedCourses.length
      ) {
        if (up) {
          return indexOfFoundCourse > 0;
        } else {
          return indexOfFoundCourse < selectedCourses.length - 1;
        }
      }
    }

    return false;
  };

  const selectCourses = () => {
    showModal(
      'Selecionar Módulos',
      <SelectCourses
        initialSelectedCourses={[...selectedCourses]}
        onSelectCourses={selectedCourses => {
          setSelectedCourses([...selectedCourses]);
          hideModal();
        }}
      />,
    );
  };

  useEffect(() => {
    (async () => {
      const localTrail = await getTrailService(trailId);

      const localSegTypes = await getSegmentationTypes();

      setName(localTrail.name);
      setDescription(localTrail.description);
      setThumbnail(localTrail.thumbnail);

      const localSelectedCourses =
        localTrail &&
        localTrail.courses.length &&
        localTrail.courses
          .sort((a, b) => (a.position > b.position ? 1 : -1))
          .map(c => ({ ...c, id: c.course_id }));

      setSelectedCourses(localSelectedCourses);

      const localSegs = localTrail.segmentations;
      const typeRoles = localSegTypes.find(seg => seg.type === 'Cargo');
      const typeRegistrations = localSegTypes.find(seg => seg.type === 'Matricula');
      
      const localRegistrations =
        (typeRegistrations.items.filter(segType => localSegs[0].items.some(seg => seg.item === segType.item)) || []);

      const localRoles =
        (typeRoles.items.filter(segType => localSegs[0].items.some(seg => seg.item === segType.item)) || [])

      setSelectedRegistrations(
        localRegistrations.map(item => ({
          value: item.segmentation_item_id,
          label: item.item,
        })),
      );
      setSelectedRoles(
        localRoles.map(item => ({
          value: item.segmentation_item_id,
          label: item.item,
        })),
      );
    })();
  }, [segTypes, trailId]);

  useEffect(() => {
    (async () => {
      const addSegTypes = await getSegmentationTypes();

      setSegTypes(addSegTypes);
    })();
  }, []);

  const roles = useMemo(() => {
    if (segTypes && segTypes.length) {
      const roleSegType = segTypes.find(segType => segType.type === 'Cargo');
      if (roleSegType) {
        if (roleSegType.items && roleSegType.items.length) {
          return roleSegType.items.map(item => ({
            label: item.item,
            value: item.segmentation_item_id,
          }));
        }
      }
    }

    return [];
  }, [segTypes]);

  const registrations = useMemo(() => {
    if (segTypes && segTypes.length) {
      const registrationType = segTypes.find(
        segType => segType.type === 'Matricula',
      );
      if (registrationType) {
        if (registrationType.items && registrationType.items.length) {
          return registrationType.items.map(item => ({
            label: item.item,
            value: item.segmentation_item_id,
          }));
        }
      }
    }

    return [];
  }, [segTypes]);

  return (
    <>
      <div className="tab-pane fade active show">
        <form className="needs-validation" onSubmit={e => updateTrail(e)}>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group row">
                <label className="col-xl-2 col-md-4">
                  <span>*</span> Nome
                </label>
                <input
                  className="form-control col-md-10"
                  type="text"
                  required={true}
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </div>
              <div className="form-group row">
                <label className="col-xl-2 col-md-4">
                  <span>*</span> Descrição
                </label>
                <textarea
                  className="form-control col-md-10"
                  type="text"
                  rows="3"
                  required={true}
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                />
              </div>

              <div className="form-group row">
                <label className="col-xl-2 col-md-4">
                  <span>*</span> Cursos
                </label>

                <div className="border rounded col-xl-10 col-md-8 p-3 text-center">
                  <button
                    className="btn btn-primary mb-3"
                    type="button"
                    onClick={selectCourses}
                  >
                    Selecionar Módulos
                  </button>

                  <div className="row">
                    {selectedCourses && selectedCourses.length ? (
                      selectedCourses.map(course => (
                        <div key={course.id} className="col-sm-12">
                          <div
                            className="card border"
                            style={{ marginBottom: '10px' }}
                          >
                            <div
                              className="card-body"
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '20px',
                              }}
                            >
                              <h4 className="card-title">{course.title}</h4>
                              <div>
                                <div className="m-2">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      checkShouldShowChangePositionButton(
                                        course,
                                        true,
                                      ) && changeCoursePosition(course, true)
                                    }
                                    className="btn btn-xs btn-light btn-small ml-2"
                                    disabled={
                                      !checkShouldShowChangePositionButton(
                                        course,
                                        true,
                                      )
                                    }
                                  >
                                    <ArrowUp size={22} />
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      checkShouldShowChangePositionButton(
                                        course,
                                        false,
                                      ) && changeCoursePosition(course, false)
                                    }
                                    className="btn btn-xs btn-light btn-small ml-2"
                                    disabled={
                                      !checkShouldShowChangePositionButton(
                                        course,
                                        false,
                                      )
                                    }
                                  >
                                    <ArrowDown size={22} />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-group row mb-1">
                <label className="col-xl-2 col-md-4">Imagem</label>
                <div className="text-center col-md-10 border rounded p-1">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={selectImage}
                  >
                    Selecionar Imagem
                  </button>

                  <div>
                    {thumbnail ? (
                      <img
                        className="mt-1"
                        style={{ width: '400px' }}
                        src={thumbnail}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-group row mb-2">
                <label className="col-xl-2 col-md-4" htmlFor="roles">
                  Cargo
                </label>
                <div className="text-center col-md-10 border rounded p-1">
                  <Select
                    id="roles"
                    isMulti={true}
                    options={roles}
                    value={selectedRoles}
                    onChange={options => setSelectedRoles(options)}
                  />
                </div>
              </div>

              <div className="form-group row mb-2">
                <label className="col-xl-2 col-md-4" htmlFor="registrations">
                  Matricula
                </label>
                <div className="text-center col-md-10 border rounded p-1">
                  <Select
                    id="registrations"
                    isMulti={true}
                    options={registrations}
                    value={selectedRegistrations}
                    onChange={options => setSelectedRegistrations(options)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="pull-right">
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={() => {
                history.push(
                  `${process.env.PUBLIC_URL}/disciplinas/listar-disciplinas`,
                );
              }}
            >
              Cancelar
            </button>

            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Tabset;
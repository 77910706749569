import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { 
  getAuthors as getAuthorsService, 
  deleteAuthor, 
  activateOrInactivateAuthor 
} from '../../services/authors';
import Breadcrumb from '../common/breadcrumb';
import Datatable from '../common/datatable';
import { Edit2, Trash2, Check, X } from 'react-feather';
import { toast } from 'react-toastify';


const ListarAulas = () => {
  const [authors, setAuthors] = useState([]);
  const history = useHistory();
  let refreshAuthors = useCallback(() => {}, []);

  const getAuthors = useCallback(async () => {
    const localAuthors = await getAuthorsService();

    if (localAuthors && localAuthors.length) {
      localAuthors.map((author) => {
        const authorId = author.id;
        const isActive = author.is_active;

        author['ações'] = (
          <>
            <button
              type="button"
              className="btn btn-xs btn-primary mr-1"
              title="Editar autor"
              onClick={() => {
                history.push(`/autores/editar-autor/${authorId}`);
              }}
            >
              <Edit2 size={18} />
            </button>
    
            <button
              type="button"
              className="btn btn-xs btn-danger mr-1"
              title="Excluir autor"
              onClick={async () => {
                if (window.confirm('Tem certeza que deseja remover este autor?')) {
                  try {
                    await deleteAuthor(authorId);

                    toast.success('Autor removido com sucesso!');

                    await refreshAuthors();
                  } catch (e) {
                    toast.error('Erro ao remover autor. ' + e.message);
                  }
                }
              }}
            >
              <Trash2 size={18} />
            </button>

            <button
              type="button"
              className="btn btn-xs btn-warning"
              title={`${author.is_active ? 'Inativar' : 'Ativar'} autor`}
              onClick={async () => {
                if (window.confirm(`Tem certeza que deseja ${isActive ? 'inativar' : 'ativar'} este autor?`)) {
                  try {
                    await activateOrInactivateAuthor(authorId, !isActive);

                    toast.success(`Autor ${isActive ? 'inativado' : 'ativado'} com sucesso!`);

                    await refreshAuthors();
                  }
                  catch (e) {
                    toast.error(`Erro ao ${isActive ? 'inativar' : 'ativar'} autor. ${e.message}`);
                  }
                }
              }}
            >
              {
                author.is_active
                  ? <X size={18} />
                  : <Check size={18} />
              }
            </button>
          </>
        );

        delete author.is_active;
        delete author.id;

        return author;
      });

      setAuthors(localAuthors);
    }
  }, [history, refreshAuthors]);

  refreshAuthors = useCallback(async () => {
    setAuthors([]);
    await getAuthors();
  }, [getAuthors]);

  useEffect(() => {
    getAuthors();
  }, [getAuthors]);

  return (
    <>
      <Breadcrumb title="Listar Autores" parent="Autores" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Lista de Autores</h5>
              </div>

              <div className="card-body">
                <div className="btn-popup pull-right">
                  <Link
                    to={`${process.env.PUBLIC_URL}/autores/criar-autor`}
                    className="btn btn-secondary"
                  >
                    Criar Autor
                  </Link>
                </div>

                <div className="clearfix"></div>
                
                <div
                  id="batchDelete"
                  className="category-table order-table coupon-list-delete"
                >
                  {
                    authors && authors.length 
                      ? (
                        <Datatable
                          myData={authors}
                          pageSize={10}
                          pagination={true}
                          class="-striped -highlight"
                        />
                      ) : (
                        <div 
                          className="
                            d-flex 
                            justify-content-center 
                            align-items-center
                          "
                        >
                         <span>{" "}</span>
                        </div>
                      )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListarAulas;

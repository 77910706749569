import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Breadcrumb from '../common/breadcrumb';
import { toast } from 'react-toastify';
import {
  createExtraMaterial as createExtraMaterialService,
  deleteExtraMaterial as deleteExtraMaterialService,
  updateCourseExtraMaterial,
} from "../../services/extra-materials";
import { hideModal, showModal } from "../../helpers/modal";
import {
  ContentUploadOrSelectContainer,
  CreateAndEditContentContainer,
  GroupSelectButtons,
  DefaultCreationForm,
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
  DefaultInput,
  DefaultTextArea
} from "./style";
import {
  createContent as createContentService,
  getContent as getContentService,
  updateContent as updateContentService,
} from "../../services/contents-trail";
import checkEmptyString from "../../helpers/check-empty-string";
import { uploadFile } from "../../services/files";
import DefaultSwitchScorm from "./DefaultSwitchScorm";
import ScormCourses from "./ScormCourses";
import SelectVideoModal from "../../components/select-video-modal";
import { Download, Trash } from 'react-feather';



const CreateAndEditContent = () => {
  const [extraMaterials, setExtraMaterials] = useState([]);
  const [extraMaterialsToBeDeleted, setExtraMaterialsToBeDeleted] =
    useState([]);
  const { contentId } = useParams();
  const history = useHistory();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [reference, setReference] = useState("");
  const [duration, setDuration] = useState(0);
  const [type, setType] = useState("");
  const [switchState, setSwitchState] = useState("video");
  const [isScormVersion12, setIsScormVersion12] = useState(false);
  const [shouldShowSelectVideoModal, setShouldShowSelectVideoModal] =
    useState(false);

  const onSelectContent = (sambaVideosContent) => {
    if (sambaVideosContent) {
      if (sambaVideosContent.files && sambaVideosContent.files.length) {
        const firstFile = sambaVideosContent.files[0];

        if (firstFile) {
          const playerKey = process.env.REACT_APP_PLAYER_KEY;
          const referenceUrl = `${process.env.REACT_APP_PLAYER_INITIAL_URL}/${playerKey}/${sambaVideosContent.id}`;

          setReference(referenceUrl);
          setDuration(firstFile.fileInfo.duration / 1000);
          setType(firstFile.qualifier.toUpperCase());
        }
      }
    }
  };

  hideModal();

  const goToContents = () => {
    history.push('/aulas/listar-aulas');
  };

  const createContent = async (event) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error("Informe um título valido para o conteúdo.");
      }

      if (checkEmptyString(description)) {
        throw new Error("Informe uma descrição valida para o conteúdo.");
      }

      if (!reference) {
        throw new Error("Selecione um conteúdo antes.");
      }

      const createdContent = await createContentService({
        title: title,
        description: description.trim().length ? description : undefined,
        duration: duration,
        reference: reference,
        type:
          switchState === "scorm"
            ? switchState.toUpperCase()
            : type.toUpperCase(),
        info: switchState === 'scorm'
          ? { scorm_version: isScormVersion12 ? '1.2' : '2004' }
          : undefined,

      }, false);

      const contentId = createdContent.content_id;

      let uploadedMaterials = [];

      if (extraMaterials && extraMaterials.length >= 1) {
        for (let material of extraMaterials) {
          const formData = new FormData();
          formData.append('file', material);

          const file = await uploadFile(formData);
          const { reference, original_name } = file;
          uploadedMaterials.push({ reference, filename: original_name, info: { tags: material.tags } });
        }
      }

      if (uploadedMaterials.length >= 1) {
        await createExtraMaterialService(contentId, {
          extra_materials: uploadedMaterials,
        });
      }

      toast.success('Aula criada com sucesso!');

      goToContents();
    } catch (error) {
      const errorMesssage = error.response || error.message;
      toast.error(errorMesssage);
    }
  };

  const updateContent = async event => {
    event.preventDefault();

    try {
      if (checkEmptyString(reference)) {
        throw new Error('Selecione uma aula antes de edita-la.');
      }

      if (checkEmptyString(title)) {
        throw new Error('Informe um titulo para a aula!');
      }

      if (checkEmptyString(description)) {
        throw new Error('Informe uma descrição para a aula!');
      }

      const newContent = {
        title: title,
        description: description,
        type: type,
        duration: duration,
        reference: reference,
      };

      for (let material of extraMaterialsToBeDeleted) {
        await deleteExtraMaterialService(material.id);
      }

      let uploadedMaterials = [];
      let materialsToBeSent = []

      if (extraMaterials && extraMaterials.length >= 1) {
        const unsavedMaterial = extraMaterials.filter(extraMaterial => !extraMaterial.alreadySaved);

        for (let material of unsavedMaterial) {
          const formData = new FormData();
          formData.append('file', material);

          const file = await uploadFile(formData);
          const { reference, original_name } = file;
          uploadedMaterials.push({ reference, filename: original_name, info: { tags: material.tags } });
        }

        const alteredMaterials = extraMaterials.filter(extraMaterial => extraMaterial.isChange);

        for (let material of alteredMaterials) {
          const data = {
            "extra_material_id": material.id,
            "extra_material": {
              info: {
                tags: material.tags
              }
            }
          }

          materialsToBeSent.push(data)
        }

        if (materialsToBeSent.length >= 1) {
          await updateCourseExtraMaterial({
            "extra_materials": materialsToBeSent
          })
        }
      }

      await updateContentService(contentId, newContent).then(async () => {
        if (uploadedMaterials.length >= 1) {
          await createExtraMaterialService(contentId, {
            extra_materials: uploadedMaterials,
          });
        }
      });

      toast.success('Aula editada com sucesso!');
      goToContents();
    } catch (e) {
      const errorMesssage = e.response || e.message;
      toast.error(errorMesssage);
    }
  };



  const selectContent = (mediaId, localType, localDuration) => {

    try {
      const playerKey = process.env.REACT_APP_PLAYER_KEY;
      const localReference = `${process.env.REACT_APP_PLAYER_INITIAL_URL}/${playerKey}/${mediaId}`;

      setReference(localReference);
      setType(localType);
      setDuration(parseInt(localDuration / 1000));
      setShouldShowSelectVideoModal(false);
    } catch (e) {
      const errorMessage = e.response && e.message;
      toast.error('Houve um erro ao selecionar a aula. ' + errorMessage);
    }
  };


  const getContent = useCallback(async () => {
    if (contentId) {
      const content = await getContentService(contentId);

      if (content && Object.keys(content).length) {
        setTitle(content.title);
        setDescription(content.description);
        setReference(content.reference);
        setDuration(content.duration);
        setType(content.type);

        const localExtraMaterials =
          content.extra_materials &&
          content.extra_materials.length &&
          content.extra_materials.map((extraMaterial) => ({
            id: extraMaterial.extra_material_id,
            name: extraMaterial.name,
            alreadySaved: true,
            reference: extraMaterial.reference,
            isChange: false,
            tags: Object.keys(extraMaterial.info).length ? extraMaterial.info.tags : { required: false }
          }));

        setExtraMaterials(localExtraMaterials);
      }
    }
  }, [contentId]);

  useEffect(() => {
    getContent();
  }, [getContent]);

  const isEditting = useMemo(() => {
    if (contentId) {
      return true;
    }

    return false;
  }, [contentId]);

  const addExtraMaterial = (event) => {
    event.preventDefault();

    if (event) {
      let file = event.target.files[0];
      file.alreadySaved = false;
      file.tags = { required: true };

      setExtraMaterials([...(extraMaterials || []), file]);
    }
  };

  const removeExtraMaterial = (extraMaterialToBeRemoved) => {
    if (extraMaterialToBeRemoved.alreadySaved) {
      setExtraMaterialsToBeDeleted([
        ...(extraMaterialsToBeDeleted || []),
        extraMaterialToBeRemoved,
      ]);
    }

    let localExtraMaterials = [...(extraMaterials || [])];
    localExtraMaterials = localExtraMaterials.filter(
      (extraMaterial) => extraMaterial !== extraMaterialToBeRemoved
    );

    setExtraMaterials(localExtraMaterials);
  };

  const onChangeSwitchState = () => {
    setSwitchState(switchState === "video" ? "scorm" : "video");
    setReference("");
  };

  const handleChangeExtraMaterial = (extraMaterial) => {
    const materials = extraMaterials.map(material => {
      if (material.id === extraMaterial.id) {
        material.tags.required = !material.tags.required;
        material.isChange = !material.isChange;

        return material;
      }

      return material;
    });

    setExtraMaterials(materials)
  }

  return (
    <CreateAndEditContentContainer>
      <Breadcrumb title={isEditting ? "Editar Aula" : "Adicionar Aula"} parent="Aula" />
      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label htmlFor="title" className="required">
            Título
          </label>
          <DefaultInput
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            id="title"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="description" className="required">
            Descrição
          </label>
          <DefaultTextArea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            id="description"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <GroupSelectButtons>
            <label htmlFor="reference" className="required">
              Conteúdo
            </label>
            <DefaultSwitchScorm
              switchState={switchState}
              onChangeSwitchState={onChangeSwitchState}
            />
          </GroupSelectButtons>

          <ContentUploadOrSelectContainer>
            {switchState === "video" ? (
              <button
                className="btn btn-primary mr-2"
                type="button"
                onClick={() => {
                  setShouldShowSelectVideoModal(true);
                }}
              >
                Selecionar Vídeo
              </button>
            ) : (
              <ScormCourses
                setReference={setReference}
                setIsScormVersion12={setIsScormVersion12}
              />
            )}

            {reference ? (
              <iframe
                title="referenced-video"
                allowFullScreen
                src={reference}
                frameBorder={0}
              ></iframe>
            ) : (
              <></>
            )}
          </ContentUploadOrSelectContainer>
        </DefaultCreationFormGroup>

        <div className="form-group row w-100">
          <label htmlFor="support-materials" className="col-sm-2">
            Materiais de apoio
          </label>

          <div className="col-md-10 border p-0">
            <input
              type="file"
              className="form-control mb-2"
              id="support-materials"
              onChange={e => addExtraMaterial(e)}
            />

            <div className="d-flex justify-content-start flex-column">
              {extraMaterials && !!extraMaterials.length
                && extraMaterials.map((extraMaterial, index) => (
                  <div
                    key={index}
                    className={`bg-info rounded m-2 d-flex overflow-hidden`}
                  >
                    <p className="text-white font-weight-bold ml-0 mt-auto mb-auto mr-auto p-2"
                      style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      {extraMaterial.name}
                    </p>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <button
                        onClick={() =>
                          removeExtraMaterial(extraMaterial)
                        }
                        className="btn btn-sm p-1 d-flex justify-content-center align-items-center"
                        style={{ background: "none", color: "white" }}
                        type="button"
                      >
                        <Trash size={20} />
                      </button>

                      {extraMaterial.reference && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={extraMaterial.reference}
                          className="btn btn-xs mt-1 p-1 btn-success d-flex justify-content-center align-items-center"
                        >
                          <Download size={14} />
                        </a>
                      )}
                    </div>
                    <div className="d-flex align-items-center form-check pr-2 pl-2 rounded"
                      style={{ backgroundColor: "#0086cc" }}>
                      <input
                        className="mr-1"
                        type="checkbox"
                        defaultChecked={extraMaterial.tags.required}
                        id={`flexCheckChecked-${index}`}
                        onClick={() => handleChangeExtraMaterial(extraMaterial, index)}
                      />
                      <label className="form-check-label d-flex" htmlFor={`flexCheckChecked-${index}`}>
                        Obrigatório
                      </label>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div className="isrequired">
          <span>*</span>
          <label>Campos obrigatórios</label>
        </div>
        <DefaultCreationFormButtonGroup>
          <button
            type="button"
            className="btn btn-secondary mr-2"
            onClick={goToContents}
          >
            Cancelar
          </button>
          <button
            type="button"
            onClick={(e) => (isEditting ? updateContent(e) : createContent(e))}
            className="btn btn-primary"
          >
            Salvar
          </button>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
      {shouldShowSelectVideoModal && (
        <SelectVideoModal
          setShouldShowSelectVideoModal={setShouldShowSelectVideoModal}
          selectContent={selectContent}
        />
      )}
    </CreateAndEditContentContainer>
  );
};

export default CreateAndEditContent;

import { convertCategoryFromDB } from '../helpers/converters/categoryConverter';
import httpClient from '../http-client';

const getCategories = async () => {
  let categories = (await httpClient.get(`/categories`)).data;

  if (categories && categories.length) {
    categories = categories.map(convertCategoryFromDB);
  }

  return categories;
};

const getCategory = async categoryId => {
  if (!categoryId) {
    throw new Error('O id da categoria é obrigatório para obter seus dados!');
  }

  let category = (await httpClient.get(`/categories/${categoryId}`)).data;

  return category;
};

const createCategory = async newCategory => {
  const createCategoryResponse = (
    await httpClient.post(`/categories/`, newCategory)
  ).data;

  return createCategoryResponse;
};

const updateCategory = async (categoryId, newCategory) => {
  if (!categoryId) {
    throw new Error('O id da categoria é obrigatório para a edição!');
  }

  if (!newCategory || !Object.keys(newCategory).length) {
    throw new Error('Os dados para atualização da categoria são obrigatórios!');
  }

  const categoryUpdateResponse = (
    await httpClient.put(`/categories/${categoryId}`, newCategory)
  ).data;

  return categoryUpdateResponse;
};

const deleteCategory = async categoryId => {
  if (!categoryId) {
    throw new Error('O id da categoria é obrigatório para a exclusão!');
  }

  const categoryDeleteResponse = (
    await httpClient.delete(`/categories/${categoryId}`)
  ).data;

  return categoryDeleteResponse;
};

const activateOrInactivateCategory = async (categoryId, active) => {
  if (!categoryId) {
    throw new Error(
      'O id da categoria é obrigatório para a ativa-la ou inativa-la!',
    );
  }

  const activateCategoryResponse = (
    await httpClient.put(`/categories/${categoryId}/active`, {
      is_active: active,
    })
  ).data;

  return activateCategoryResponse;
};

const getCategoriesForSelect = async () => {
  let categories = (await httpClient.get('/categories/')).data;

  categories =
    categories &&
    categories.length &&
    categories.map(category => ({
      label: category.title,
      value: category.category_id,
    }));

  return categories;
};

export {
  getCategories,
  getCategory,
  createCategory,
  updateCategory,
  deleteCategory,
  activateOrInactivateCategory,
  getCategoriesForSelect
};

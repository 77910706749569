import React, { useEffect, useMemo, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Eye, Search, Check, X, Trash } from 'react-feather';
import Datatable from '../common/datatable';
import {
  deleteReviewService,
  getReviews,
  visibleReviewService,
} from '../../services/reviews';
import { AiFillStar, AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import getErrorMessage from '../../helpers/get-error-message';
import Swal from 'sweetalert2';

const ReviewsTrails = () => {
  const [trails, setTrails] = useState([]);
  const [search, setSearch] = useState('');

  const refreshCourses = async event => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    const localCourses = await getReviews();
    if (localCourses && localCourses.length) {
      setTrails(localCourses);
    }
  };

  const hiddenReview = (trailId, userId, isVisible) => {
    Swal.fire({
      icon: 'question',
      title: isVisible ? 'Esconder Depoimento' : 'Mostrar Depoimento',
      text: isVisible
        ? 'Tem certeza que deseja esconder esta depoimento?'
        : 'Tem certeza que deseja mostrar esta depoimento?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await visibleReviewService(trailId, userId);
          await refreshCourses();
        } catch (error) {
          const errorMessage = getErrorMessage(error);
          Swal.fire({ title: 'Erro', icon: 'error', text: errorMessage });
        }
      }
    });
  };

  const deleteReview = async trailId => {
    Swal.fire({
      icon: 'warning',
      title: 'Remover Depoimento',
      text: 'Tem certeza que deseja remover esta depoimento?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await deleteReviewService(trailId);
          await refreshCourses();
        } catch (error) {
          const errorMessage = getErrorMessage(error);
          Swal.fire({ title: 'Erro', icon: 'error', text: errorMessage });
        }
      }
    });
  };

  const trailsToBeShown = useMemo(() => {
    return trails.map(course => {
      return {
        Disciplina: course.trail_name,
        Autor: course.name,
        Depoimento: course.description,
        Visivel: course.show_to_all_users ? (
          <Check color="green" />
        ) : (
          <X color="red" />
        ),
        Avaliação: Array(5)
          .fill('')
          .map((_, index) => (
            <AiFillStar
              size={24}
              key={index}
              color={
                index + 1 <=
                (+course.stars % 1 >= 0.5
                  ? Math.ceil(+course.stars)
                  : Math.floor(+course.stars))
                  ? '#FCC201'
                  : '#333'
              }
            />
          )),
        Ações: (
          <>
            <button
              className="btn btn-xs btn-info"
              onClick={() =>
                hiddenReview(
                  course.trail_id,
                  course.user_id,
                  course.show_to_all_users,
                )
              }
              title={
                course.show_to_all_users
                  ? 'Depoimento visível'
                  : 'Depoimento escondido'
              }
            >
              {course.show_to_all_users ? (
                <AiFillEye size={18} />
              ) : (
                <AiFillEyeInvisible size={18} />
              )}
            </button>

            <button
              className="btn btn-xs btn-danger ml-2"
              onClick={() => deleteReview(course.trail_id)}
              title="Deletar review"
            >
              <Trash size={18} />
            </button>
          </>
        ),
      };
    });
  }, [trails]);

  useEffect(() => {
    refreshCourses();
  }, []);

  return (
    <>
      <Breadcrumb title="Avaliações" parent="Disciplinas" />
      <div className="container-fluid">
        <div className="container my-3">
          <form className="row" onSubmit={refreshCourses}>
            <div className="col-md-10">
              <input
                className="form-control"
                placeholder="Pesquisar..."
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
            </div>
            <div className="col-md-2">
              <button className="btn btn-primary">
                <Search size={18} />
              </button>
            </div>
          </form>
        </div>

        <Datatable
          myData={trailsToBeShown}
          pageSize={10}
          pagination={true}
          class="-striped -highlight"
        />
      </div>
    </>
  );
};

export default ReviewsTrails;

import React, { useEffect, useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumb from '../common/breadcrumb';
import {
  getCategories as getCategoriesService,
  deleteCategory,
  activateOrInactivateCategory,
} from '../../services/categories';
import Datatable from '../common/datatable';
import { Edit2, Trash2, Check, X } from 'react-feather';
import { toast } from 'react-toastify';


const ListarCategorias = () => {
  const [categories, setCategories] = useState([]);
  const history = useHistory();
  let refreshCategories = useCallback(() => {}, []);

  const getCategories = useCallback(async () => {
    let localCategories = await getCategoriesService();

    if (localCategories && localCategories.length) {
      localCategories = localCategories.map(category => {
        const categoryId = category.id;
        const isActive = category.active;

        category['ações'] = (
          <>
            <button
              type="button"
              className="btn btn-xs btn-primary mr-1"
              title="Editar Categoria"
              onClick={() => {
                history.push(`/categorias/editar-categoria/${categoryId}`);
              }}
            >
              <Edit2 size={18} />
            </button>
            <button
              type="button"
              className="btn btn-xs btn-danger mr-1"
              title="Excluir categoria"
              onClick={async () => {
                if (
                  window.confirm(
                    'Tem certeza que deseja remover este categoria?',
                  )
                ) {
                  try {
                    await deleteCategory(categoryId);

                    toast.success('categoria removida com sucesso!');

                    await refreshCategories();
                  } catch (e) {
                    toast.error('Erro ao remover categoria. ' + e.message);
                  }
                }
              }}
            >
              <Trash2 size={18} />
            </button>

            <button
              type="button"
              className="btn btn-xs btn-warning"
              title={`${isActive ? 'Inativar' : 'Ativar'} categoria`}
              onClick={async () => {
                if (
                  window.confirm(
                    `Tem certeza que deseja ${
                      isActive ? 'inativar' : 'ativar'
                    } este categoria?`,
                  )
                ) {
                  try {
                    await activateOrInactivateCategory(categoryId, !isActive);

                    toast.success(
                      `Categoria ${
                        isActive ? 'inativado' : 'ativado'
                      } com sucesso!`,
                    );

                    await refreshCategories();
                  } catch (e) {
                    toast.error(
                      `Erro ao ${isActive ? 'inativar' : 'ativar'} categoria. ${
                        e.message
                      }`,
                    );
                  }
                }
              }}
            >
              {isActive ? <X size={18} /> : <Check size={18} />}
            </button>
          </>
        );

        delete category.id;
        delete category.active;

        return category;
      });
    }

    setCategories(localCategories);
  }, [refreshCategories, history]);

  refreshCategories = useCallback(() => {
    (async () => {
      setCategories([]);
      await getCategories();
    })();
  }, [getCategories]);

  useEffect(() => {
    (async () => {
      await getCategories();
    })();
  }, [getCategories]);

  return (
    <>
      <Breadcrumb title="Listar de Categorias" parent="Categorias" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Lista de Categorias</h5>
              </div>
              <div className="card-body">
                <div className="btn-popup pull-right">
                  <Link
                    to={`${process.env.PUBLIC_URL}/categorias/criar-categoria`}
                    className="btn btn-primary"
                  >
                    Criar Categoria
                  </Link>
                </div>
                <div className="clearfix"></div>
                <div
                  id="batchDelete"
                  className="category-table order-table coupon-list-delete"
                >
                  {categories && categories.length ? (
                    <Datatable
                      myData={categories}
                      pageSize={10}
                      pagination={true}
                      class="-striped -highlight"
                    />
                  ) : (
                    <div className="d-flex justify-content-center align-items-center">
                     <span>{" "}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListarCategorias;

import styled from "styled-components";

const CreateAndEditContentContainer = styled.div``;

const ContentUploadOrSelectContainer = styled.div`
  border-radius: 5px;
  border: solid 1px #ddd;;
  padding: 10px 15px;
  width: 100%;

  iframe {
    margin-top: 10px;
    width: 600px;
    height: 340px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ThumbnailUploadContainer = styled.div`
  border-radius: 5px;
  border: solid 1px #ddd;;
  padding: 10px 15px;
  width: 100%;

  img {
    margin-top: 10px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ContentThumbnail = styled.img`
  max-width: 600px;
`;

const Switch = styled.label`
  position: relative;
  display: inline-block;
  min-width: 60px !important;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #1d2680;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

const ExtraMaterialList = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ExtraMaterial = styled.div`
  width: 100%;
  border-radius: 4px;
  border: solid 1px #ddd;;
  padding: 5px;
  display: flex;
  align-items: center;

  h5 {
    flex: 1 1 100%;
  }

  button,
  a {
    outline: none;
    cursor: pointer;
    padding: 3px 6px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 5px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 8%), 0px 4px 6px rgb(50 50 93 / 10%);
    background: white;
    color: #444;
    border: solid 1px #ddd;;
    margin-left: 10px;
  }

  button svg {
    color: rgb(245, 73, 21);
  }

  a svg {
    color:#1d2680;
  }
`;

const ExtraMaterialsInput = styled.input`
  padding: 10px;
  border: 1px solid #ddd;;
  width: 100%;
  border-radius: 5px;
`;

const GroupSelectButtons = styled.div`
  display: flex;
  flex-direction : column;
  gap: 5px;
`;

const DefaultButton = styled.button`
  background: #1d2680;
  color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 15px 20px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08), 0px 4px 6px rgba(50, 50, 93, 0.1);

  &:hover {
    background: #2b3391;
  }

  &.danger {
    background:rgb(245, 73, 21);
  }

  &.success {
    background: #3779c5;
  }

  &.info {
    background:#66b647;
  }

  &.white {
    background: white;
    color: #444;
    border: solid 1px #ddd;;
  }

  &.warning {
    background: #ffc107;
    color: black;
  }

  &.small {
    padding: 3px 6px;
  }

  &.medium {
    padding: 10px;
    max-height: 60px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;


const DefaultCreationForm = styled.form`
  & > :not(:last-child) {
    margin-bottom: 10px;
  }

  & .react-datepicker-wrapper {
    flex-grow: 1;

    input {
      width: 100%;
      height: 40px;
      border-radius: 5px;
      border: solid 1px #ddd;;
      padding: 10px 15px;
    }
  }

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
`;

const DefaultCreationFormGroup = styled.div`
  width: 100%;

  & + div {
    margin-top: 16px;
  }

  label {
    min-width: 145px;
    margin-bottom: 12px;
    font-size: 12px;
    color: rgb(74, 88, 106);
    font-weight: 500;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

const DefaultCreationFormButtonGroup = styled.div`
  width: 100%;
  margin: 10px 0;

  & > :not(:last-child) {
    margin-right: 10px;
  }

  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const DefaultInput = styled.input`
  border-radius: 5px;
  border: solid 1px #ddd;;
  outline: none;
  font-size:14px;
  width: 100%;
  height: 44px;
  padding: 0 10px;
  color:#444444;
  background-color: #fdfdfd; 
  margin-top: 8px;
`;

const DefaultPageTitle = styled.h2`
  font-size: var(--font-medium);
  margin-bottom: 30px;
  border-bottom: solid 1.2px rgba(0, 0, 0, 0.1);
  color: black;
  font-weight: 400;
  padding-bottom: 10px;
`;


const DefaultTextArea = styled.textarea`
  border-radius: 5px;
  border: solid 1px #ddd;;
  outline: none;
  padding: 10px 15px;
  font-size: 12px;
  color: black;
  background-color: #fdfdfd;
  width: 100%;
  resize: vertical;
`;


export {
  CreateAndEditContentContainer,
  ContentUploadOrSelectContainer,
  ThumbnailUploadContainer,
  ContentThumbnail,
  Switch,
  ExtraMaterial,
  ExtraMaterialList,
  ExtraMaterialsInput,
  GroupSelectButtons,
  DefaultButton,
  DefaultCreationFormGroup,
  DefaultCreationFormButtonGroup,
  DefaultCreationForm,
  DefaultInput,
  DefaultPageTitle,
  DefaultTextArea

};

import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Breadcrumb from '../common/breadcrumb';
import {
  getAboutUs as getAboutUsService,
  deleteBanner as deleteAboutUsService,
  createBanner as createAboutService,
  updateBanner as updateAboutService,
  uploadImage as uploadImageService,
} from '../../services/banner';
import Swal from 'sweetalert2';
import checkEmptyString from '../../util/checkEmptyString';

const ListAbout = () => {
  const [about, setAbout] = useState({});
  const [description, setDescription] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [thumbnailToUpload, setThumbnailToUpload] = useState();

  const fileInput = useRef(null);

  const getAboutUs = useCallback(async () => {
    let data = await getAboutUsService();
    setAbout(data);
    setDescription(data.description);
    setThumbnail(data.image);
  }, []);

  useEffect(() => {
    getAboutUs();
  }, []);

  const hasEmptyFields = () => {
    if (checkEmptyString(description) || thumbnail === '') {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha todos os campos!',
        icon: 'error',
      });

      return true;
    }

    return false;
  };

  const submit = async event => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    if (hasEmptyFields()) {
      return;
    }

    if (!about || !Object.keys(about).length) {
      try {
        const createdAbout = await createAboutService(description, 'about_us');

        if (thumbnailToUpload) {
          const formData = new FormData();
          formData.append('file', thumbnailToUpload);
          formData.append('type', thumbnailToUpload.type);

          await uploadImageService(createdAbout.home_info_id, formData);
        }

        Swal.fire({
          title: 'Sucesso!',
          text: 'Sobre criado com sucesso!',
          icon: 'success',
        });
      } catch (error) {
        Swal.fire({
          title: 'Erro',
          text: 'Erro ao criar sobre. ' + error.message,
          icon: 'error',
        });
      }

      return;
    }

    try {
      const updatedAbout = await updateAboutService(
        about.home_info_id,
        'about_us',
        null,
        description,
      );

      if (thumbnailToUpload) {
        const formData = new FormData();
        formData.append('file', thumbnailToUpload);
        formData.append('type', thumbnailToUpload.type);

        await uploadImageService(updatedAbout.home_info_id, formData);
      }

      Swal.fire({
        title: 'Sucesso!',
        text: 'Sobre editado com sucesso!',
        icon: 'success',
      });
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Erro ao editar sobre. ' + error.message,
        icon: 'error',
      });
    }
  };

  const uploadImage = useCallback(() => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  });

  const handleFile = event => {
    if (event.currentTarget.files && event.currentTarget.files.length) {
      const file = event.currentTarget.files[0];

      try {
        if (file) {
          if (file.type && !file.type.includes('image')) {
            throw new Error('Arquivo inválido selecionado!');
          }

          setThumbnailToUpload(file);

          let fr = new FileReader();

          fr.onloadend = () => {
            setThumbnail(`${fr.result}`);
          };

          fr.readAsDataURL(file);
        }
      } catch (e) {
        Swal.fire({
          title: 'Erro',
          text:
            'Ocorreu um erro ao carregar a imagem selecionada. ' + e.message,
          icon: 'error',
        });
      }
    }
  };

  return (
    <>
      <Breadcrumb title="Listar Sobre Nós" parent="Sobre Nós" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Sobre Nós</h5>
              </div>

              <form onSubmit={submit} className="p-3">
                <div className="mb-3">
                  <label htmlFor="description" className="form-label">
                    Descrição
                  </label>
                  <textarea
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    id="description"
                    className="form-control"
                  ></textarea>
                </div>
                <div className="mb-3">
                  <label htmlFor="description" className="form-label">
                    Imagem
                  </label>
                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <button
                      onClick={uploadImage}
                      id="description"
                      type="button"
                      className="btn btn-primary mb-2"
                    >
                      Selecionar Imagem
                    </button>

                    {thumbnail && (
                      <img
                        style={{ maxWidth: '400px', maxHeight: '400px' }}
                        src={thumbnail}
                        alt="thumbnail"
                      />
                    )}
                  </div>
                  <input
                    onChange={handleFile}
                    ref={fileInput}
                    type="file"
                    style={{ display: 'none' }}
                  />
                </div>
                <div className="mb-3 w-100 d-flex justify-content-end align-items-center">
                  <button type="submit" className="btn btn-primary">
                    Salvar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListAbout;

import React, { useEffect, useMemo, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import Datatable from '../common/datatable';
import {
  getUsers as getUsersService,
  toogleAdminPrivileges as toogleAdminPrivilegesService,
} from '../../services/users';
import { Plus, X } from 'react-feather';
import getErrorMessage from '../../helpers/get-error-message';
import Swal from 'sweetalert2';

const UsuariosAdmin = () => {
  const [users, setUsers] = useState([]);

  const getUsers = async () => {
    const localUsers = await getUsersService();
    setUsers(localUsers);
  };

  const toogleAdminPrivileges = async (userId, isAdmin) => {
    Swal.fire({
      text: `Tem certeza que deseja ${
        isAdmin ? 'remover' : 'dar'
      } privilégios de administrador a este usuário?`,
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await toogleAdminPrivilegesService(userId);
          await getUsers();
        } catch (error) {
          const erroMessage = getErrorMessage(error);
          Swal.fire({
            icon: 'error',
            text:
              `Erro ao ${
                isAdmin ? 'remover' : 'dar'
              } privilégios de administrador ao usuário. ` + erroMessage,
          });
        }
      }
    });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const usersToBeShown = useMemo(() => {
    if (users && users.length) {
      const usersEdited = users.map((user) => {
       return  {...user, name: user.name.includes("vallourec_vlrsa") ? user.name.slice(16) : user.name}}
       )
       function compare( a, b ) {
      if ( a.name.toLowerCase() < b.name.toLowerCase() ){
    return -1;
      }
      if ( a.name.toLowerCase() > b.name.toLowerCase() ){
    return 1;
      }
  return 0;
}
usersEdited.sort(compare)
      return usersEdited.map(user => ({
        nome: user.name,
        email: user.email,
        registro: user.registration,
        status: user.is_admin ? 'Administrador' : 'Usuário',
        ações: (
          <>
            {!user ||
              (!user.is_admin && (
                <button
                  className="btn btn-xs btn-primary"
                  onClick={() => toogleAdminPrivileges(user.user_id, false)}
                >
                  <Plus />
                </button>
              ))}
            {user && user.is_admin && (
              <button
                className="btn btn-xs btn-danger"
                onClick={() => toogleAdminPrivileges(user.user_id, true)}
              >
                <X />
              </button>
            )}
          </>
        ),
      }));
    }

    return [];
  }, [users]);

  return (
    <>
      <Breadcrumb title="Listar Usuários" parent="Usuários" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Lista de Usuários</h5>
              </div>
              <div className="card-body">
                <div className="clearfix"></div>
                <div
                  id="batchDelete"
                  className="category-table order-table coupon-list-delete"
                >
                  {usersToBeShown && usersToBeShown.length ? (
                    <Datatable
                      multiSelectOption={false}
                      myData={usersToBeShown}
                      pageSize={10}
                      pagination={true}
                      class="-striped -highlight"
                    />
                  ) : (
                    <div
                      className="
                        d-flex 
                        justify-content-center 
                        align-items-center
                      "
                    >
                      <span>Nenhum usuário</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UsuariosAdmin;

import httpClient from '../http-client';
import download from 'downloadjs';

const generateCompletedCoursesReport = async (format, queryStrings) => {
  const reportResponse = await httpClient.get(
    `/reports/courses-finished/${format}`,
    {
      params: queryStrings,
      responseType: 'blob',
    },
  );

  const contentType = reportResponse.headers['content-type'];

  download(
    reportResponse.data,
    `relatório_cursos-completos.${format}`,
    contentType,
  );
};

const generateEngagementReport = async (format, queryStrings) => {
  const reportResponse = await httpClient.get(`/reports/engagement/${format}`, {
    params: queryStrings,
    responseType: 'blob',
  });

  const contentType = reportResponse.headers['content-type'];

  download(reportResponse.data, `relatório_engajamento.${format}`, contentType);
};

const generateViewedContentsReport = async (format, queryStrings) => {
  const reportResponse = await httpClient.get(`/reports/full-views/${format}`, {
    params: queryStrings,
    responseType: 'blob',
  });

  const contentType = reportResponse.headers['content-type'];

  download(
    reportResponse.data,
    `relatório_conteudos_visualizados.${format}`,
    contentType,
  );
};

const generateFavoriteCoursesReport = async format => {
  const reportResponse = await httpClient.get(
    `/reports/course-book-marks/${format}`,
    {
      responseType: 'blob',
    },
  );

  const contentType = reportResponse.headers['content-type'];

  download(
    reportResponse.data,
    `relatório_cursos_favoritos.${format}`,
    contentType,
  );
};

const generateRegisteredTrailsReport = async format => {
  const reportResponse = await httpClient.get(
    `/reports/registered-trails/${format}`,
    {
      responseType: 'blob',
    },
  );

  const contentType = reportResponse.headers['content-type'];

  download(
    reportResponse.data,
    `relatório_disciplinas_cadastradas.${format}`,
    contentType,
  );
};
const generateCoursesInProgressReport = async format => {
  const reportResponse = await httpClient.get(
    `/reports/courses-in-progress/${format}`,
    {
      responseType: 'blob',
    },
  );

  const contentType = reportResponse.headers['content-type'];

  download(
    reportResponse.data,
    `relatório_cursos_em_andamento.${format}`,
    contentType,
  );
};
const generateFinishedTrailsReport = async (format, filters) => {
  const reportResponse = await httpClient.get(
    `/reports/finished-trails/${format}`,
    {
      responseType: 'blob',
      params: filters
    },
  );

  const contentType = reportResponse.headers['content-type'];

  download(
    reportResponse.data,
    `relatório_disciplinas_finalizadas.${format}`,
    contentType,
  );
};

const generateFullViewsReport = async (format, filters) => {
  const reportResponse = await httpClient.get(
    `/reports/full-views/${format}`,
    {
      responseType: 'blob',
      params: filters
    },
  );

  const contentType = reportResponse.headers['content-type'];

  download(
    reportResponse.data,
    `relatório_visualizacoes_completas.${format}`,
    contentType,
  );
};

const generateSingleCourseReport = async (format, filters) => {
  const reportResponse = await httpClient.get(
    `/reports/single-course/${format}`,
    {
      responseType: 'blob',
      params: filters
    },
  );

  const contentType = reportResponse.headers['content-type'];

  download(
    reportResponse.data,
    `relatório_cursos_avulsos_finalizados.${format}`,
    contentType,
  );
};

export {
  generateCompletedCoursesReport,
  generateEngagementReport,
  generateViewedContentsReport,
  generateFavoriteCoursesReport,
  generateRegisteredTrailsReport,
  generateCoursesInProgressReport,
  generateFinishedTrailsReport,
  generateFullViewsReport,
  generateSingleCourseReport
};

import httpClient from '../http-client';
import { convertContentFromDB } from '../helpers/converters/contentConverter';

const getContents = async (filters) => {
  let contents = (await httpClient.get(`/contents`, { params: filters })).data;

  contents = contents.filter(item => item && item.type && (item.type.toUpperCase() === 'VIDEO' || item.type.toUpperCase() === 'SCORM'));
  if (contents && contents.length) {
    contents = contents.map(convertContentFromDB);
  }

  return contents;
};

const getContentsForSelect = async fileType => {
  let contents = (
    await httpClient.get(`/contents`, { params: { type: fileType } })
  ).data;

  if (contents && contents.length) {
    contents = contents.map(content => ({
      label: content.title,
      value: content.content_id,
    }));
  }

  return contents;
};

const getContent = async contentId => {
  if (!contentId) {
    throw new Error('O id do conteúdo é obrigatório para obter seus dados!');
  }

  let content = (await httpClient.get(`/contents/${contentId}`)).data;

  return content;
};

const deleteContent = async contentId => {
  if (!contentId) {
    throw new Error('O id do conteúdo é obrigatório para a exclusão!');
  }

  const contentDeleteResponse = (
    await httpClient.delete(`/contents/${contentId}`)
  ).data;

  return contentDeleteResponse;
};

const updateContent = async (contentId, newContent) => {
  if (!contentId) {
    throw new Error('O id do conteúdo é obrigatório para a edição!');
  }

  if (!newContent || !Object.keys(newContent).length) {
    throw new Error('Os dados para atualização do conteúdo são obrigatórios!');
  }

  const contentUpdateResponse = (
    await httpClient.put(`/contents/${contentId}`, {
      ...newContent,
      is_library: false,
    })
  ).data;

  return contentUpdateResponse;
};

const updateDocument = async (contentId, newContent) => {
  if (!contentId) {
    throw new Error('O id do conteúdo é obrigatório para a edição!');
  }

  if (!newContent || !Object.keys(newContent).length) {
    throw new Error('Os dados para atualização do conteúdo são obrigatórios!');
  }

  const contentUpdateResponse = (
    await httpClient.put(`/contents/${contentId}`, {
      ...newContent,
      type: 'file',
      is_library: true,
    })
  ).data;

  return contentUpdateResponse;
};

const createContent = async (newContent) => {
  if (!newContent || !Object.keys(newContent).length) {
    throw new Error('Os dados para criação do conteúdo são obrigatórios!');
  }
  const createContentResponse = (
    await httpClient.post(`/contents`, newContent)
  ).data;

  return createContentResponse;
};

const createDocument = async newContent => {
  if (!newContent || !Object.keys(newContent).length) {
    throw new Error('Os dados para criação do conteúdo são obrigatórios!');
  }

  const createContentResponse = (
    await httpClient.post(
      `/contents
  `,
      { ...newContent, type: 'file', is_library: true },
    )
  ).data;

  return createContentResponse;
};

const activateOrInactivateContent = async (contentId, active) => {
  if (!contentId) {
    throw new Error('O id do conteúdo é obrigatório.');
  }

  const activateOrInactivateContentResponse = (
    await httpClient.put(`/contents/${contentId}/active`, { is_active: active })
  ).data;

  return activateOrInactivateContentResponse;
};

const getSignedUrl = async (contentType, path) => {
  return (await httpClient.post('files/url-signed', {
    content_type: contentType,
    path: `scorm/vallourec/${path}`
  })).data;
};

export {
  getContents,
  getContent,
  deleteContent,
  updateContent,
  createContent,
  getContentsForSelect,
  activateOrInactivateContent,
  createDocument,
  updateDocument,
  getSignedUrl
};

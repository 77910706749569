import React from 'react';
import { Check, X } from 'react-feather';

const convertDocumentFromDB = (document) => (
  {
    'id': document.content_id,
    'titulo': document.title,
    'descrição': document.description,
    'url': <a target="_blanc" href={document.reference}>{document.reference}</a>,
    'ativo': document.is_active
      ? <Check size={16} />
      : <X size={16} />,
    'is_active': document.is_active
  }
);

export {
  convertDocumentFromDB
} 

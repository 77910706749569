import React from 'react';
import Breadcrumb from '../common/breadcrumb';
import Tabset from './tabset-editar-prova';

const EditarQuestoes = () => {
  return (
    <>
      <Breadcrumb title="Editar Prova" parent="Provas" />
      <div className="container-fluid">
        <div className="card">
          <div className="card-header">
            <h5>Editar Prova</h5>
          </div>
          <div className="card-body">
            <Tabset />
          </div>
        </div>
      </div>
    </>
  );
};

export default EditarQuestoes;

import React, { useEffect, useState, useMemo } from 'react';
import Breadcrumb from '../common/breadcrumb';
import {
  getCategories as getCategoriesService,
  deleteCategory as deleteCategoryService,
} from '../../services/forum';
import Datatable from '../common/datatable';
import { useHistory } from 'react-router';
import { Edit2, Trash } from 'react-feather';
import Swal from 'sweetalert2';
import getErrorMessage from '../../helpers/get-error-message';

const ForumCategories = () => {
  const history = useHistory();
  const [categories, setCategories] = useState([]);

  const editCategory = categoryId => {
    history.push(
      `${process.env.PUBLIC_URL}/forum/categorias/criar-editar-categoria/${categoryId}`,
    );
  };

  const deleteCategory = async categoryId => {
    Swal.fire({
      icon: 'question',
      text: 'Tem certeza que deseja excluir esta categoria?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await deleteCategoryService(categoryId);
          getCategories();
        } catch (error) {
          const errorMessage = getErrorMessage(error);
          Swal.fire({
            icon: 'error',
            text: 'Erro ao excluir categoria. ' + errorMessage,
          });
        }
      }
    });
  };

  const getCategories = async () => {
    setCategories([]);
    const localCategories = await getCategoriesService();
    setCategories(localCategories);
  };

  const createCategory = () => {
    history.push(
      `${process.env.PUBLIC_URL}/forum/categorias/criar-editar-categoria`,
    );
  };

  const categoriesToBeShown = useMemo(() => {
    return (categories || []).map(category => ({
      descrição: category.description,
      data: category.created_at
        ? new Intl.DateTimeFormat('pt-BR').format(new Date(category.created_at))
        : '',
      ações: (
        <>
          <button
            onClick={() => editCategory(category.category_id)}
            type="button"
            className="btn btn-xs btn-primary mr-1"
          >
            <Edit2 size={16} />
          </button>
          <button
            onClick={() => deleteCategory(category.category_id)}
            type="button"
            className="btn btn-xs btn-danger"
          >
            <Trash size={16} />
          </button>
        </>
      ),
    }));
  }, [categories]);

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      <Breadcrumb title="Categorias de Fórum" parent="Fórum" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="pull-right mb-2">
              <button className="btn btn-primary" onClick={createCategory}>
                Criar Categoria
              </button>
            </div>
          </div>
          <div className="col-sm-12">
            {categories && categories.length ? (
              <Datatable
                myData={categoriesToBeShown}
                pageSize={10}
                pagination={true}
                class="-striped -highlight"
              />
            ) : (
              <div
                className="
                  d-flex
                  justify-content-center
                  align-items-center
                "
              >
                <span>Não há Categorias</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ForumCategories;

import React from 'react';
import { Check, X } from 'react-feather';

const convertTrailFromDB = (trail) => {
  return {
    'capa': (<img src={trail.thumbnail} style={{ width: 80, height: 80 }} alt="capa"/>),
    'id': trail.trail_id,
    'nome': trail.name,
    'descrição': trail.description,
    'is_active': trail.is_active,
    'ativo': trail.is_active ? <Check /> : <X />
  }
};

export {
  convertTrailFromDB
}


import httpClient from "../http-client";

const getContent = async (contentId) => {
  const content = (await httpClient.get(`contents/${contentId}`)).data;
  return content;
};

const createContent = async (newContent, isLibrary) => {
  if (!newContent || !Object.keys(newContent).length) {
    throw new Error('Os dados para criação do conteúdo são obrigatórios!');
  }
  const createContentResponse = (
    await httpClient.post(`/contents`, {
      ...newContent,
      type: newContent.type.toLowerCase(),
      is_library: isLibrary || false,
    })
  ).data;

  return createContentResponse;
};

const updateContent = async (contentId, newContent) => {
 const contentUpdateResponse = (
    await httpClient.put(`/contents/${contentId}`, {
      ...newContent,
      is_library: false,
    })
  ).data;

  return contentUpdateResponse;
};

export {
  updateContent,
  createContent,
  getContent,
};

import httpClient from "../http-client";

const getSignedUrl = async (contentType,  filePath) => {
  return (

    await httpClient.post("/files/url-signed", {

      content_type: contentType,
      path: `scorm/vallourec/${filePath}`,
    })
  ).data;
};


export default getSignedUrl ;
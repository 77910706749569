import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './index.scss';
import App from './components/app';
import { ScrollContext } from 'react-router-scroll-4';
import { ToastContainer } from 'react-toastify';

// Components

import Datatable from './components/common/datatable';

import CreateCategoria from './components/categorias/criar-categoria';
import EditarCategoria from './components/categorias/editar-categoria';
import ListCategorias from './components/categorias/listar-categorias';

import CreateCurso from './components/cursos/criar-curso';
import EditCurso from './components/cursos/editar-curso';
import ListCursos from './components/cursos/listar-cursos';

import CreateCursoAvulso from './components/cursos-avulsos/criar-curso';
import EditCursoAvulso from './components/cursos-avulsos/editar-curso';
import ListCursosAvulsos from './components/cursos-avulsos/listar-cursos';

import ListContents from './components/conteudos/listar-conteudos';

import EditDocument from './components/documentos/editar-documento';
import CreateDocument from './components/documentos/criar-documento';
import ListDocuments from './components/documentos/listar-documentos';

import Banners from './components/home/listar-banner';
import About from './components/home/listar-sobre';
import CreateFaq from './components/faq/criar-faq';
import EditFaq from './components/faq/editar-faq';
import ListFaqs from './components/faq/listar-faqs';

import CreateTrail from './components/trilhas/criar-trilha';
import EditTrail from './components/trilhas/editar-trilha';
import ListTrails from './components/trilhas/listar-trilhas';

import CreateAuthors from './components/autores/criar-autor';
import EditAuthors from './components/autores/editar-autor';
import ListAuthors from './components/autores/listar-autores';

import VisualizacoesCompletas from './components/relatorios/visualizacoes-completas';
import Engajamento from './components/relatorios/engajamento';
import CursosFavoritos from './components/relatorios/cursos-favoritos';
import DisciplinasCadastradas from './components/relatorios/disciplinas-cadastradas';
import CursosAndamento from './components/relatorios/cursos-em-andamento';
import DisciplinasFinalizadas from './components/relatorios/disciplinas-finalizadas';

import Profile from './components/configuracoes/perfil';

import Loading from './components/Loading';

import EditVideo from './components/biblioteca/editar-video';
import CreateVideo from './components/biblioteca/criar-video';
import ListVideos from './components/biblioteca/listar-videos';

import CreateQuestions from './components/questoes/criar-questoes';
import EditQuestions from './components/questoes/editar-questoes';
import ListQuestions from './components/questoes/listar-questoes';

import CreateExams from './components/provas/criar-provas';
import ListExams from './components/provas/listar-provas';
import EditExams from './components/provas/editar-provas';
import ForumCategories from './components/forum-category';
import ForumTags from './components/forum-tag';
import CreateForumTag from './components/forum-tag/create-tag';
import CreateForumCategory from './components/forum-category/create-category';

import ListarLives from './components/lives';
import CriarEditarLive from './components/lives/criar-editar-live';
import UsuariosAdmin from './components/UsuariosAdmin';

import EditarTipoSegmentacao from './components/Segmentacao/editar-segmentacao';
import TiposSegmentacao from './components/Segmentacao';
import CreateAndEditContent from './components/CreateAndEditContent';
import ReviewsTrails from './components/trilhas/reviews-trilha';
import CursosAvulsos from './components/relatorios/cursos-avulsos';

const Root = () => {
  return (
    <>
      <BrowserRouter basename={'/'}>
        <ScrollContext>
          <Switch>
            <App>
              <Loading />
              <Route path={`/dashboard`} component={Profile} />

              <Route
                path={`/categorias/listar-categorias`}
                component={ListCategorias}
              />
              <Route
                path={`/categorias/criar-categoria`}
                component={CreateCategoria}
              />
              <Route
                path={`/categorias/editar-categoria/:categoryId`}
                component={EditarCategoria}
              />

              <Route path={`/cursos/listar-cursos`} component={ListCursos} />
              <Route
                path={`/cursos/editar-curso/:courseId`}
                component={EditCurso}
              />
              <Route path={`/cursos/criar-curso`} component={CreateCurso} />

              <Route
                path={`/cursos-avulsos/listar-cursos-avulsos`}
                component={ListCursosAvulsos}
              />
              <Route
                path={`/cursos-avulsos/editar-curso-avulso/:courseId`}
                component={EditCursoAvulso}
              />
              <Route
                path={`/cursos-avulsos/criar-curso-avulso`}
                component={CreateCursoAvulso}
              />

              <Route path={`/aulas/listar-aulas`} component={ListContents} />
              <Route
                path={`/aulas/criar-aula`}
                component={CreateAndEditContent}
              />
              <Route
                path={`/aulas/editar-aula/:contentId`}
                component={CreateAndEditContent}
              />

              <Route
                path={`/disciplinas/listar-disciplinas`}
                component={ListTrails}
              />
              <Route
                path={`/disciplinas/criar-disciplina`}
                component={CreateTrail}
              />

              <Route
                path={`/disciplinas/editar-disciplina/:trailId`}
                component={EditTrail}
              />

              <Route
                path={`/disciplinas/avaliacoes`}
                exact
                component={ReviewsTrails}
              />

              <Route
                path={`/biblioteca/videos/listar-videos`}
                component={ListVideos}
              />
              <Route
                path={`/biblioteca/videos/criar-video`}
                component={CreateVideo}
              />

              <Route
                path={`/biblioteca/videos/editar-video/:contentId`}
                component={EditVideo}
              />

              <Route
                path={`/biblioteca/documentos/listar-documentos`}
                component={ListDocuments}
              />
              <Route
                path={`/biblioteca/documentos/criar-documento`}
                component={CreateDocument}
              />

              <Route
                path={`/biblioteca/documentos/editar-documento/:contentId`}
                component={EditDocument}
              />

              <Route path={`/autores/listar-autores`} component={ListAuthors} />
              <Route path={`/autores/criar-autor`} component={CreateAuthors} />
              <Route
                path={`/autores/editar-autor/:authorId`}
                component={EditAuthors}
              />
              <Route path={`/relatorios/engajamento`} component={Engajamento} />
              <Route
                path={`/relatorios/visualizacoes-completas`}
                component={VisualizacoesCompletas}
              />
              <Route
                path={`/relatorios/cursos-favoritos`}
                component={CursosFavoritos}
              />

              <Route
                path={`/relatorios/disciplinas-cadastradas`}
                component={DisciplinasCadastradas}
              />
              <Route
                path={`/relatorios/disciplinas-finalizadas`}
                component={DisciplinasFinalizadas}
              />
              <Route
                path={`/relatorios/cursos-em-andamento`}
                component={CursosAndamento}
              />
              <Route
                path={`/relatorios/cursos-avulsos-finalizados`}
                component={CursosAvulsos}
              />

              <Route path={`/configuracoes/perfil`} component={Profile} />

              <Route path={`/data-table`} component={Datatable} />

              <Route path={`/faq/listar-faqs`} component={ListFaqs} />
              <Route path={`/faq/criar-faq`} component={CreateFaq} />

              <Route path={`/faq/editar-faq/:faqId`} component={EditFaq} />
              <Route
                path={`${process.env.PUBLIC_URL}/questoes/listar`}
                component={ListQuestions}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/questoes/criar`}
                component={CreateQuestions}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/questoes/editar/:id`}
                component={EditQuestions}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/home/listar-banner`}
                component={Banners}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/home/sobre`}
                component={About}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/faq/listar-faq`}
                component={ListFaqs}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/provas/criar`}
                component={CreateExams}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/provas/listar`}
                component={ListExams}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/provas/editar/:id`}
                component={EditExams}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/forum/categorias/`}
                exact
                component={ForumCategories}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/forum/categorias/criar-editar-categoria`}
                exact
                component={CreateForumCategory}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/forum/categorias/criar-editar-categoria/:categoryId`}
                exact
                component={CreateForumCategory}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/forum/tags/`}
                exact
                component={ForumTags}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/forum/tags/criar-editar-tag`}
                exact
                component={CreateForumTag}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/forum/tags/criar-editar-tag/:tagId`}
                exact
                component={CreateForumTag}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/lives/listar-lives`}
                exact
                component={ListarLives}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/lives/criar-live`}
                exact
                component={CriarEditarLive}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/lives/editar-live/:liveId`}
                exact
                component={CriarEditarLive}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/usuarios-admin/listar`}
                exact
                component={UsuariosAdmin}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/segmentacao/listar`}
                exact
                component={TiposSegmentacao}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/segmentacao/editar/:segmentationTypeId`}
                exact
                component={EditarTipoSegmentacao}
              />
            </App>
          </Switch>
        </ScrollContext>
      </BrowserRouter>

      <ToastContainer />
    </>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));

import React, { useEffect, useState } from 'react';
import { getContents } from '../../services/contents';

const SelectContents = ({ initialSelectedContents, onSelectContents }) => {
  const [contents, setContents] = useState([]);
  const [selectedContents, setSelectedContents] = useState([]);

  useEffect(() => {
    (async () => {
      let localContents = await getContents({ is_library: false, is_active: true });
      if (localContents && localContents.length) {
        localContents = localContents.map(lc => ({
          ...lc,
          title: lc.titulo,
          description: lc['descrição'],
        }));
        setContents(localContents);

        for (let content of initialSelectedContents) {
          const foundContent = localContents.find(c => c.id === content.id);
          if (foundContent) {
            selectedContents.push(foundContent);
          }
        }

        setSelectedContents([...selectedContents]);
      }
    })();
  }, []);

  return (
    <div className="container m-5 pt-3" style={{ height: '98%' }}>
      <div className="row">
        <div className="col-sm-12 pb-3 text-center">
          <button
            className="btn btn-primary"
            onClick={() => onSelectContents(selectedContents)}
          >
            Aplicar Seleção
          </button>
        </div>
      </div>
      <div
        className="row border p-2"
        style={{ height: '90%', overflow: 'auto' }}
      >
        {contents && contents.length ? (
          contents.map(content => (
            <div className="col-xl-6 col-md-4" key={content.id}>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (!selectedContents.find(c => c.id === content.id)) {
                    setSelectedContents(selectedContents => [
                      ...selectedContents,
                      {
                        id: content.id,
                        title: content.titulo,
                        description: content['descrição'],
                      },
                    ]);
                  } else {
                    const foundContent = selectedContents.find(
                      c => c.id === content.id,
                    );
                    if (foundContent) {
                      const indexOfFoundContent =
                        selectedContents.indexOf(foundContent);
                      if (
                        indexOfFoundContent > -1 &&
                        indexOfFoundContent < selectedContents.length
                      ) {
                        selectedContents.splice(indexOfFoundContent, 1);
                      }
                    }

                    setSelectedContents([...selectedContents]);
                  }
                }}
                className="card border"
              >
                <div
                  className={`card-body text-center rounded ${
                    !!selectedContents.find(c => c.id === content.id)
                      ? 'bg-success'
                      : ''
                  }`}
                  onMouseOver={event => {
                    if (!selectedContents.find(c => c.id === content.id)) {
                      event.currentTarget.classList.add('bg-success');
                      event.currentTarget.classList.add('text-white');
                    }
                  }}
                  onMouseOut={event => {
                    if (!selectedContents.find(c => c.id === content.id)) {
                      event.currentTarget.classList.remove('bg-success');
                      event.currentTarget.classList.remove('text-white');
                    }
                  }}
                >
                  <h3>{content.titulo}</h3>
                  <h5>{content['descrição']}</h5>
                </div>
              </div>
            </div>
          ))
        ) : (
          <>Não foram encontradas aulas!</>
        )}
      </div>
    </div>
  );
};

export default SelectContents;

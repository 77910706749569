import {
  Home,
  AlignLeft,
  Award,
  BookOpen,
  FileText,
  HelpCircle,
  Edit3,
  Video,
  Users,
  Shield,
} from 'react-feather';
import { IoMegaphoneOutline } from 'react-icons/io5';

export const MENUITEMS = [
  {
    title: 'Dashboard',
    icon: Home,
    path: '/dashboard',
    type: 'link',
  },
  {
    title: 'Disciplinas',
    icon: BookOpen,
    type: 'sub',
    children: [
      {
        title: 'Aulas',
        icon: AlignLeft,
        path: '/aulas/listar-aulas',
        type: 'link',
      },
      {
        title: 'Cursos',
        icon: Award,
        path: '/cursos/listar-cursos',
        type: 'link',
      },
      {
        title: 'Disciplinas',
        icon: BookOpen,
        path: '/disciplinas/listar-disciplinas',
        type: 'link',
      },
      {
        title: 'Avaliações',
        icon: Award,
        path: '/disciplinas/avaliacoes',
        type: 'link',
      },
    ],
  },
  {
    title: 'Cursos (Avulsos)',
    icon: Award,
    type: 'sub',
    children: [
      {
        title: 'Aulas',
        icon: AlignLeft,
        path: '/aulas/listar-aulas',
        type: 'link',
      },
      {
        title: 'Cursos (Avulsos)',
        icon: Award,
        path: '/cursos-avulsos/listar-cursos-avulsos',
        type: 'link',
      },
    ],
  },
  {
    title: 'Biblioteca',
    icon: BookOpen,
    type: 'sub',
    children: [
      {
        path: '/biblioteca/videos/listar-videos',
        title: 'Videos',
        type: 'link',
      },
      {
        path: '/biblioteca/documentos/listar-documentos',
        title: 'Documentos',
        type: 'link',
      },
    ],
  },
  {
    title: 'Lives',
    icon: Video,
    type: 'link',
    path: '/lives/listar-lives',
  },
  {
    title: 'Provas',
    icon: Edit3,
    type: 'sub',
    children: [
      {
        path: '/provas/listar',
        title: 'Provas',
        type: 'link',
      },
      {
        path: '/questoes/listar',
        title: 'Questões',
        type: 'link',
      },
    ],
  },
  {
    title: 'FAQs',
    icon: HelpCircle,
    path: '/faq/listar-faqs',
    type: 'link',
  },
  {
    title: 'Fórum',
    icon: IoMegaphoneOutline,
    type: 'sub',
    children: [
      {
        path: '/forum/categorias',
        title: 'Categorias',
        type: 'link',
      },
      {
        path: '/forum/tags',
        title: 'Tags',
        type: 'link',
      },
    ],
  },
  {
    title: 'Home',
    icon: Home,
    type: 'sub',
    children: [
      {
        path: '/home/listar-banner',
        title: 'Banner',
        type: 'link',
      },
      {
        path: '/home/sobre',
        title: 'Sobre Nós',
        type: 'link',
      },
    ],
  },
  {
    title: 'Segmentação',
    icon: Shield,
    type: 'link',
    path: '/segmentacao/listar',
  },
  {
    title: 'Usuários Admin',
    icon: Users,
    type: 'link',
    path: '/usuarios-admin/listar',
  },
  {
    title: 'Relatórios',
    icon: FileText,
    type: 'sub',
    children: [
      {
        path: '/relatorios/engajamento',
        title: 'Engajamento',
        type: 'link',
      },
      {
        path: '/relatorios/cursos-favoritos',
        title: 'Cursos Favoritos',
        type: 'link',
      },
      {
        path: '/relatorios/visualizacoes-completas',
        title: 'Disciplina Finalizadas',
        type: 'link',
      },
      {
        path: '/relatorios/cursos-avulsos-finalizados',
        title: 'Cursos Avulsos',
        type: 'link',
      },
      {
        path: '/relatorios/disciplinas-cadastradas',
        title: 'Disciplinas Cadastradas',
        type: 'link',
      },
      {
        path: '/relatorios/cursos-em-andamento',
        title: 'Cursos em Andamento',
        type: 'link',
      },
      {
        path: '/relatorios/disciplinas-finalizadas',
        title: 'Disciplinas Finalizadas',
      },
    ],
  },
];

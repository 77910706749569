import axios from 'axios';

const sambaVideosApiBaseUrl = 'https://sambavideos.sambatech.com/v1';

const getMedias = async filters => {
  const medias = (
    await axios.get(
      `${sambaVideosApiBaseUrl}/medias?access_token=${process.env.REACT_APP_SAMBA_ACCESS_TOKEN}&pid=${process.env.REACT_APP_SAMBA_PID}`,
      {
        params: filters,
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      },
    )
  ).data;

  return medias;
};

const getMedia = async ({ mediaId }) => {
  const medias = (
    await axios.get(
      `${sambaVideosApiBaseUrl}/medias/${mediaId}?access_token=${process.env.REACT_APP_SAMBA_ACCESS_TOKEN}&pid=${process.env.REACT_APP_SAMBA_PID}`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      },
    )
  ).data;

  return medias;
};

export { getMedias, getMedia };

import React, { Component, Fragment } from 'react';
import man from '../../../assets/images/dashboard/man.png';
export class UserMenu extends Component {
  render() {
    return (
      <Fragment>
        <li className="onhover-dropdown">
          <div className="media align-items-center">
            <img
              className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded"
              src={man}
              alt="header-user"
            />
            <div className="dotted-animation">
              <span className="animate-circle"></span>
              <span className="main-circle"></span>
            </div>
          </div>
          <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
            <li>
              <button
                style={{
                  background: 'transparent',
                  border: 'none',
                  outline: 'none',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  window.localStorage.removeItem('vallourec-api-token');
                  window.localStorage.removeItem('vallourec-user-name');
                  //Variaveis de ambiente do scorm usada pois leva para o link da aplicação
                  window.location.href = `${process.env.REACT_APP_ASSETS_SCORM}`;
                }}
              >
                <i data-feather="log-out"></i>Sair
              </button>
            </li>
          </ul>
        </li>
      </Fragment>
    );
  }
}

export default UserMenu;

import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import Tabset from './tabset-criar-trilha';

export class Criar_trilha extends Component {
  render() {
    return (
      <Fragment>
        <Breadcrumb title="Criar Disciplina" parent="Disciplinas" />
        <div className="container-fluid">
          <div 
            className="card"
          >
            <div className="card-header">
              <h5>Criar Disciplina</h5>
            </div>
            <div className="card-body">
              <Tabset />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Criar_trilha;

import React, { useEffect, useState } from 'react';
import { getCourses } from '../../services/courses';

const SelectCourses = ({ initialSelectedCourses, onSelectCourses }) => {
  const [courses, setCourses] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);

  useEffect(() => {
    (async () => {
      let localCourses = await getCourses({ is_single: false, is_active: true });
      if (localCourses && localCourses.length) {
        localCourses = localCourses.map(lc => ({
          ...lc,
          title: lc.titulo,
          description: lc['descrição'],
        }));
        setCourses(localCourses);

        for (let course of initialSelectedCourses) {
          const foundCourse = localCourses.find(c => c.id === course.id);
          if (foundCourse) {
            selectedCourses.push(foundCourse);
          }
        }

        setSelectedCourses([...selectedCourses]);
      }
    })();
  }, []);

  return (
    <div className="container m-5 pt-3" style={{ height: '98%' }}>
      <div className="row">
        <div className="col-sm-12 pb-3 text-center">
          <button
            className="btn btn-primary"
            onClick={() => onSelectCourses(selectedCourses)}
          >
            Aplicar Seleção
          </button>
        </div>
      </div>
      <div
        className="row border p-2"
        style={{ height: '90%', overflow: 'auto' }}
      >
        {courses && courses.length ? (
          courses.map(course => (
            <div className="col-xl-6 col-md-4" key={course.id}>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (!selectedCourses.find(c => c.id === course.id)) {
                    setSelectedCourses(selectedCourses => [
                      ...selectedCourses,
                      {
                        id: course.id,
                        title: course.titulo,
                        description: course['descrição'],
                      },
                    ]);
                  } else {
                    const foundCourse = selectedCourses.find(
                      c => c.id === course.id,
                    );
                    if (foundCourse) {
                      const indexOfFoundCourse =
                        selectedCourses.indexOf(foundCourse);
                      if (
                        indexOfFoundCourse > -1 &&
                        indexOfFoundCourse < selectedCourses.length
                      ) {
                        selectedCourses.splice(indexOfFoundCourse, 1);
                      }
                    }

                    setSelectedCourses([...selectedCourses]);
                  }
                }}
                className="card border"
              >
                <div
                  className={`card-body text-center rounded ${
                    !!selectedCourses.find(c => c.id === course.id)
                      ? 'bg-success'
                      : ''
                  }`}
                  onMouseOver={event => {
                    if (!selectedCourses.find(c => c.id === course.id)) {
                      event.currentTarget.classList.add('bg-success');
                      event.currentTarget.classList.add('text-white');
                    }
                  }}
                  onMouseOut={event => {
                    if (!selectedCourses.find(c => c.id === course.id)) {
                      event.currentTarget.classList.remove('bg-success');
                      event.currentTarget.classList.remove('text-white');
                    }
                  }}
                >
                  <h3>{course.titulo}</h3>
                  <h5>{course['descrição']}</h5>
                </div>
              </div>
            </div>
          ))
        ) : (
          <>Não foram encontrados módulos!</>
        )}
      </div>
    </div>
  );
};

export default SelectCourses;

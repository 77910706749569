import React, { useRef, useState } from 'react';
import Cropper from 'react-cropper';
import Swal from 'sweetalert2';

const SelectImage = ({ aspect, onCutImage }) => {
  const [cropper, setCropper] = useState();
  const [initialThumbnailSrc, setInitialThumbnailSrc] = useState('');
  const [initialThumbnailExtension, setInitialThumbnailExtension] =
    useState('');

  const fileInput = useRef(null);

  const activeFileInput = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleFile = event => {
    event.preventDefault();

    const currentTarget = event.currentTarget;

    if (currentTarget && currentTarget.files && currentTarget.files.length) {
      try {
        let file =
          currentTarget.files && currentTarget.files.length
            ? currentTarget.files[0]
            : null;

        if (file) {
          if (file.type && !file.type.includes('image')) {
            throw new Error('Arquivo inválido selecionado!');
          }

          let fr = new FileReader();

          fr.onloadend = () => {
            setInitialThumbnailSrc(`${fr.result}`);
            setInitialThumbnailExtension((file && file.type) || '');

            currentTarget.value = '';
          };

          fr.readAsDataURL(file);
        }
      } catch (e) {
        Swal.fire({
          title: 'Erro',
          text:
            'Ocorreu um erro ao carregar a imagem selecionada. ' + e.message,
          icon: 'error',
        });
      }
    }
  };

  const getCroppedImage = () => {
    if (cropper) {
      return cropper.getCroppedCanvas().toDataURL();
    }

    return '';
  };

  const selectImage = () => {
    const croppedImage = getCroppedImage();
    const croppedFile = dataURLtoFile(
      croppedImage,
      `thumbnail.${initialThumbnailExtension}`,
    );

    onCutImage(croppedFile);
  };

  function dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(',');

    let mime = null;
    if (arr && arr.length) {
      if (arr[0]) {
        mime = arr[0].match(/:(.*?);/);
        if (mime && mime.length > 1) {
          mime = mime[1];
        }
      }
    }

    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: `${mime}` });
  }

  return (
    <div className="row">
      <div className="text-center col-sm-12">
        {!initialThumbnailSrc && (
          <button
            className="btn btn-primary"
            type="button"
            onClick={activeFileInput}
          >
            Selecionar Imagem
          </button>
        )}
        <input
          ref={fileInput}
          style={{ display: 'none' }}
          accept="image/*"
          type="file"
          onChange={handleFile}
        />
      </div>
      <div className="col-sm-12">
        {initialThumbnailSrc ? (
          <>
            <div className="crop">
              <Cropper
                src={initialThumbnailSrc}
                style={{ height: '50vh' }}
                aspectRatio={aspect}
                guides={false}
                onInitialized={instance => {
                  setCropper(instance);
                }}
              />
            </div>
            <div className="buttons text-center mt-1">
              <button
                className="btn btn-primary mr-1"
                type="button"
                onClick={activeFileInput}
              >
                Selecionar Imagem
              </button>
              <button
                className="btn btn-success"
                type="button"
                onClick={selectImage}
              >
                Cortar Imagem
              </button>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default SelectImage;

import React from 'react';
import { Check, X } from 'react-feather';

const convertCategoryFromDB = (category) => (
  {
    'id': category.category_id,
    'titulo': category.title,
    'descrição': category.description,
    'ativo': category.is_active
      ? <Check size={16} />
      : <X size={16} />,
    'active': category.is_active
  }
);

export {
  convertCategoryFromDB
} 

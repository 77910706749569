import React, { useEffect, useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useHistory, useParams } from 'react-router';
import Swal from 'sweetalert2';
import getErrorMessage from '../../helpers/get-error-message';
import Breadcrumb from '../common/breadcrumb';
import { showModal, hideModal } from '../modal';
import SelectImage from '../select-image';
import {
  createLive as createLiveService,
  updateLive as updateLiveService,
} from '../../services/lives';
import checkEmptyString from '../../helpers/check-empty-string';
import { uploadFile } from '../../services/files';
import { getLive as getLiveService } from '../../services/lives';

const CriarEditarLive = () => {
  const history = useHistory();
  const { liveId } = useParams();

  const fiveMinutesDivisorFutureDate = date => {
    const actualDate = date || new Date();
    const actualMinutes = actualDate.getMinutes() + 5;
    const actualHours = actualDate.getHours();

    let liveInitialMinutes = actualMinutes;
    let hourIncrement = 0;
    let dayIncrement = 0;
    while ((liveInitialMinutes || 5) % 5 !== 0) {
      if (liveInitialMinutes === 59) {
        liveInitialMinutes = 0;
        hourIncrement++;
        if (actualHours === 23) {
          dayIncrement++;
        }
      } else {
        liveInitialMinutes++;
      }
    }

    actualDate.setDate(actualDate.getDate() + dayIncrement);
    actualDate.setHours(actualDate.getHours() + hourIncrement);
    actualDate.setMinutes(liveInitialMinutes);

    return actualDate;
  };

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState(fiveMinutesDivisorFutureDate());
  const [finishDate, setFinishDate] = useState();
  const [imageToUpload, setImageToUpload] = useState('');
  const [image, setImage] = useState('');

  const selectImage = () => {
    showModal(
      'Cortar Imagem',
      <SelectImage aspect={16 / 9} onCutImage={onCutImage} />,
    );
  };

  const getLive = async () => {
    const localLive = await getLiveService(liveId);
    setName(localLive.name);
    setDescription(localLive.description);
    setStartDate(
      localLive.start
        ? new Date(localLive.start)
        : fiveMinutesDivisorFutureDate(),
    );
    setFinishDate(localLive.stop ? new Date(localLive.stop) : '');
    setImage(localLive.thumbnail);
  };

  const onCutImage = file => {
    if (file) {
      setImageToUpload(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setImage(`${reader.result}`);

      hideModal();
    }
  };

  const gotoLives = () => {
    history.push(`${process.env.PUBLIC_URL}/lives/listar-lives`);
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const handleSubmit = event => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    if (isEditting) {
      updateLive();
    } else {
      createLive();
    }
  };

  const validate = () => {
    if (checkEmptyString(name)) {
      throw new Error('Informe um nome valido para a live!');
    }

    if (checkEmptyString(description)) {
      throw new Error('Informe uma descrição valida para a live!');
    }

    if (new Date().getTime() >= startDate.getTime()) {
      throw new Error(
        'Informe uma data de inicio valida para a live! Certifique-se que a data seja ao menos 5 minutos superior a data atual!',
      );
    }

    if (finishDate && new Date().getTime() >= finishDate.getTime()) {
      throw new Error(
        'Informe uma data final valida para a live! Certifique-se que a data seja ao menos 5 minutos superior a data de inicio da live!',
      );
    }

    if (finishDate && startDate.getTime() >= finishDate.getTime()) {
      throw new Error('Informe uma data de inicio inferior a data de fim!');
    }
  };

  const createLive = async () => {
    try {
      validate();

      const newLive = {
        name: name,
        description: description,
        start: startDate.toISOString(),
        vod: 0,
      };

      if (finishDate) {
        newLive.stop = finishDate.toISOString();
      }

      if (image && image.length && image.includes('base64')) {
        try {
          const thumbnail = dataURLtoFile(image, `thumbnail-${name}.png`);

          const formData = new FormData();
          formData.append('file', thumbnail);

          const { reference } = await uploadFile(formData);
          newLive.thumbnail_url = reference;
        } catch (e) {
          throw new Error('Erro ao incluir a imagem de capa. ' + e.message);
        }
      }

      await createLiveService(newLive);

      gotoLives();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({ icon: 'error', text: 'Erro ao criar live. ' + errorMessage });
    }
  };

  const updateLive = async () => {
    try {
      validate();

      const newLive = {
        name: name,
        description: description,
        start: startDate.toISOString(),
        stop: finishDate.toISOString(),
        vod: 0,
      };

      if (image && image.length && image.includes('base64')) {
        try {
          const thumbnail = dataURLtoFile(image, `thumbnail-${name}.png`);

          const formData = new FormData();
          formData.append('file', thumbnail);

          const { reference } = await uploadFile(formData);
          newLive.thumbnail_url = reference;
        } catch (e) {
          throw new Error('Erro ao alterar a imagem de capa. ' + e.message);
        }
      }

      await updateLiveService(liveId, newLive);

      gotoLives();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        icon: 'error',
        text: 'Erro ao atualizar live. ' + errorMessage,
      });
    }
  };

  const isEditting = useMemo(() => {
    return !!liveId;
  }, []);

  useEffect(() => {
    if (liveId) {
      getLive();
    }
  }, [liveId]);

  return (
    <>
      <Breadcrumb
        title={`${isEditting ? 'Editar' : 'Criar'} Lives`}
        parent="Lives"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>{isEditting ? 'Editar' : 'Criar'} Live</h5>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row mb-2">
                    <label
                      htmlFor="name"
                      className="control-label col-md-2 required"
                    >
                      Nome
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="form-control col-md-10"
                      value={name}
                      readOnly={isEditting}
                      onChange={e => setName(e.target.value)}
                    />
                  </div>

                  <div className="row mb-2">
                    <label
                      htmlFor="description"
                      className="control-label col-md-2 required"
                    >
                      Descrição
                    </label>
                    <textarea
                      id="description"
                      className="form-control col-md-10"
                      cols="30"
                      rows="10"
                      value={description}
                      onChange={e => setDescription(e.target.value)}
                      readOnly={isEditting}
                    ></textarea>
                  </div>

                  <div className="row mb-2">
                    <label
                      htmlFor="startDate"
                      className="control-label col-md-2 required"
                    >
                      Inicio
                    </label>
                    <ReactDatePicker
                      id="startDate"
                      selected={startDate}
                      showTimeSelect
                      dateFormat="dd/MM/yyyy hh:mm"
                      timeIntervals={5}
                      onChange={date => setStartDate(date)}
                      readOnly={isEditting}
                      onChangeRaw={event => event.preventDefault()}
                    />
                  </div>

                  <div className="row mb-2">
                    <label
                      htmlFor="finishDate"
                      className="control-label col-md-2"
                    >
                      Fim
                    </label>
                    <ReactDatePicker
                      id="finishDate"
                      selected={finishDate}
                      showTimeSelect
                      dateFormat="dd/MM/yyyy hh:mm"
                      timeIntervals={5}
                      onChange={date => setFinishDate(date)}
                      readOnly={isEditting}
                      onChangeRaw={event => event.preventDefault()}
                    />
                  </div>

                  <div className="row mb-2">
                    <label
                      htmlFor="thumbnail"
                      className="control-label col-md-2"
                    >
                      Capa
                    </label>
                    <div className="text-center col-md-10 border rounded p-1">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={selectImage}
                        disabled={isEditting}
                      >
                        Selecionar Imagem
                      </button>

                      <div>
                        {image ? (
                          <img
                            className="mt-1"
                            style={{ width: '400px' }}
                            src={image}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="pull-right">
                    <button
                      className="btn btn-danger mr-1"
                      type="button"
                      onClick={gotoLives}
                    >
                      Cancelar
                    </button>
                    <button
                      className="btn btn-primary"
                      type="submit"
                      disabled={isEditting}
                    >
                      Salvar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CriarEditarLive;

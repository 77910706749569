import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import Tabset from './tabset-criar-video';

export class CreateVideo extends Component {
  render() {
    return (
      <Fragment>
        <Breadcrumb title="Adicionar Video" parent="Biblioteca" />
        <div className="container-fluid">
          <div 
            className="card"
            style={{ 
              minHeight: '75vh'
            }}
          >
            <div className="card-header">
              <h5>Criar Video</h5>
            </div>
            <div className="card-body">
              <Tabset />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default CreateVideo;

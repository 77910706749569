import httpClient from '../http-client';

const getSegmentationTypes = async () => {
  const segTypes = (await httpClient.get('/segmentation-items/type')).data;
  return segTypes;
};

const getSegmentationType = async segTypeId => {
  const segType = (
    await httpClient.get(`/segmentation-items/${segTypeId}/type`)
  ).data;
  return segType;
};

const getSegmentationItems = async segTypeId => {
  const segItems = (
    await httpClient.get(`/segmentation-items/${segTypeId}/type`)
  ).data;
  return segItems;
};

const createSegmentationItem = async (segTypeId, newItem) => {
  await httpClient.post(`/segmentation-items/${segTypeId}`, newItem);
};

const updateSegmentationItem = async (itemId, newItem) => {
  await httpClient.put(`/segmentation-items/${itemId}`, newItem);
};

const deleteSegmentationItem = async itemId => {
  await httpClient.delete(`/segmentation-items/${itemId}`);
};

export {
  getSegmentationTypes,
  getSegmentationItems,
  createSegmentationItem,
  updateSegmentationItem,
  deleteSegmentationItem,
  getSegmentationType,
};

import httpClient from '../http-client';

const login = async () => {
  const token = getAccessToken();
  if (token) {
    window.localStorage.setItem('vallourec-api-token', token);
    const user = (await httpClient.get('/users/me')).data;

    if (!user || !user.is_admin) {
      throw new Error('Você não possui acesso a plataforma de administrador!');
    }
  }
};

const getAccessToken = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const token = urlSearchParams.get('token');

  return token;
};

export { login, getAccessToken };

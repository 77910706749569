import { convertAuthorFromDB } from '../helpers/converters/authorConverter';
import httpClient from '../http-client';

const getAuthors = async () => {
  let authors = (await httpClient.get(`/authors`)).data;

  if (authors && authors.length) {
    authors = authors.map(convertAuthorFromDB);
  }

  return authors;
}

const getAuthorsForSelect = async () => {
  let authors = (await httpClient.get(`/authors`)).data;

  if (authors && authors.length) {
    authors = authors.map(author => ({
      value: author.author_id,
      label: author.name
    }));
  }

  return authors;
}

const getAuthor = async (authorId) => {
  if (!authorId) {
    throw new Error('O id do autor é obrigatório para obter seus dados!');
  }

  let author = (await httpClient.get(`/authors/${authorId}`)).data;

  return author;
}

const deleteAuthor = async (authorId) => {
  if (!authorId) {
    throw new Error('O id do autor é obrigatório para a exclusão!');
  }

  const authorDeleteResponse = 
    (await httpClient.delete(
      `/authors/${authorId}`
    )).data;

  return authorDeleteResponse;
}

const updateAuthor = async (authorId, newAuthor) => {
  if (!authorId) {
    throw new Error('O id do autor é obrigatório para a edição!');
  }

  if (!newAuthor || !Object.keys(newAuthor).length) {
    throw new Error('Os dados para atualização do autor são obrigatórios!');
  }

  const authorUpdateResponse = 
    (await httpClient.put(
      `/authors/${authorId}`, 
      newAuthor
    )).data;

  return authorUpdateResponse;
}

const createAuthor = async (newAuthor) => {
  if (!newAuthor || !Object.keys(newAuthor).length) {
    throw new Error('Os dados para criação do autor são obrigatórios!');
  }

  const createAuthorResponse = 
    (await httpClient.post(
      `/authors`, 
      newAuthor
    )).data;

  return createAuthorResponse;
}

const activateOrInactivateAuthor = async (authorId, active) => {
  const activateOrInactivateAuthorResponse = 
    (await httpClient.put(
      `/authors/${authorId}/active`,
      {
        'is_active': active
      }
    )).data;

  return activateOrInactivateAuthorResponse;
}

export {
  getAuthors,
  getAuthorsForSelect,
  getAuthor,
  deleteAuthor,
  updateAuthor,
  createAuthor,
  activateOrInactivateAuthor
}

import React, { useCallback, useEffect, useState } from 'react';
import Sidebar from './common/sidebar_components/sidebar';
import Header from './common/header_components/header';
import 'cropperjs/dist/cropper.css';
import { useHistory } from 'react-router';
import { getActualUser } from '../services/users';
import { login as loginService } from '../services/login';
import Swal from 'sweetalert2';
import getErrorMessage from '../helpers/get-error-message';

export const App = ({ children }) => {
  const history = useHistory();
  const [isAuthorized, setIsAuthorized] = useState(false);

  const login = async () => {
    if (!window.localStorage.getItem('vallourec-api-token')) {
      await tryLogin();
    } else {
      try {
        await getActualUser();
        changeToHome();
        setIsAuthorized(true);
      } catch (error) {
        window.localStorage.removeItem('vallourec-api-token');
        await tryLogin();
      }
    }
  };

  const tryLogin = async () => {
    try {
      await loginService();
      changeToHome();
      setIsAuthorized(true);
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({ icon: 'error', text: errorMessage }).then(
        () =>
          (window.location.href = `${process.env.REACT_APP_REDIRECT_LOGIN}`),
      );
    }
  };

  const changeToHome = () => {
    history.push('/dashboard');
  };

  useEffect(() => {
    (async () => {
      const queryStrings = new URLSearchParams(window.location.search);
      const token =
        queryStrings.get('token') ||
        window.localStorage.getItem('vallourec-api-token');
      if (token) {
        window.localStorage.setItem('vallourec-api-token', token);
        setIsAuthorized(true);
        history.push('/dashboard');
      } else {
        await login();
      }
    })();
  }, []);

  return (
    isAuthorized && (
      <div>
        <div className="page-wrapper">
          <Header />
          <div className="page-body-wrapper">
            <Sidebar />
            <div className="page-body">{children}</div>
          </div>
        </div>
      </div>
    )
  );
};

export default App;

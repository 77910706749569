import httpClient from '../http-client';

const getLive = async liveId => {
  const live = (await httpClient.get(`/lives/${liveId}`)).data;
  return live;
};

const getLives = async () => {
  const lives = (await httpClient.get('/lives')).data;
  return lives;
};

const createLive = async newLive => {
  await httpClient.post('/lives', newLive);
};

const updateLive = async (liveId, newLive) => {
  await httpClient.put(`/lives/${liveId}`, newLive);
};

const deleteLive = async liveId => {
  await httpClient.delete(`/lives/${liveId}`);
};

const stopLive = async liveId => {
  await httpClient.post(`/lives/stop/${liveId}`);
};

const startLive = async liveId => {
  await httpClient.post(`/lives/start/${liveId}`);
};

export {
  getLives,
  getLive,
  createLive,
  updateLive,
  deleteLive,
  stopLive,
  startLive,
};

import httpClient from '../http-client/index';
import { uploadFile } from './files';

const getImages = async () => {
  let images = (await httpClient.get(`/home-infos`)).data;

  return images;
};

const getBanners = async () => {
  let images = await getImages();

  images =
    images && images.length
      ? images.filter(image => image.location.toLowerCase() === 'banner')
      : [];

  return images;
};

const getBanner = async bannerId => {
  const banner = (await httpClient.get(`/home-infos/${bannerId}`)).data;

  return banner;
};

const deleteBanner = async bannerId => {
  const response = (await httpClient.delete(`/home-infos/${bannerId}`)).data;

  return response;
};

const getAboutUs = async () => {
  let images = await getImages();

  images =
    images && images.length
      ? images.filter(image => image.location.toLowerCase() === 'about_us') || {}
      : {};

  let aboutUsImage = (images || []).find(i => i);
  if (images && images.length) {
    images.splice(0, 1);
    for (let image of images) {
      await deleteAboutUs(image.home_info_id);
    }
  }

  return aboutUsImage;
};

const createBanner = async (description, location, position, hyperlink) => {
  switch (location) {
    case 'banner':
      return (
        await httpClient.post('/home-infos', {
          description,
          location,
          position,
          hyperlink,
        })
      ).data;
    case 'about_us':
      return (await httpClient.post('/home-infos', { description, location }))
        .data;
    default:
      return null;
  }
};

const updateBanner = async (bannerId, location, position, description, hyperlink) => {
  const response = (
    await httpClient.put(`/home-infos/${bannerId}`, {
      location,
      position,
      description,
      hyperlink,
    })
  ).data;

  return response;
};

const uploadImage = async (id, formData) => {
  const uploadedFile = await uploadFile(formData);

  httpClient.patch(`/home-infos/${id}/image`, {
    image: uploadedFile.reference,
  });
};

const deleteAboutUs = async (homeInfoId) => {
  await httpClient.delete(`/home-infos/${homeInfoId}`);
}

export {
  createBanner,
  uploadImage,
  getImages,
  getBanners,
  getAboutUs,
  deleteBanner,
  updateBanner,
  getBanner,
};

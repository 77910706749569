import httpClient from '../http-client';

const createExtraMaterial = async (contentId, material) => {
  const createExtraMaterialResponse = (
    await httpClient.post(`/extra-materials/${contentId}`, material)
  ).data;

  return createExtraMaterialResponse;
};

const deleteExtraMaterial = async extraMaterialId => {
  const deleteExtraMaterialResponse = await httpClient.delete(
    `/extra-materials/${extraMaterialId}`,
  );

  return deleteExtraMaterialResponse;
};

const createCourseExtraMaterial = async (courseId, material) => {
  const createExtraMaterialResponse = (
    await httpClient.post(`/extra-materials/course/${courseId}`, material)
  ).data;

  return createExtraMaterialResponse;
};

const deleteCourseExtraMaterial = async extraMaterialId => {
  const deleteExtraMaterialResponse = await httpClient.delete(
    `/extra-materials/course/${extraMaterialId}`,
  );

  return deleteExtraMaterialResponse;
};

const updateCourseExtraMaterial = async (extraMaterials) => {
  const updateCourseExtraMaterialResponse = (
    await httpClient.put('/extra-materials/', extraMaterials)
  ).data;

  return updateCourseExtraMaterialResponse
}

export {
  createExtraMaterial,
  deleteExtraMaterial,
  createCourseExtraMaterial,
  deleteCourseExtraMaterial,
  updateCourseExtraMaterial
};
